import React from "react";

function Icon(props) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448.11 467.2">
      <path d="M445.6 120.9L353.9 4.6a11.94 11.94 0 00-9.4-4.6H103.7a11.8 11.8 0 00-9.4 4.6L2.6 120.9a11.71 11.71 0 00-2.6 7.4v326.9a12 12 0 0012 12h424.1a12 12 0 0012-12V128.3a11.07 11.07 0 00-2.5-7.4zm-32.6-2.6H236.1V24h102.6zM109.5 24h102.6v94.3H35.2zM24 443.2V142.3h400.1v300.9z"></path>
      <path d="M172.73 260.33L143.88 291c-10.63 11.3-21.32 22.59-31.89 34a21.51 21.51 0 00-5.1 9.88c-3.83 18.65-7.53 37.3-11.24 55.95l-2.06 10.33a15.35 15.35 0 003.52 13.49 13.17 13.17 0 009.6 4.13 17.26 17.26 0 003.1-.32l10.69-2.39c17-3.81 34.07-7.68 51.08-11.43a19.44 19.44 0 0010.14-5.8q71.61-76.2 143.23-152.19A22 22 0 00330.9 233a9.6 9.6 0 00-.18-2.64c-.31-1.3-.55-2.65-.85-3.94a59.67 59.67 0 00-2.86-10.65c-8.93-21.69-23.75-37.17-44-45.89-4.07-1.74-8.39-2.32-12.15-2.84l-1-.13c-5.46-.71-10.44 1.23-14.76 5.88q-41.06 43.72-82.37 87.54zm95.06-77.9h.3l1 .13a30.21 30.21 0 018.38 1.8c16.52 7.1 28.67 19.75 36 37.57a42.41 42.41 0 011.94 7.48c.18 1 .43 2.07.61 3.1a5 5 0 01-1.52 2.78q-71.7 76-143.29 152.18a4.65 4.65 0 01-2.61 1.48l-51.14 11.43-9 2 1.7-8.46c3.71-18.59 7.41-37.24 11.18-55.82a5.68 5.68 0 011.15-2.13q15.85-17 31.77-33.89l28.85-30.65q41.27-43.86 82.48-87.78c1.23-1.03 1.78-1.22 2.2-1.22z"></path>
    </svg>
  );
}

export default Icon;
