import React, {Component} from 'react'
import {isMobile} from "react-device-detect"

import Logo from "./svg/Logo"
// import PhoneIcon from "./svg/icon-phone.js"

import "./css/Login.css"

class Login extends Component {
    constructor(){
      super()

      this.state = {
        login: "",
        smsCode: "",
        step: 1,
        allPerm: false,
        polPryw: false,
        rodo: false,
        regulamin: false,
        cookie: false,
        // displayLoginFocus: false
      }
      this.chandleChange = this.chandleChange.bind(this)
      this.togleCheckbox = this.togleCheckbox.bind(this)
      this.sendSms = this.sendSms.bind(this)
      this.sendSmsAgain = this.sendSmsAgain.bind(this)

      this.displayLoginFocus = this.displayLoginFocus.bind(this)
      this.displayLoginBlur = this.displayLoginBlur.bind(this)
      this.displaySmsCodeFocus = this.displaySmsCodeFocus.bind(this)
      this.displaySmsCodeBlur = this.displaySmsCodeBlur.bind(this)
    //   this.focusOnLogin = this.focusOnLogin.bind(this)
    //   this.toglePerm = this.toglePerm.bind(this)
    }

    componentDidMount(){
        this.focusOnLogin()
    }
    componentDidUpdate(prevProps, prevState){
        // console.log("this.componentDidUpdate() -> this.state.step", this.state.step)
        if(prevState.step !== this.state.step){
            if(this.state.step === 1){
                this.focusOnLogin()
            } else if(this.state.step === 3){
                // let cduTim = setTimeout( (con = this) => {
                    this.focusOnSms()
                // })
            } else {
                document.querySelector("#login").blur()
                document.querySelector("#smsCode").blur()        
            }
        }
    }
    focusOnLogin(){
        document.querySelector("#login").focus()
    }
    focusOnSms(){
        document.querySelector("#smsCode").focus()
    }
    displayLoginFocus(){
        this.setState({
            displayLoginFocus: true
        })
    }
    displayLoginBlur(){
        this.setState({
            displayLoginFocus: false
        })
    }
    displaySmsCodeFocus(){
        this.setState({
            displaySmsCodeFocus: true
        })
    }
    displaySmsCodeBlur(){
        this.setState({
            displaySmsCodeFocus: false
        })
    }
    chandleChange(e){
        let name  = e.target.name
        let val = e.target.value
        val = val.slice(0, 9)
        this.setState({
            [name]: val
        })
        if(name === "login" && val > 100000000){
            document.querySelector('#login').blur()
            this.sendSms(val)
            let tim = setTimeout( () => {
                this.setState({step: 2})
                tim = clearTimeout(tim)
            }, 500) // ze zgodami
            // let tim = setTimeout( () => {this.setState({step: 3})}, 500) // bez zgód
        } else if(name === "smsCode" && val > 100000){
            document.querySelector('#smsCode').blur()
            this.sendLogin(val)
            let tim = setTimeout( () => {
                if(this.state.step === 3){
                    this.setState({step: 9})
                }
                tim = clearTimeout(tim)
            }, 500)
        }
    }
    sendSmsAgain(e){
        
        this.setState({
            step: 4
        })
        this.sendSms(this.state.login)
        let ssaTim = setTimeout( ()=> {
            this.setState({
                step: 3
            })
            ssaTim = clearTimeout(ssaTim)
            // this.focusOnSms()
        } , 3000)
    }
    sendSms(login){
        if(this.sendSmsInProgres) return
        this.sendSmsInProgres = true


        let body = {
        sessionID: localStorage.getItem('sessionID'),
        login: login,
        }

        body = JSON.stringify(body)
        fetch('https://api2.kurkadomowa.pl/sendSms.php', {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else{ try{ return response.json()} catch(e){ throw new Error(e) }}
        })
        .then((res) => {
            this.sendSmsInProgres = false
            // console.warn(res)
            this.setState({
                sendSms: res
            })
            // this.focusOnSms()
            if(!res.smsSent){
                let tim = setTimeout( () => {
                    this.setState({step: 7})
                    tim = clearTimeout(tim)
                }, 500)
            }
        })
    }
    sendLogin(smsCode){
        if(this.sendLoginInProgres) return
        this.sendLoginInProgres = true
    
        // this.props.loaderAnimationControl("start")
        let body = {
          sessionID: localStorage.getItem('sessionID'),
          login: this.state.login,
          smsCode: smsCode,
          userAgent: navigator.userAgent
        }
    
        body = JSON.stringify(body)
        fetch('https://api2.kurkadomowa.pl/login.php', {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else{ try{ return response.json()} catch(e){ throw new Error(e) }}
        })
        .then((res) => {
            this.sendLoginInProgres = false
            console.log("sendLoginRes", res)
            this.setState({
                sendLoginRes: res
            })
            if(res.userLogedIn){
                this.props.loaderAnimationControl("start")
                this.props.appUpdate()
                localStorage.setItem("ltt", res.genLtt.ltt)
            } else if(res.badSmsCode){
                this.setState({
                    smsCode: "",
                    step: 6
                })
                let tim = setTimeout( () => {
                    this.setState({step: 3})
                    this.focusOnSms()
                    tim = clearTimeout(tim)
                }, 3000)
            } else if(res.accountBloked){
                this.setState({
                    step: 8
                })
            } else {
                this.setState({
                    step: 99
                }) 
            }
        })
      }
    togleCheckbox(e){
        // e.preventDefault();
        let name = e.target.name
        let val = !this.state[name]
        if(name === 'allPerm'){
            this.setState({
                allPerm: val,
                polPryw: val,
                rodo: val,
                regulamin: val,
                cookie: val,
            })
        } else {
            this.setState({
                [name]: val
            })
        }
        if((name === "allPerm" && val) ||
            (name === "polPryw" && this.state.rodo && this.state.regulamin && this.state.cookie) ||
            (name === "rodo" && this.state.polPryw && this.state.regulamin && this.state.cookie) ||
            (name === "regulamin" && this.state.polPryw && this.state.rodo &&this.state.cookie) ||
            (name === "cookie" && this.state.polPryw && this.state.rodo && this.state.regulamin)){
            this.setState({
                allPerm: true,
            })
            let tcTim = setTimeout( () => {
                this.setState({step: 3})
                tcTim = clearTimeout(tcTim)
            }, 400)
            let tcTim2 = setTimeout( () => {
                this.focusOnSms()
                tcTim2 = clearTimeout(tcTim2)
            },1000)
        }
    }
    render(){
        return(
            <div className={isMobile ? "contentWraperMobile loginWraper" : "contentWraper loginWraper"}>
                <form id="loginForm" 
                    onClick={this.state.step === 1 ? this.focusOnLogin : this.state.step === 3 ? this.focusOnSms : null}
                >
                    <Logo className="logo" />
                    <div id="stepWraper">
                        <div className={this.state.step === 1 ? "step" : "inactive"} id="step1" >
                            <h2>Podaj numer telefonu</h2>
                            <div id="loginInputWraper">
                                <input
                                    type="tel"
                                    name="login"
                                    id="login"
                                    value={this.state.login}
                                    onChange={this.chandleChange}
                                    // autoselect="true"
                                    autoComplete="off"
                                    onFocus={this.displayLoginFocus}
                                    onBlur={this.displayLoginBlur}
                                />
                                <div id="displayLogin" className={this.state.displayLoginFocus ? "focused" : null}>
                                    <span className={this.state.login[0] ? "filed" : "unfiled"}>
                                        {this.state.login[0] ? this.state.login[0] : null}
                                    </span>
                                    <span className={this.state.login[1] ? "filed" : this.state.login[0] ? "unfiled" : null}>
                                        {this.state.login[1] ? this.state.login[1] : null}
                                    </span>
                                    <span className={this.state.login[2] ? "filed" : this.state.login[1] ? "unfiled" : null}>
                                        {this.state.login[2] ? this.state.login[2] : null}
                                    </span>
                                
                                    <span className={this.state.login[3] ? "filed" : this.state.login[2] ? "unfiled" : null}>
                                        {this.state.login[3] ? this.state.login[3] : null}
                                    </span>
                                    <span className={this.state.login[4] ? "filed" : this.state.login[3] ? "unfiled" : null}>
                                        {this.state.login[4] ? this.state.login[4] : null}
                                    </span>
                                    <span className={this.state.login[5] ? "filed" : this.state.login[4] ? "unfiled" : null}>
                                        {this.state.login[5] ? this.state.login[5] : null}
                                    </span>

                                    <span className={this.state.login[6] ? "filed" : this.state.login[5] ? "unfiled" : null}>
                                        {this.state.login[6] ? this.state.login[6] : null}
                                    </span>
                                    <span className={this.state.login[7] ? "filed" : this.state.login[6] ? "unfiled" : null}>
                                        {this.state.login[7] ? this.state.login[7] : null}
                                    </span>
                                    <span className={this.state.login[8] ? "filed" : this.state.login[7] ? "unfiled" : null}>
                                        {this.state.login[8] ? this.state.login[8] : null}
                                    </span>
                                </div>
                            </div>
                            <p>
                                W celu zalogowania się lub założenia konta podaj numer telefonu komórkowego, który będzie również numerem kontaktowym w celu realizacji złożonych zleceń. 
                            </p>
                        </div>
                        <div className={this.state.step === 2 ? "step" : "inactive"} id="step2">
                            <h2>Potwierdz wymagane zgody</h2>


                            <label className='checkboxWraperBold'
                                >
                                <input type="checkbox" 
                                    onChange={this.togleCheckbox}
                                    name="allPerm"
                                    checked={this.state.allPerm}
                                />
                                Wszystkie zgody
                                <div className={this.state.allPerm ? "checkboxChecked": "checkbox"}>
                                    <div></div>
                                </div>
                            </label>
                            <label className='checkboxWraper'
                                >
                                <input type="checkbox" 
                                    onChange={this.togleCheckbox}
                                    name="polPryw"
                                    checked={this.state.polPryw}
                                />
                                Polityka prywatności
                                <div className={this.state.polPryw ? "checkboxChecked": "checkbox"}>
                                    <div></div>
                                </div>
                            </label>
                            <label className='checkboxWraper'
                                >
                                <input type="checkbox" 
                                onChange={this.togleCheckbox}
                                name="rodo"
                                checked={this.state.rodo}
                            />
                                RODO
                                <div className={this.state.rodo ? "checkboxChecked": "checkbox"}>
                                    <div></div>
                                </div>
                            </label>
                            <label className='checkboxWraper'
                                >
                                <input type="checkbox" 
                                onChange={this.togleCheckbox}
                                name="regulamin"
                                checked={this.state.regulamin}
                            />
                                Regulamin
                                <div className={this.state.regulamin ? "checkboxChecked": "checkbox"}>
                                    <div></div>
                                </div>
                            </label>
                            <label className='checkboxWraper'
                                >
                                <input type="checkbox" 
                                onChange={this.togleCheckbox}
                                name="cookie"
                                checked={this.state.cookie}
                            />
                                Ciasteczka
                                <div className={this.state.cookie ? "checkboxChecked": "checkbox"}>
                                    <div></div>
                                </div>
                            </label>


                            <p>
                                Zapoznałem się i wyrażam zgodę na przetwarzanie danych osobowych zgodnie z polityką prywatności, regulaminem oraz RODO, a także zgadzam się na użycie plików cookies.
                            </p>

                        </div>

                        <div className={this.state.step === 3 ? "step" : "inactive"} id="step2">
                            <h2>Podaj kod sms</h2>
                            <div id="smsWrapper">
                                <input
                                    type="tel"
                                    name="smsCode"
                                    id="smsCode"
                                    value={this.state.smsCode}
                                    onChange={this.chandleChange}
                                    onFocus={this.displaySmsCodeFocus}
                                    onBlur={this.displaySmsCodeBlur}
                                    // autoselect="true"
                                />
                                <div id="displaySmsCode" className={this.state.displaySmsCodeFocus ? "focused" : null}>
                                    <span className='smsDigit'>
                                        <span className={this.state.smsCode[0] ? "filed" : "unfieled"}>{this.state.smsCode[0] ? this.state.smsCode[0] : null}</span>
                                    </span>
                                    <span className='smsDigit'>
                                        <span className={this.state.smsCode[1] ? "filed" : this.state.smsCode[0] ? "unfieled" : null}>{this.state.smsCode[1] ? this.state.smsCode[1] : null}</span>
                                    </span>
                                    <span className='smsDigit'>
                                        <span className={this.state.smsCode[2] ? "filed" : this.state.smsCode[1] ? "unfieled" : null}>{this.state.smsCode[2] ? this.state.smsCode[2] : null}</span>
                                    </span>
                                    <span className='smsDigit'>
                                        <span className={this.state.smsCode[3] ? "filed" : this.state.smsCode[2] ? "unfieled" : null}>{this.state.smsCode[3] ? this.state.smsCode[3] : null}</span>
                                    </span>
                                    <span className='smsDigit'>
                                        <span className={this.state.smsCode[4] ? "filed" : this.state.smsCode[3] ? "unfieled" : null}>{this.state.smsCode[4] ? this.state.smsCode[4] : null}</span>
                                    </span>
                                    <span className='smsDigit'>
                                        <span className={this.state.smsCode[5] ? "filed" : this.state.smsCode[4] ? "unfieled" : null}>{this.state.smsCode[5] ? this.state.smsCode[5] : null}</span>
                                    </span>
                                </div>
                            </div>
                            <p>
                                Wysłaliśmy do Ciebie na numer <nobr>+48 {this.state.login.substring(0, 3)} {this.state.login.substring(3, 6)} {this.state.login.substring(6, 9)}</nobr> wiadomość SMS z 6-cio cyfrowym kodem weryfikacyjnym, aby sprawdzić czy to na pewno Ty.
                            </p>
                            {/* <div>
                                {this.state.sendSms !== undefined ? JSON.stringify(this.state.sendSms._SESSION.smsCode, null ,2) : null}
                            </div> */}
                            <button className="linc" onClick={this.sendSmsAgain} type="button">
                                Wyślij ponownie
                            </button>
                        </div>
                        
                        <div className={this.state.step === 4 ? "step" : "inactive"} id="step4">
                            <h2>Ponownie wysłaliśmy kod sms</h2>
                        </div>


                        <div className={this.state.step === 6 ? "step" : "inactive"} id="step6">
                            <h2>Błędny kod sms</h2>
                        </div>
                        <div className={this.state.step === 7 ? "step" : "inactive"} id="step7">
                            <h2>Nie udało się wysłać sms'a</h2>
                        </div>
                        <div className={this.state.step === 8 ? "step" : "inactive"} id="step8">
                            <h2>Konto zablokowane</h2>

                            <p>
                                Wykryliśmy zbyt wiele prób logowania na to konto. Aby je odblokować, skontaktuj się z naszym supportem pod numerem <a href="tel:+48500445665">(+48 500 445 665)</a>
                            </p>
                        </div>

                        <div className={this.state.step === 9 ? "step" : "inactive"} id="step9">
                            <h2>Zaczekaj</h2>

                            <p>
                                Trwa weryfikacja danych logowania.
                            </p>
                        </div>

                        <div className={this.state.step === 99 ? "step" : "inactive"} id="step99">
                            <h2>Niepowodzenie</h2>

                            <p>
                                Coś poszło nie tak. Nie jesteśmy w stanie Cię zalogować.
                            </p>
                            <p>
                                W tej chwilil można logować sie tylko na istniejące konta
                            </p>
                        </div>
                    </div>
                    {/* <pre>
                        {JSON.stringify(this.state, null, 2)}
                    </pre> */}
                </form>
            </div>
        )
    }
}

export default Login