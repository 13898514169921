import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M462 44h-47.667V30c0-16.542-13.458-30-30-30s-30 13.458-30 30v14h-168V30c0-16.542-13.458-30-30-30s-30 13.458-30 30v14.37a9.979 9.979 0 00-2.666-.37H83.823c-27.57 0-50 22.43-50 50v170.333c0 5.523 4.478 10 10 10s10-4.477 10-10V147h360.51c5.522 0 10-4.477 10-10s-4.478-10-10-10H53.823V94c0-16.542 13.458-30 30-30h39.844c.925 0 1.816-.136 2.666-.37V76.5c0 16.542 13.458 30 30 30s30-13.458 30-30V64h168v12.5c0 16.542 13.458 30 30 30s30-13.458 30-30V64H462c16.542 0 30 13.458 30 30v379.994c0 9.928-8.077 18.006-18.006 18.006s-18.006-8.078-18.006-18.006v-25.869c0-5.523-4.478-10-10-10H329.667c-5.522 0-10 4.477-10 10s4.478 10 10 10h106.321v15.869A37.775 37.775 0 00440.533 492H38c-9.925 0-18-8.075-18-18v-15.875h223.825c5.522 0 10-4.477 10-10s-4.478-10-10-10h-99.916c17.474-15.049 28.57-37.309 28.57-62.125 0-45.215-36.785-82-82-82s-82 36.785-82 82c0 24.816 11.096 47.076 28.57 62.125H10c-5.522 0-10 4.477-10 10V474c0 20.953 17.047 38 38 38h435.994C494.95 512 512 494.951 512 473.994V94c0-27.57-22.43-50-50-50zM166.333 76.5c0 5.514-4.486 10-10 10s-10-4.486-10-10V30c0-5.514 4.486-10 10-10s10 4.486 10 10v46.5zm228 0c0 5.514-4.486 10-10 10s-10-4.486-10-10V30c0-5.514 4.486-10 10-10s10 4.486 10 10v46.5zM28.479 376c0-34.187 27.813-62 62-62s62 27.813 62 62-27.813 62-62 62-62-27.813-62-62z"></path>
      <path d="M468.309 129.93a10.072 10.072 0 00-7.069-2.93c-2.631 0-5.21 1.07-7.07 2.93-1.86 1.86-2.93 4.44-2.93 7.07s1.069 5.21 2.93 7.07a10.076 10.076 0 007.07 2.93c2.63 0 5.21-1.07 7.069-2.93 1.86-1.86 2.931-4.44 2.931-7.07s-1.07-5.21-2.931-7.07zM298.649 441.05c-1.859-1.86-4.439-2.92-7.069-2.92s-5.21 1.06-7.07 2.92a10.093 10.093 0 00-2.93 7.07c0 2.64 1.069 5.21 2.93 7.08 1.86 1.86 4.44 2.92 7.07 2.92s5.21-1.06 7.069-2.92a10.118 10.118 0 002.931-7.08c0-2.63-1.07-5.2-2.931-7.07zM226.245 304c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM319.578 304c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM412.912 304c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM132.912 200c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM319.578 200c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM412.912 200c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM226.245 200c-20.953 0-38 17.047-38 38s17.047 38 38 38 38-17.047 38-38-17.047-38-38-38zm0 56c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zM126.104 351.629c-3.906-3.905-10.236-3.905-14.143 0l-32.566 32.567-11.129-11.129c-3.906-3.905-10.236-3.905-14.143 0-3.905 3.905-3.905 10.237 0 14.143l18.201 18.199a10.003 10.003 0 0014.142 0l39.638-39.638c3.905-3.905 3.905-10.237 0-14.142z"></path>
    </svg>
  );
}

export default Icon;