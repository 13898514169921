import React from "react";

function Icon(props) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M336 336c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 0M200 326c-5.516 0-10-4.484-10-10s4.484-10 10-10c3.543 0 7.281 1.809 10.816 5.227 3.97 3.84 10.301 3.738 14.141-.235 3.84-3.969 3.734-10.297-.234-14.137-5.075-4.914-10.153-7.69-14.723-9.207V276c0-5.523-4.477-10-10-10s-10 4.477-10 10v11.719c-11.64 4.129-20 15.246-20 28.281 0 16.543 13.457 30 30 30 5.512 0 10 4.484 10 10s-4.488 10-10 10c-4.273 0-8.887-2.688-12.988-7.566-3.551-4.227-9.86-4.774-14.086-1.22-4.23 3.556-4.774 9.864-1.223 14.09 5.348 6.36 11.633 10.79 18.297 13.024V396c0 5.523 4.477 10 10 10s10-4.477 10-10v-11.719c11.637-4.129 20-15.246 20-28.281 0-16.543-13.457-30-30-30zm0 0M120 166c5.523 0 10-4.477 10-10v-40c0-5.523-4.477-10-10-10s-10 4.477-10 10v40c0 5.523 4.477 10 10 10zm0 0"></path>
      <path d="M472 236v-80c0-27.57-22.43-50-50-50h-56V30c0-16.543-13.457-30-30-30H150c-16.543 0-30 13.457-30 30v36H90c-16.543 0-30 13.457-30 30v10H50c-27.57 0-50 22.43-50 50v306c0 27.57 22.43 50 50 50h306c24.145 0 44.348-17.203 48.992-40H422c27.57 0 50-22.43 50-50v-26h10c16.543 0 30-13.457 30-30v-90c0-22.055-17.945-40-40-40zm20 40c0 11.047-8.953 20-20 20v-40c11.027 0 20 8.973 20 20zm-70-150c16.543 0 30 13.457 30 30v140h-46v-60c0-24.145-17.203-44.348-40-48.992V126zM140 30c0-5.516 4.484-10 10-10h186c5.516 0 10 4.484 10 10v156h-46V96c0-16.543-13.457-30-30-30H140zm100 156V86h30c5.516 0 10 4.484 10 10v90zm-60 0V86h40v100zM80 96c0-5.516 4.484-10 10-10h70v100H80zm-30 30h10v60h-7.86C35.614 186 20 174.031 20 156c0-16.543 13.457-30 30-30zm306 366H50c-16.543 0-30-13.457-30-30V195.383C28.941 202.043 40.172 206 52.14 206H356c16.543 0 30 13.457 30 30v60h-70c-16.543 0-30 13.457-30 30v40c0 16.543 13.457 30 30 30h70v66c0 16.543-13.457 30-30 30zm96-70c0 16.543-13.457 30-30 30h-16v-56h46zm30-46H316c-5.516 0-10-4.484-10-10v-40c0-5.516 4.484-10 10-10h156c7.137 0 13.984-1.867 20-5.355V366c0 5.516-4.484 10-10 10zm0 0"></path>
      <path d="M422 336h-46c-5.523 0-10 4.477-10 10s4.477 10 10 10h46c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 0"></path>
    </svg>
  );
}

export default Icon;
