import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 368.6 368.6"
      version="1.1"
      viewBox="0 0 368.6 368.6"
      xmlSpace="preserve"
    >
      <path d="M368.4 311.1l-26.2-158c-.2-1.1-.6-2.2-1.2-3.1l-16-95.5c-.6-3.6-3.7-6.3-7.4-6.3H86.3c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5h224.9l14 83.6H161c-32.2 0-62.8 10.1-88.7 29.2s-44.5 45.5-54 76.2l-18 58.5c-.7 2.3-.3 4.7 1.1 6.7 1.4 1.9 3.7 3 6 3H361c4.1 0 7.5-3.4 7.5-7.5.1-.6 0-1.2-.1-1.8zm-350.8-5.7l15-48.7c8.5-27.7 25.3-51.4 48.6-68.6s50.9-26.3 79.8-26.3h166.7l8.5 50.7c.2 1 .6 2 1.1 2.8l14.9 90.1H17.6z"></path>
    </svg>
  );
}

export default Icon;
