import {isMobile} from "react-device-detect"
import { useNavigate } from "react-router-dom";

import Order from "./Order"

import Link from "./ScrollingLink"
import IconDelete from "./svg/IconDelete"
import "./css/Address.css"

function sendDeleteOrder(orderID, loaderAnimationControl, appUpdate, navigate){
    // alert("tets")

    // return
    loaderAnimationControl("start")

    let url = "https://api2.kurkadomowa.pl/deleteOrder.php"
    let body = {
        sessionID: localStorage.getItem('sessionID'),
        orderID: orderID,
    }
    console.warn('sendData() -> body',body)

    body = JSON.stringify(body)

    fetch(url, {
        method: 'POST',
        body: body,
        headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .catch(() => console.error('AddAddress.sendData: error parsing response:'))
    .then(res => {
        console.group('AddAddress.res')
        console.log(res)
        if(res.updateConfirmed){
            //// zapisano
            // window.scrollTo({top: 0});
            navigate("/home");
            appUpdate()
        } else {
            alert("Coś poszło nie tak. Skontaktuj się z nami pod numerem +48 500 445 665")
            console.warn('updateOrderState', res.reason)
        }
        console.groupEnd()
    })
}

function OrderDelete(props){
    let navigate = useNavigate();
    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Napewno chcesz usunąć to zlecenie?</h1>
        <Order
            order={props.order}
        />
        <div className="tileCenter">
            <IconDelete className="tileIconedIconTop" />

            <div className="buttonDiv">
                <Link to={"/order/"+props.order.orderID} className="button">Anuluj</Link>
                <button className="buttonWarn" 
                    type="button"
                    onClick={ () => sendDeleteOrder(
                    props.order.orderID,
                    props.loaderAnimationControl,
                    props.appUpdate,
                    navigate
                )}>Usuń zlecenie</button>
            </div>
            <div>
                <span className="bold">
                    Tej operacji nie można cofnąć,
                </span> <span>
                    ale zawsze możesz złożyć nowe zlecnie.
                </span>
            </div>
        </div>

        {/* <pre>
            {JSON.stringify(props, null , 2)}
        </pre> */}
    </div>)
}

export default OrderDelete