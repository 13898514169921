import React from 'react';

import "./css/App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link,
  Outlet,
  useParams,
  useLocation,
  Navigate
} from "react-router-dom";
import { motion, transform } from "framer-motion";
import Menu from "./Menu"
import Home from "./Home"
import History from "./History";
import MyAccount from "./MyAccount";
import BugReport from "./BugReport"
import Login from "./Login"
import OrderDetails from "./OrderDetails";
import AddressDelete from "./AddressDelete"
import AddressAdd from "./AddressAdd"
import Addresses from "./Addresses"
import Logout from "./Logout";
import NewLaundry from "./NewLaundry";
import About from "./About"
import OrderDelete from "./OrderDelete"
import NewOrder from "./NewOrder";
import SetDelivery from "./SetDelivery";


// import Test from "./Test";

/**
 * https://stackoverflow.com/questions/71808023/transition-between-routes-in-react-router-dom-v6-3
 */
const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};
function usePageViews() {
  let location = useLocation();

  React.useEffect(() => {
    console.log("PageView", location)
    console.warn(window.fbq)
    window.fbq('track', 'PageView');
  }, [location]);
}

// Custom component for tracking page views
function PageViewTracker() {
  usePageViews();
  return null;
}

export default function MyRouter(props) {
  return (
    <div id="MyRouter" style={props.appState.loader === "none" | props.appState.loader === "fadeOut" ? {opacity: 1} : {opacity: 0}}>
      <Router>
        <PageViewTracker />
        <Routes>
          <Route element={<AnimationLayout />}>
            <Route path="/about" element={<About />}/>
            <Route path="/report" element={<BugReport />} />
            <Route path="/newOrder" element={<NewOrder />} />

            

            {props.appState.userLogedIn ? 
              <>
                {/* tyllko dla zalogowanego */}
                <Route path="/" element={<Home orders={props.appState.orders} currentOrderNumber={props.appState.currentOrderNumber} userLogedIn={props.appState.userLogedIn}/>} />
                <Route path="/history" element={<History orders={props.appState.historyOrders} historyOrderNumber={props.appState.historyOrderNumber}/>} />
                <Route path="/myAccount" element={<MyAccount user={props.appState.user}/>} />

                <Route path="/newLaundry" element={<NewLaundry 
                  appUpdate={props.appUpdate}
                  loaderAnimationControl={props.loaderAnimationControl}
                  addressList={props.appState.addressList}
                  calendar={props.appState.calendar}
                  laundryType="wet"
                />} />
                <Route path="/newIroning" element={<NewLaundry 
                  appUpdate={props.appUpdate}
                  loaderAnimationControl={props.loaderAnimationControl}
                  addressList={props.appState.addressList}
                  calendar={props.appState.calendar}
                  laundryType="none"
                />} />
                <Route path="/newRugCleaning" element={<NewLaundry 
                  appUpdate={props.appUpdate}
                  loaderAnimationControl={props.loaderAnimationControl}
                  addressList={props.appState.addressList}
                  calendar={props.appState.calendar}
                  laundryType="rug"
                />} />
                <Route path="/newDryCleaning" element={<NewLaundry 
                  appUpdate={props.appUpdate}
                  loaderAnimationControl={props.loaderAnimationControl}
                  addressList={props.appState.addressList}
                  calendar={props.appState.calendar}
                  laundryType="dry"
                />} />
                <Route path="/cancel/:id" element={<DeleteOrder orders={props.appState.orders} loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>} />
                <Route path="setDelivery/:id" element={<DeliverySet 
                  orders={props.appState.orders} 
                  loaderAnimationControl={props.loaderAnimationControl}
                  appUpdate={props.appUpdate}
                  addressList={props.appState.addressList}
                  calendar={props.appState.calendar}
                />}/>
                <Route path="/addresses" element={<Addresses addressList={props.appState.addressList} />} />
                <Route path="/deleteAddress/:id" element={<DeleteAddress addressList={props.appState.addressList} loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>} />
                <Route path="/addAaddress" element={<AddAddress loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>}/>
                <Route path="/addAaddress/:redir" element={<AddAddress loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>}/>
                <Route path="/order/:id" element={<ValidateOrder 
                  orders={props.appState.orders}
                  historyOrders={props.appState.historyOrders}
                  addressList={props.appState.addressList}
                />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/login" element={<Navigate to="/" replace />} />
                <Route path="/register" element={<Navigate to="/" replace />} />
                <Route path="*" element={<Navigate to="/" replace />} />

                {/* <Route path="/test" element={<Test />}/> */}
              </>
            : 
              <>
                {/* Dla niezalogowanego */}
                <Route path="/" element={<About />}/>
                <Route path="/home" element={<Home orders={props.appState.orders} currentOrderNumber={props.appState.currentOrderNumber} userLogedIn={props.appState.userLogedIn}/>} />
                <Route path="/login" element={<Login loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>} />
                <Route path="*" element={<Login loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>} />
              </>
            }
          </Route>
        </Routes>
        <Menu lang="pl" userLogedIn={props.appState.userLogedIn} menuStandalone={props.appState.menuStandalone}/>
      </Router>
      {/* <pre style={{marginTop: "9rem"}}>
        {JSON.stringify(props, null, 2)}
      </pre> */}
    </div>
  );
}

function DeleteOrder(props){
  let orderID = useParams().id.match(/\d+/)[0]
  let order = props.orders.filter(item => item.orderID === orderID)[0];
  return <OrderDelete order={order} loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>
}
function DeliverySet(props){
  let orderID = useParams().id.match(/\d+/)[0]
  let order = props.orders.filter(item => item.orderID === orderID)[0];
  return (<SetDelivery 
    order={order}
    loaderAnimationControl={props.loaderAnimationControl}
    appUpdate={props.appUpdate}
    addressList={props.addressList}
    calendar={props.calendar}
  />)
}

function DeleteAddress(props){
  let addressID = useParams().id.match(/\d+/)[0]
  let address = props.addressList.filter(item => item.addressID === addressID+"")[0];
  return <AddressDelete address={address} loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>
}
function ValidateOrder(props) {
  let orderID = useParams().id.match(/\d+/)[0]
  let orders = props.orders.concat(props.historyOrders)
  let order = orders.filter(item => item.orderID === orderID)[0];
  return(
    <OrderDetails
      addressList={props.addressList}
      order={order}
    />
  )
}
function AddAddress(props){
  let redir = useParams().redir
  return <AddressAdd redir={redir} loaderAnimationControl={props.loaderAnimationControl} appUpdate={props.appUpdate}/>
}

