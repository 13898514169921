 
 function repairUtf8(str){
    if(str === undefined) return undefined

    let badUtf = ["u0105", "u015b", "u0119", "u017c", "u017a", "u0107", "u0144", "u00f3", "u0142", "u0104", "u015a", "u0118", "u017b", "u0179", "u0106", "u0143", "u00d3", "u0141"]
    let reapired = ["ą", "ś", "ę", "ż", "ź", "ć", "ń", "ó", "ł", "Ą", "Ś", "Ę", "Ż", "Ź", "Ć", "Ń" ,"Ó", "Ł"]
    let a = 0
    while(badUtf[a] !== undefined){
        if(str.indexOf(badUtf[a]) > -1){
            str = str.split(badUtf[a]).join(reapired[a])
        }
        a++
    }
    return str
 }

 export default repairUtf8