import React from "react";

function Icon(props) {
  return (
    <svg viewBox="0 0 64 64" className={props.className}>
      <path d="M34.688 3.315c-15.887 0-28.812 12.924-28.81 28.729-.012.251-.157 4.435 1.034 8.941l-3.881-2.262a2.019 2.019 0 00-2.758.727A2.017 2.017 0 001 42.205l9.095 5.302c.019.01.038.013.056.022.097.052.196.09.301.126.071.025.14.051.211.068.087.019.173.025.262.033.062.006.124.025.186.025.035 0 .068-.012.104-.014.034-.001.063.007.097.004.05-.005.095-.026.144-.034.097-.017.189-.038.282-.068a1.976 1.976 0 00.471-.229c.071-.046.139-.096.203-.15a2.013 2.013 0 00.356-.4c.027-.04.063-.069.087-.11.018-.031.018-.067.033-.098.027-.055.069-.103.093-.162l3.618-8.958a2.016 2.016 0 10-3.736-1.51l-1.858 4.6c-1.24-4.147-1.099-8.408-1.095-8.525 0-13.664 11.117-24.78 24.779-24.78 13.663 0 24.779 11.116 24.779 24.78 0 13.662-11.116 24.778-24.779 24.778a2.016 2.016 0 100 4.032c15.888 0 28.812-12.924 28.812-28.81-.002-15.888-12.926-28.812-28.813-28.812z"></path>
      <path d="M33.916 36.002c.203.084.417.114.634.129.045.003.089.027.134.027.236 0 .465-.054.684-.134.061-.022.118-.054.177-.083.167-.08.321-.182.463-.307.031-.027.072-.037.103-.068l12.587-12.587a2.015 2.015 0 000-2.851 2.015 2.015 0 00-2.851 0L34.215 31.759l-10.439-4.372a2.017 2.017 0 00-1.56 3.719L33.904 36l.012.002z"></path>
    </svg>
  );
}

export default Icon;
