import React from 'react'
import State31 from "./svg/state31"
import State50 from "./svg/state50"
import Unknown from "./svg/012-question"
import IconDryCleaning from "./svg/IconDryCleaning"
import IconRug from "./svg/IconRug"
// import dry from "./svg/icon-dry-cleaning"


function StateIcon(props){
    // props.orderType


    // wet <State31 className={props.className} />
    // none <State50 className={props.className} />
    // dru
    // rug

    if(props.orderType === "wet"){
        return <State31 className={props.className} />
    } else if(props.orderType === "none"){
        return <State50 className={props.className} />
    } else if(props.orderType === "dry"){
        return <IconDryCleaning className={props.className} />
    } else if(props.orderType === "rug"){
        return <IconRug className={props.className} />
    } else {
        return <Unknown className={props.className} />
    }
}

export default StateIcon