import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 55.017 55.017"
      version="1.1"
      viewBox="0 0 55.017 55.017"
      xmlSpace="preserve"
    >
      <path d="M51.688 23.013H40.789a1 1 0 100 2h9.102l2.899 27H2.268l3.403-27h9.118a1 1 0 100-2H3.907L0 54.013h55.017l-3.329-31z"></path>
      <path d="M26.654 38.968c-.147.087-.304.164-.445.255-.22.142-.435.291-.646.445a1.001 1.001 0 001.184 1.613 10.836 10.836 0 011.562-.96.993.993 0 00.462-1.269c.008-.008.018-.014.025-.022 1.809-1.916 7.905-9.096 10.429-21.058.512-2.426.627-4.754.342-6.919-.86-6.575-4.945-10.051-11.813-10.051-6.866 0-10.951 3.476-11.813 10.051-.284 2.166-.169 4.494.343 6.919 2.499 11.846 8.499 18.998 10.37 20.996zm-8.73-27.654c.733-5.592 3.949-8.311 9.831-8.311 5.883 0 9.098 2.719 9.83 8.311.255 1.94.148 4.043-.316 6.247C35 28.314 29.59 35.137 27.755 37.207c-1.837-2.072-7.246-8.898-9.514-19.646-.465-2.204-.571-4.306-.317-6.247z"></path>
      <path d="M27.755 19.925c4.051 0 7.346-3.295 7.346-7.346s-3.295-7.346-7.346-7.346-7.346 3.295-7.346 7.346 3.295 7.346 7.346 7.346zm0-12.691c2.947 0 5.346 2.398 5.346 5.346s-2.398 5.346-5.346 5.346-5.346-2.398-5.346-5.346 2.399-5.346 5.346-5.346zM31.428 37.17a1 1 0 10.418 1.956 23.245 23.245 0 011.886-.313.998.998 0 00.869-1.115.99.99 0 00-1.115-.869c-.723.089-1.416.204-2.058.341zM36.599 37.576a1 1 0 00.998.957l.044-.001 2.001-.083a1 1 0 00.953-1.044c-.025-.553-.539-.984-1.044-.954l-1.996.083a.998.998 0 00-.956 1.042zM22.433 42.177c-.514.388-1.045.761-1.58 1.107a1 1 0 001.09 1.678 29.36 29.36 0 001.695-1.188c.44-.333.528-.96.196-1.401a1.003 1.003 0 00-1.401-.196zM44.72 35.583c-.338.237-.777.409-1.346.526a1 1 0 10.405 1.959c.842-.174 1.526-.452 2.096-.853l.134-.098a1 1 0 00-1.207-1.595l-.082.061zM8.86 43.402a.999.999 0 00-.704-1.226.996.996 0 00-1.226.704 6.688 6.688 0 00-.233 1.758c.001.196.01.392.029.587a1 1 0 001.991-.204 3.996 3.996 0 01-.013-.638c.016-.324.068-.655.156-.981zM44.698 27.81a5.88 5.88 0 00-2.386.181 1 1 0 00.543 1.924 3.903 3.903 0 011.575-.122 1 1 0 00.268-1.983zM46.688 32.764a1 1 0 001.91.59c.099-.318.16-.641.183-.963a3.991 3.991 0 00-.247-1.642 1.001 1.001 0 00-1.875.695c.086.232.13.467.13.696l-.003.117a2.255 2.255 0 01-.098.507zM17.481 45.164c-.586.275-1.183.53-1.774.759a1 1 0 10.721 1.866 29.865 29.865 0 001.903-.813 1 1 0 00.48-1.33.997.997 0 00-1.33-.482zM10.201 41.001a1 1 0 00.478-.122c.288-.157.595-.255.911-.289.135-.016.273-.016.406.002a.99.99 0 001.122-.86 1 1 0 00-.86-1.122 3.693 3.693 0 00-.891-.008 4.445 4.445 0 00-1.646.521.999.999 0 00.48 1.878zM9.993 48.842c.216.056.436.098.654.124.256.031.512.047.769.047.313 0 .627-.022.94-.062a.999.999 0 10-.25-1.984 4.9 4.9 0 01-1.216.015 3.335 3.335 0 01-.394-.075.997.997 0 00-1.22.716 1.001 1.001 0 00.717 1.219z"></path>
    </svg>
  );
}

export default Icon;
