import React from 'react'
import {isMobile} from "react-device-detect"
import Order from "./Order"
// import "./css/Order.css"

function History(props){
    // let lastH1
    let orderList = []
    let i = 0
    let header = "2023"
    while(props.orders[i] !== undefined){
        if(header !== props.orders[i].pickup.date.split("-")[0]){
            header = props.orders[i].pickup.date.split("-")[0]
            orderList.push(<h2 key={"header"+header}>{header}</h2>)
        }
        orderList.push(<Order order={props.orders[i]} key={"o"+props.orders[i].orderID}/>)
        i++
    }

    // let test = []
    // let a = -1
    // while(a<100){
    //     test.push(<div className="OrderTile">
    //         <StateIcon orderState={a} className={"orderStateIcon"}/>
    //         {a}
    //         <StateText
    //             lang={"pl"}
    //             orderState={a}/>
    //     </div>)
    //     a++
    // }
    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        {/* {test} */}
        <h1>Historia zleceń</h1>
        {orderList}

        {props.historyOrderNumber === 0 ? 
            <div className="lesVisibleTile">
                <span>W tej chwili nie posiadasz żadnych zakończonych zleceń. Pojawią się one automatycznie po ich zakończeniu.</span>
            </div>
        : null} 

        {/* <div className='tile'>
            <pre>
                {JSON.stringify(props, null, 2)}
            </pre>
        </div> */}
    </div>
    )
}

export default History