import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <g>
        <path d="M40 262.17a25.844 25.844 0 0046.521 15.489l.649-.866.643.857a25.842 25.842 0 1036.192-36.167h-.008l-.864-.648.865-.648A25.843 25.843 0 1087.817 204l-.647.864-.654-.874a25.844 25.844 0 10-36.175 36.188l.866.649-.866.649A25.645 25.645 0 0040 262.17zm52.882-21.337a5.714 5.714 0 11-9.755-4.04 5.715 5.715 0 019.755 4.041zm22.57 28.28a9.834 9.834 0 01-14.832-1.053l-5.322-7.1a21.712 21.712 0 0012.007-12l7.092 5.313a9.845 9.845 0 011.055 14.836zm-7.652-59.424a9.841 9.841 0 016.606 17.689l-7.1 5.319a21.761 21.761 0 00-12-12l5.316-7.1a9.712 9.712 0 017.178-3.908zm-48.909 2.858a9.834 9.834 0 0114.83 1.05l5.318 7.1a21.72 21.72 0 00-12 12.006l-7.1-5.323a9.835 9.835 0 01-1.05-14.831zm1.049 41.733l7.094-5.32a21.667 21.667 0 0012.006 12.006l-5.319 7.093A9.843 9.843 0 1159.94 254.28zM144.065 124.327A25.844 25.844 0 00169.838 152c.609 0 1.222-.021 1.835-.065a25.835 25.835 0 0018.843-10.272l.651-.869.652.869A25.841 25.841 0 10228 105.485l-.869-.651.87-.652A25.842 25.842 0 10191.818 68l-.651.869-.652-.869a25.842 25.842 0 10-36.178 36.179l.869.651-.869.652a25.831 25.831 0 00-10.272 18.845zm51.142-15.454a5.713 5.713 0 01-8.08-8.08 5.714 5.714 0 118.08 8.081zm24.244 24.243a9.84 9.84 0 01-14.832-1.053l-5.323-7.1a21.715 21.715 0 0012.009-12l7.092 5.319a9.841 9.841 0 011.054 14.832zm-7.657-59.425a9.841 9.841 0 016.6 17.69l-7.092 5.32A21.765 21.765 0 00199.3 84.7l5.319-7.092a9.708 9.708 0 017.175-3.917zm-48.91 2.858a9.84 9.84 0 0114.831 1.051l5.319 7.092a21.723 21.723 0 00-12 12.009l-7.1-5.322a9.84 9.84 0 01-1.054-14.833zm-2.859 48.912a9.7 9.7 0 013.912-7.176l7.1-5.323a21.671 21.671 0 0012 12l-5.322 7.1a9.842 9.842 0 01-17.691-6.6zM408 352a32.036 32.036 0 0032-32v-64a32 32 0 00-64 0v64a32.036 32.036 0 0032 32zm-16-96a16 16 0 0132 0v64a16 16 0 01-32 0z"></path>
        <path d="M32 448a48.053 48.053 0 0048 48h326a74.083 74.083 0 0074-74V318.3a313.343 313.343 0 00-80-209.351V80a8 8 0 00-8-8V40a24.028 24.028 0 00-24-24h-32a24.028 24.028 0 00-24 24v32a8 8 0 00-8 8v28.947A313.345 313.345 0 00224 318.3v1.7H80a47.983 47.983 0 00-26.494 88A47.991 47.991 0 0032 448zM320 88h64v16h-64zm8-48a8.009 8.009 0 018-8h32a8.009 8.009 0 018 8v32h-48zm-88 278.3A297.359 297.359 0 01315.556 120h72.888A297.393 297.393 0 01464 318.3V422a58.066 58.066 0 01-58 58h-48.707a27.935 27.935 0 00-5.314-31.587c.007-.138.021-.274.021-.413s-.014-.275-.021-.413a27.932 27.932 0 00-.41-39.587 27.932 27.932 0 00.41-39.587c.007-.138.021-.274.021-.413s-.014-.275-.021-.413A27.978 27.978 0 00332 320h-92zM48 368a32.036 32.036 0 0132-32h252a12 12 0 010 24H80a8 8 0 000 16h252a12 12 0 010 24H80a32.036 32.036 0 01-32-32zm32 48h252a12 12 0 010 24H80a8 8 0 000 16h252a12 12 0 010 24H80a32 32 0 010-64z"></path>
      </g>
    </svg>
  );
}

export default Icon;
