import React, { Component } from 'react'
import Loader from './Loader'
// import getCookie from './func/getCookie'
// import StateDebuger from './StateDebuger'
// import SoftenerText from './SoftenerText'


// class SoftenerSelect extends Component{
//     constructor(){
//         super()
//         this.state = {
//             debugComponent: false,
            
//             // isLoading: true,
//             softeners: null
//         }
//         this.txt = {
//             pl: {
//                 "softener--1": "Bez płynu do płukania",
//                 "softener-1": "Silan - Citrus oil & Frangipani",
//                 "softener-2": "Silan - Lavender garden",
//                 "softener-3": "Silan - Jasmine oil & Lily",
//                 "softener-4": "Silan - Sensitive (Dla niemowląt i alergików)",
//                 "softener-5": "Silan - Fresh Sky",
//                 "softener-6": "Silan - Orange oil & Magnolia",
//                 "softener-7": "Silan - Lemon Blossom Scent & Minerals",
//                 "softener-8": "Silan - Rose Oil & Peony",
//                 "softener-9": "Silan - Coconut Water Scent & Minerals",
//                 "softener-10": "Silan - Peony & White Tea Scent",
//                 "softener-11": "Silan - Patchouli Oil & Lotus",
//                 "softener-21": "Lenor - Gold orchid",
//                 "softener-22": "Lenor - Wild verbena",
//                 "softener-23": "Lenor - Silk tree blossom",
//                 "softener-24": "Lenor - Deep sea minerals",
//                 "softener-25": "Lenor - Amethyst & Floral bouqet",
//                 "softener-26": "Lenor - Shea butter",
//                 "softener-27": "Lenor - Sensitive (Dla niemowląt i alergików)",
//                 "softener-28": "Lenor - Floral romance",
//                 "softener-29": "Lenor - Fresh meadow",
//                 "softener-30": "Lenor - Lavender & camomile",
//                 "softener-31": "Lenor - Summer breeze",
//                 "softener-32": "Lenor - Spring awakening",
//                 "softener-33": "Lenor - Diamond & Lotus flower",
//                 "softener-34": "Lenor - Emerald & Ivory flower",
//                 "softener-35": "Lenor - Lavender",
//                 "softener-51": "Softlan - Fruhlingsfrische",
//                 "softener-52": "Softlan - Vanille & orchidee",
//                 "softener-53": "Softlan - Windfrisch",
//                 "softener-54": "Softlan - Kirschbluten",
//                 "softener-55": "Softlan - Weich & Mild sensitiv",
//                 "softener-56": "Softlan - Traumfrisch"
//             },
//             en: {
//                 "softener--1": "Without softener",
//                 "softener-1": "Silan - Citrus oil & Frangipani",
//                 "softener-2": "Silan - Lavender garden",
//                 "softener-3": "Silan - Jasmine oil & Lily",
//                 "softener-4": "Silan - Sensitive (Dla niemowląt i alergików)",
//                 "softener-5": "Silan - Fresh Sky",
//                 "softener-6": "Silan - Orange oil & Magnolia",
//                 "softener-7": "Silan - Lemon Blossom Scent & Minerals",
//                 "softener-8": "Silan - Rose Oil & Peony",
//                 "softener-9": "Silan - Coconut Water Scent & Minerals",
//                 "softener-10": "Silan - Peony & White Tea Scent",
//                 "softener-11": "Silan - Patchouli Oil & Lotus",
//                 "softener-21": "Lenor - Gold orchid",
//                 "softener-22": "Lenor - Wild verbena",
//                 "softener-23": "Lenor - Silk tree blossom",
//                 "softener-24": "Lenor - Deep sea minerals",
//                 "softener-25": "Lenor - Amethyst & Floral bouqet",
//                 "softener-26": "Lenor - Shea butter",
//                 "softener-27": "Lenor - Sensitive (Dla niemowląt i alergików)",
//                 "softener-28": "Lenor - Floral romance",
//                 "softener-29": "Lenor - Fresh meadow",
//                 "softener-30": "Lenor - Lavender & camomile",
//                 "softener-31": "Lenor - Summer breeze",
//                 "softener-32": "Lenor - Spring awakening",
//                 "softener-33": "Lenor - Diamond & Lotus flower",
//                 "softener-34": "Lenor - Emerald & Ivory flower",
//                 "softener-35": "Lenor - Lavender",
//                 "softener-51": "Softlan - Fruhlingsfrische",
//                 "softener-52": "Softlan - Vanille & orchidee",
//                 "softener-53": "Softlan - Windfrisch",
//                 "softener-54": "Softlan - Kirschbluten",
//                 "softener-55": "Softlan - Weich & Mild sensitiv",
//                 "softener-56": "Softlan - Traumfrisch"
//             }
//         }
//         this.getSoftenerData = this.getSoftenerData.bind(this)
//     }
//     componentDidMount(){
//         this.getSoftenerData()
//     }
//     getSoftenerData(){
//         fetch("https://api.kurkadomowa.pl/softener.php", {
//             method: 'POST',
//             body: JSON.stringify({
//                 sessionID: localStorage.getItem('sessionID')
//             }),
//             headers: {'Content-Type': 'application/json'}
//         })
//         .then(res => res.json())
//         .catch(() => console.error('SoftenerSelect.getSoftenerData: error parsing response:'))
//         .then(res => {
//             console.log(res)
//             this.setState({
//                 softeners: res.softeners,
//                 res: res,
//                 isLoading: false
//             })
//         })
//     }
//     text(txt_name, txt_name2){
//         if(this.props.lang === undefined || this.txt[this.props.lang] === undefined || this.txt[this.props.lang][txt_name] === undefined){
//             if(txt_name2 !== undefined){
//                 return this.constructor.name+'->'+txt_name+'->'+txt_name2
//             }
//             return this.constructor.name+'->'+txt_name
//         }
//         if(typeof this.txt[this.props.lang][txt_name] === 'object'){
//             return  this.txt[this.props.lang][txt_name][txt_name2]
//         }
//         return this.txt[this.props.lang][txt_name]
//     }
//     render(){
//         if(this.state.isLoading){
//             return(
//                 <div>
//                     <Loader />
//                     {/* <StateDebuger
//                         force={this.state.debugComponent}
//                         data={this}
//                     /> */}
//                 </div>
//             )
//         }
//         let options = []
//         if(this.state.softeners !== null){
//             for( var key in this.state.softeners){
//                 options.push(
//                     <option key={"softener"+this.state.softeners[key].id} value={this.state.softeners[key].id}>
//                         {this.text('softener-'+this.state.softeners[key].id)}
//                     </option>
//                 )
//             }
//         }

//         //console.log(options)
//         return(
//             <div>
//                 <select
//                     name="selectedSoftener"
//                     onChange={this.props.chandleChange}
//                     value={this.props.selectedSoftener}
//                 >
//                     <select>test</select>
//                     {
//                     options
//                     }
//                 </select>
//                 {/* <StateDebuger
//                     force={this.state.debugComponent}
//                     data={this}
//                 /> */}
//             </div>
//         )
//     }
// }


function SoftenerSelect(props){
    return(
    <div>
        <select
            name="selectedSoftener"
            onChange={props.chandleChange}
            value={props.selectedSoftener}
        >
            <optgroup label="">
                <option value="-2">     Obojętnie jaki                                      </option>
                <option value="-1">     Brak płynu do płukania                              </option>
            </optgroup>
            <optgroup label="Silan">
                <option value="1">      Silan - Citrus oil & Frangipani                     </option>
                <option value="2">      Silan - Lavender garden                             </option>
                <option value="3">      Silan - Jasmine oil & Lily                          </option>
                <option value="4">      Silan - Sensitive (Dla niemowląt i alergików)       </option>
                <option value="5">      Silan - Fresh Sky                                   </option>
                <option value="6">      Silan - Orange oil & Magnolia                       </option>
                <option value="7">      Silan - Lemon Blossom Scent & Minerals              </option>
                <option value="8">      Silan - Rose Oil & Peony                            </option>
                <option value="9">      Silan - Coconut Water Scent & Minerals              </option>
                <option value="10">     Silan - Peony & White Tea Scent                     </option>
                <option value="11">     Silan - Patchouli Oil & Lotus                       </option>
            </optgroup>
            <optgroup label="Lenor">
                <option value="21">     Lenor - Gold orchid                                 </option>
                <option value="22">     Lenor - Wild verbena                                </option>
                <option value="23">     Lenor - Silk tree blossom                           </option>
                <option value="24">     Lenor - Deep sea minerals                           </option>
                <option value="25">     Lenor - Amethyst & Floral bouqet                    </option>
                <option value="26">     Lenor - Shea butter                                 </option>
                <option value="27">     Lenor - Sensitive (Dla niemowląt i alergików)       </option>
                <option value="28">     Lenor - Floral romance                              </option>
                <option value="29">     Lenor - Fresh meadow                                </option>
                <option value="30">     Lenor - Lavender & camomile                         </option>
                <option value="31">     Lenor - Summer breeze                               </option>
                <option value="32">     Lenor - Spring awakening                            </option>
                <option value="33">     Lenor - Diamond & Lotus flower                      </option>
                <option value="34">     Lenor - Emerald & Ivory flower                      </option>
                <option value="35">     Lenor - Lavender                                    </option>
            </optgroup>
            <optgroup label="Softlan">
                <option value="51">     Softlan - Fruhlingsfrische                          </option>
                <option value="52">     Softlan - Vanille & orchidee                        </option>
                <option value="53">     Softlan - Windfrisch                                </option>
                <option value="54">     Softlan - Kirschbluten                              </option>
                <option value="55">     Softlan - Weich & Mild sensitiv                     </option>
                <option value="56">     Softlan - Traumfrisch                               </option>
            </optgroup>
        </select>
        {/* <StateDebuger
        force={this.state.debugComponent}
        data={this}
        /> */}
    </div>
    )
}

export default SoftenerSelect