import React from 'react'
// import Link from "./ScrollingLink"

function MenuItemIcon(props){
    // function handleClick(event){
    //     props.handleClick({next: event.target.getAttribute("name")})
    // }
    if(props.name === "home"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 512 512">
                <path d="M506.555,208.064L263.859,30.367c-4.68-3.426-11.038-3.426-15.716,0L5.445,208.064 c-5.928,4.341-7.216,12.665-2.875,18.593s12.666,7.214,18.593,2.875L256,57.588l234.837,171.943c2.368,1.735,5.12,2.57,7.848,2.57 c4.096,0,8.138-1.885,10.744-5.445C513.771,220.729,512.483,212.405,506.555,208.064z"></path> 
                <path d="M442.246,232.543c-7.346,0-13.303,5.956-13.303,13.303v211.749H322.521V342.009c0-36.68-29.842-66.52-66.52-66.52 s-66.52,29.842-66.52,66.52v115.587H83.058V245.847c0-7.347-5.957-13.303-13.303-13.303s-13.303,5.956-13.303,13.303v225.053 c0,7.347,5.957,13.303,13.303,13.303h133.029c6.996,0,12.721-5.405,13.251-12.267c0.032-0.311,0.052-0.651,0.052-1.036v-128.89 c0-22.009,17.905-39.914,39.914-39.914s39.914,17.906,39.914,39.914v128.89c0,0.383,0.02,0.717,0.052,1.024 c0.524,6.867,6.251,12.279,13.251,12.279h133.029c7.347,0,13.303-5.956,13.303-13.303V245.847 C455.549,238.499,449.593,232.543,442.246,232.543z"></path>
            </svg>
        )
    }
    if(props.name === "newOrder"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 467.2 467.2" >
                <path d="M455.146,120.9l-91.7-116.3c-2.3-2.9-5.7-4.6-9.4-4.6h-240.8c-3.7,0-7.1,1.7-9.4,4.6l-91.7,116.3 c-1.7,2.1-2.6,4.7-2.6,7.4v326.9c0,6.6,5.4,12,12,12h424.1c6.6,0,12-5.4,12-12V128.3C457.746,125.6,456.846,123,455.146,120.9z M422.546,118.3h-176.9V24h102.6L422.546,118.3z M119.046,24h102.6v94.3h-176.9L119.046,24z M33.546,443.2V142.3h400.1v300.9 L33.546,443.2L33.546,443.2z"/>
                <path d="M303.046,284.2h-57.4v-57.4c0-6.6-5.4-12-12-12s-12,5.4-12,12v57.4h-57.4c-6.6,0-12,5.4-12,12s5.4,12,12,12h57.4v57.4 c0,6.6,5.4,12,12,12s12-5.4,12-12v-57.4h57.4c6.6,0,12-5.4,12-12S309.746,284.2,303.046,284.2z"/>
            </svg>
        )
    }
    else if(props.name === "history"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 64 64">
                <path d="m34.688,3.315c-15.887,0-28.812,12.924-28.81,28.729-0.012,0.251-0.157,4.435 1.034,8.941l-3.881-2.262c-0.964-0.56-2.192-0.235-2.758,0.727-0.558,0.96-0.234,2.195 0.727,2.755l9.095,5.302c0.019,0.01 0.038,0.013 0.056,0.022 0.097,0.052 0.196,0.09 0.301,0.126 0.071,0.025 0.14,0.051 0.211,0.068 0.087,0.019 0.173,0.025 0.262,0.033 0.062,0.006 0.124,0.025 0.186,0.025 0.035,0 0.068-0.012 0.104-0.014 0.034-0.001 0.063,0.007 0.097,0.004 0.05-0.005 0.095-0.026 0.144-0.034 0.097-0.017 0.189-0.038 0.282-0.068 0.078-0.026 0.155-0.057 0.23-0.093 0.084-0.04 0.163-0.084 0.241-0.136 0.071-0.046 0.139-0.096 0.203-0.15 0.071-0.06 0.134-0.125 0.197-0.195 0.059-0.065 0.11-0.133 0.159-0.205 0.027-0.04 0.063-0.069 0.087-0.11 0.018-0.031 0.018-0.067 0.033-0.098 0.027-0.055 0.069-0.103 0.093-0.162l3.618-8.958c0.417-1.032-0.081-2.207-1.112-2.624-1.033-0.414-2.207,0.082-2.624,1.114l-1.858,4.6c-1.24-4.147-1.099-8.408-1.095-8.525 0-13.664 11.117-24.78 24.779-24.78 13.663,0 24.779,11.116 24.779,24.78 0,13.662-11.116,24.778-24.779,24.778-1.114,0-2.016,0.903-2.016,2.016s0.901,2.016 2.016,2.016c15.888,0 28.812-12.924 28.812-28.81-0.002-15.888-12.926-28.812-28.813-28.812z"></path>
                <path d="m33.916,36.002c0.203,0.084 0.417,0.114 0.634,0.129 0.045,0.003 0.089,0.027 0.134,0.027 0.236,0 0.465-0.054 0.684-0.134 0.061-0.022 0.118-0.054 0.177-0.083 0.167-0.08 0.321-0.182 0.463-0.307 0.031-0.027 0.072-0.037 0.103-0.068l12.587-12.587c0.788-0.788 0.788-2.063 0-2.851-0.787-0.788-2.062-0.788-2.851,0l-11.632,11.631-10.439-4.372c-1.033-0.431-2.209,0.053-2.64,1.081-0.43,1.027 0.055,2.208 1.08,2.638l11.688,4.894c0.004,0.001 0.008,0.001 0.012,0.002z"></path>
            </svg>
        )
    }
    else if(props.name === "myAccount"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 482.9 482.9">
                <path d="M239.7,260.2c0.5,0,1,0,1.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1,0c29.3-0.5,53-10.8,70.5-30.5 c38.5-43.4,32.1-117.8,31.4-124.9c-2.5-53.3-27.7-78.8-48.5-90.7C280.8,5.2,262.7,0.4,242.5,0h-0.7c-0.1,0-0.3,0-0.4,0h-0.6 c-11.1,0-32.9,1.8-53.8,13.7c-21,11.9-46.6,37.4-49.1,91.1c-0.7,7.1-7.1,81.5,31.4,124.9C186.7,249.4,210.4,259.7,239.7,260.2z  M164.6,107.3c0-0.3,0.1-0.6,0.1-0.8c3.3-71.7,54.2-79.4,76-79.4h0.4c0.2,0,0.5,0,0.8,0c27,0.6,72.9,11.6,76,79.4 c0,0.3,0,0.6,0.1,0.8c0.1,0.7,7.1,68.7-24.7,104.5c-12.6,14.2-29.4,21.2-51.5,21.4c-0.2,0-0.3,0-0.5,0l0,0c-0.2,0-0.3,0-0.5,0 c-22-0.2-38.9-7.2-51.4-21.4C157.7,176.2,164.5,107.9,164.6,107.3z"></path>
                <path d="M446.8,383.6c0-0.1,0-0.2,0-0.3c0-0.8-0.1-1.6-0.1-2.5c-0.6-19.8-1.9-66.1-45.3-80.9c-0.3-0.1-0.7-0.2-1-0.3 c-45.1-11.5-82.6-37.5-83-37.8c-6.1-4.3-14.5-2.8-18.8,3.3c-4.3,6.1-2.8,14.5,3.3,18.8c1.7,1.2,41.5,28.9,91.3,41.7 c23.3,8.3,25.9,33.2,26.6,56c0,0.9,0,1.7,0.1,2.5c0.1,9-0.5,22.9-2.1,30.9c-16.2,9.2-79.7,41-176.3,41 c-96.2,0-160.1-31.9-176.4-41.1c-1.6-8-2.3-21.9-2.1-30.9c0-0.8,0.1-1.6,0.1-2.5c0.7-22.8,3.3-47.7,26.6-56 c49.8-12.8,89.6-40.6,91.3-41.7c6.1-4.3,7.6-12.7,3.3-18.8c-4.3-6.1-12.7-7.6-18.8-3.3c-0.4,0.3-37.7,26.3-83,37.8 c-0.4,0.1-0.7,0.2-1,0.3c-43.4,14.9-44.7,61.2-45.3,80.9c0,0.9,0,1.7-0.1,2.5c0,0.1,0,0.2,0,0.3c-0.1,5.2-0.2,31.9,5.1,45.3 c1,2.6,2.8,4.8,5.2,6.3c3,2,74.9,47.8,195.2,47.8s192.2-45.9,195.2-47.8c2.3-1.5,4.2-3.7,5.2-6.3 C447,415.5,446.9,388.8,446.8,383.6z"></path>
            </svg>
        )
    }
    else if(props.name === "faq"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 512 512">
                <path d="M346,319c-5.522,0-10,4.477-10,10v69c0,27.57-22.43,50-50,50H178.032c-5.521,0-9.996,4.473-10,9.993l-0.014,19.882
                    l-23.868-23.867c-1.545-3.547-5.081-6.008-9.171-6.008H70c-27.57,0-50-22.43-50-50V244c0-27.57,22.43-50,50-50h101
                    c5.522,0,10-4.477,10-10s-4.478-10-10-10H70c-38.598,0-70,31.402-70,70v154c0,38.598,31.402,70,70,70h59.858l41.071,41.071
                    c1.913,1.913,4.47,2.929,7.073,2.929c1.287,0,2.586-0.249,3.821-0.76c3.737-1.546,6.174-5.19,6.177-9.233L188.024,468H286
                    c38.598,0,70-31.402,70-70v-69C356,323.477,351.522,319,346,319z"/>
                <path d="M366.655,0h-25.309C261.202,0,196,65.202,196,145.346s65.202,145.345,145.345,145.345h25.309
                    c12.509,0,24.89-1.589,36.89-4.729l37.387,37.366c1.913,1.911,4.469,2.927,7.071,2.927c1.289,0,2.589-0.249,3.826-0.762
                    c3.736-1.548,6.172-5.194,6.172-9.238v-57.856c15.829-12.819,28.978-29.012,38.206-47.102
                    C506.687,190.751,512,168.562,512,145.346C512,65.202,446.798,0,366.655,0z M441.983,245.535
                    c-2.507,1.889-3.983,4.847-3.983,7.988v38.6l-24.471-24.458c-1.904-1.902-4.458-2.927-7.07-2.927c-0.98,0-1.97,0.145-2.936,0.442
                    c-11.903,3.658-24.307,5.512-36.868,5.512h-25.309c-69.117,0-125.346-56.23-125.346-125.346S272.23,20,341.346,20h25.309
                    C435.771,20,492,76.23,492,145.346C492,185.077,473.77,221.595,441.983,245.535z"/>
                <path d="M399.033,109.421c-1.443-20.935-18.319-37.811-39.255-39.254c-11.868-0.815-23.194,3.188-31.863,11.281
                    c-8.55,7.981-13.453,19.263-13.453,30.954c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10c0-6.259,2.522-12.06,7.1-16.333
                    c4.574-4.269,10.552-6.382,16.842-5.948c11.028,0.76,19.917,9.649,20.677,20.676c0.768,11.137-6.539,20.979-17.373,23.403
                    c-8.778,1.964-14.908,9.592-14.908,18.549v24.025c0,5.523,4.478,10,10,10c5.523,0,10-4.477,9.999-10v-23.226
                    C386.949,148.68,400.468,130.242,399.033,109.421z"/>
                <path d="M363.87,209.26c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
                    c0,2.64,1.071,5.22,2.93,7.08c1.86,1.86,4.44,2.92,7.07,2.92s5.21-1.06,7.07-2.92c1.86-1.87,2.93-4.44,2.93-7.08
                    C366.8,213.7,365.729,211.12,363.87,209.26z"/>
                <path d="M275,310H64c-5.522,0-10,4.477-10,10s4.478,10,10,10h211c5.523,0,10-4.477,10-10S280.522,310,275,310z"/>
                <path d="M282.069,368.93C280.21,367.07,277.63,366,275,366s-5.21,1.07-7.07,2.93c-1.861,1.86-2.93,4.44-2.93,7.07
                    s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.069-2.93c1.861-1.86,2.931-4.43,2.931-7.07
                    C285,373.37,283.929,370.79,282.069,368.93z"/>
                <path d="M235.667,366H64c-5.522,0-10,4.477-10,10s4.478,10,10,10h171.667c5.523,0,10-4.477,10-10S241.189,366,235.667,366z"/>
                <path d="M210,254H64c-5.522,0-10,4.477-10,10s4.478,10,10,10h146c5.523,0,10-4.477,10-10S215.522,254,210,254z"/>
            </svg>
        )
    }
    else if(props.name === "report"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 512 512">
                <path d="M256,246c-5.52,0-10,4.48-10,10s4.48,10,10,10s10-4.48,10-10C266,250.48,261.52,246,256,246z"/>
                <path d="M502,246h-10.239c-1.234-27.526-7.549-54.555-18.847-80.484c-12.157-27.901-29.435-53.161-51.352-75.078
                    s-47.177-39.194-75.078-51.352C320.555,27.787,293.526,21.473,266,20.239V10c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10
                    v10.239c-27.526,1.234-54.555,7.549-80.484,18.847c-27.901,12.157-53.161,29.435-75.078,51.352s-39.194,47.177-51.352,75.078
                    C27.787,191.445,21.473,218.474,20.239,246H10c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h10.239
                    c1.234,27.526,7.549,54.555,18.847,80.484c12.157,27.901,29.435,53.161,51.352,75.078c21.917,21.917,47.177,39.194,75.078,51.352
                    c25.929,11.298,52.959,17.613,80.484,18.847V502c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-10.239
                    c27.526-1.234,54.555-7.549,80.484-18.847c27.901-12.157,53.161-29.435,75.078-51.352s39.194-47.177,51.352-75.078
                    c11.298-25.929,17.613-52.959,18.847-80.484H502c5.522,0,10-4.478,10-10C512,250.478,507.522,246,502,246z M436,266h35.737
                    C466.277,373.529,373.529,466.277,266,471.737V436c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10v35.737
                    C138.471,466.277,45.723,373.529,40.263,266H76c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H40.263
                    C45.723,138.471,138.471,45.723,246,40.263V76c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10V40.263
                    C373.529,45.723,466.277,138.471,471.737,246H436c-5.522,0-10,4.478-10,10C426,261.522,430.478,266,436,266z"/>
                <path d="M396,266h-50v-21.006c22.795-4.646,40-24.847,40-48.994c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10
                    c0,13.518-8.989,24.971-21.301,28.709c-4.54-19.585-20.609-34.794-40.649-38.052c1.276-3.36,1.95-6.956,1.95-10.657
                    c0-13.349-5.202-25.903-14.649-35.353c-0.982-0.981-2.003-1.912-3.053-2.802l14.774-14.774c3.905-3.905,3.905-10.237,0-14.143
                    c-3.906-3.904-10.236-3.904-14.143,0l-19.04,19.04C265.409,126.67,260.763,126,256,126c-4.765,0-9.413,0.67-13.89,1.968
                    l-19.039-19.039c-3.906-3.904-10.236-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l14.774,14.774
                    c-1.051,0.891-2.072,1.822-3.054,2.804C211.202,150.097,206,162.651,206,176c0,3.752,0.698,7.342,1.962,10.655
                    c-20.045,3.254-36.12,18.466-40.66,38.055C154.989,220.971,146,209.518,146,196c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10
                    c0,24.146,17.205,44.348,40,48.994V266h-50c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h50v21.006
                    c-22.795,4.646-40,24.847-40,48.994c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10c0-13.307,8.711-24.611,20.728-28.527
                    C172.38,371.702,210.26,406,256,406s83.62-34.298,89.272-78.527C357.289,331.389,366,342.693,366,356c0,5.522,4.478,10,10,10
                    c5.522,0,10-4.478,10-10c0-24.146-17.205-44.348-40-48.994V286h50c5.522,0,10-4.478,10-10C406,270.478,401.522,266,396,266z
                    M234.79,154.792c2.584-2.584,5.566-4.648,8.886-6.147C247.539,146.89,251.686,146,256,146c4.314,0,8.461,0.89,12.345,2.654
                    c3.299,1.489,6.281,3.554,8.864,6.136C282.878,160.46,286,167.992,286,176c0,2.668-1.041,5.178-2.932,7.068
                    c-1.891,1.89-4.4,2.932-7.068,2.932h-40c-5.514,0-10-4.486-10-10C226,167.992,229.122,160.46,234.79,154.792z M326,316
                    c0,35.203-26.123,64.406-60,69.274V296c0-5.522-4.478-10-10-10c-5.522,0-10,4.478-10,10v89.274c-33.877-4.868-60-34.072-60-69.274
                    v-80c0-16.542,13.458-30,30-30h20h10v10c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10v-10h10h20c16.542,0,30,13.458,30,30V316z"
                    />
            </svg>
        )
    }

    else if(props.name === "logout"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 511.989 511.989">
                <path d="M110.933,221.782c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-51.2 C119.467,225.605,115.644,221.782,110.933,221.782z"></path>
                <path d="M111.855,2.304L31.172,34.586C8.448,43,0,54.418,0,76.715v358.477c0,22.298,8.448,33.715,30.959,42.061l81.058,32.427 c4.011,1.519,8.038,2.287,11.981,2.287c17.152,0,29.602-14.336,29.602-34.091V34.049C153.6,9.78,134.246-6.126,111.855,2.304z M136.533,477.876c0,10.18-5.035,17.024-12.535,17.024c-1.869,0-3.883-0.401-5.803-1.118L37.103,461.33 c-16.102-5.965-20.036-11.102-20.036-26.138V76.715c0-15.036,3.934-20.164,20.241-26.206l80.725-32.29 c2.082-0.785,4.087-1.186,5.956-1.186c7.501,0,12.544,6.835,12.544,17.016V477.876z"></path>
                <path d="M178.133,51.115h120.533c14.114,0,25.6,11.486,25.6,25.6v128c0,4.71,3.814,8.533,8.533,8.533 c4.719,0,8.533-3.823,8.533-8.533v-128c0-23.526-19.14-42.667-42.667-42.667H178.133c-4.71,0-8.533,3.823-8.533,8.533 S173.423,51.115,178.133,51.115z"></path>
                <path d="M332.8,298.582c-4.719,0-8.533,3.823-8.533,8.533v128c0,14.114-11.486,25.6-25.6,25.6H179.2 c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h119.467c23.526,0,42.667-19.14,42.667-42.667v-128 C341.333,302.405,337.519,298.582,332.8,298.582z"></path>
                <path d="M511.343,252.655c-0.435-1.05-1.058-1.988-1.852-2.782l-85.325-85.333c-3.337-3.336-8.73-3.336-12.066,0 c-3.337,3.337-3.337,8.73,0,12.066l70.767,70.775H196.267c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533 h286.601L412.1,335.215c-3.337,3.337-3.337,8.73,0,12.066c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5 l85.325-85.325c0.794-0.794,1.417-1.732,1.852-2.782C512.205,257.093,512.205,254.738,511.343,252.655z"></path>
            </svg>
        )
    }
    else if(props.name === "login"){
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
                viewBox="0 0 511.989 511.989">
                <g transform="matrix(-1,0,0,1,511.989,0)">
                    <path d="M110.933,221.782C106.223,221.782 102.4,225.605 102.4,230.315L102.4,281.515C102.4,286.225 106.223,290.048 110.933,290.048C115.643,290.048 119.466,286.225 119.466,281.515L119.466,230.315C119.467,225.605 115.644,221.782 110.933,221.782Z"/>
                </g>
                <g transform="matrix(-1,0,0,1,511.989,0)">
                    <path d="M111.855,2.304L31.172,34.586C8.448,43 0,54.418 0,76.715L0,435.192C0,457.49 8.448,468.907 30.959,477.253L112.017,509.68C116.028,511.199 120.055,511.967 123.998,511.967C141.15,511.967 153.6,497.631 153.6,477.876L153.6,34.049C153.6,9.78 134.246,-6.126 111.855,2.304ZM136.533,477.876C136.533,488.056 131.498,494.9 123.998,494.9C122.129,494.9 120.115,494.499 118.195,493.782L37.103,461.33C21.001,455.365 17.067,450.228 17.067,435.192L17.067,76.715C17.067,61.679 21.001,56.551 37.308,50.509L118.033,18.219C120.115,17.434 122.12,17.033 123.989,17.033C131.49,17.033 136.533,23.868 136.533,34.049L136.533,477.876Z"/>
                </g>
                <g transform="matrix(-1,0,0,1,511.989,0)">
                    <path d="M178.133,51.115L298.666,51.115C312.78,51.115 324.266,62.601 324.266,76.715L324.266,204.715C324.266,209.425 328.08,213.248 332.799,213.248C337.518,213.248 341.332,209.425 341.332,204.715L341.332,76.715C341.332,53.189 322.192,34.048 298.665,34.048L178.133,34.048C173.423,34.048 169.6,37.871 169.6,42.581C169.6,47.291 173.423,51.115 178.133,51.115Z"/>
                </g>
                <g transform="matrix(-1,0,0,1,511.989,0)">
                    <path d="M332.8,298.582C328.081,298.582 324.267,302.405 324.267,307.115L324.267,435.115C324.267,449.229 312.781,460.715 298.667,460.715L179.2,460.715C174.49,460.715 170.667,464.538 170.667,469.248C170.667,473.958 174.49,477.781 179.2,477.781L298.667,477.781C322.193,477.781 341.334,458.641 341.334,435.114L341.334,307.114C341.333,302.405 337.519,298.582 332.8,298.582Z"/>
                </g>
                <g transform="matrix(1,0,0,1,-187.734,0)">
                    <path d="M511.343,252.655C510.908,251.605 510.285,250.667 509.491,249.873L424.166,164.54C420.829,161.204 415.436,161.204 412.1,164.54C408.763,167.877 408.763,173.27 412.1,176.606L482.867,247.381L196.267,247.381C191.557,247.381 187.734,251.204 187.734,255.914C187.734,260.624 191.557,264.447 196.267,264.447L482.868,264.447L412.1,335.215C408.763,338.552 408.763,343.945 412.1,347.281C413.764,348.945 415.949,349.781 418.133,349.781C420.318,349.781 422.502,348.945 424.166,347.281L509.491,261.956C510.285,261.162 510.908,260.224 511.343,259.174C512.205,257.093 512.205,254.738 511.343,252.655Z"/>
                </g>
            </svg>
        )
    }
////////////////////////////////
    // else if(props.name === "about"){
    //     return(
    //         <svg
    //             name={props.name}
    //             className={props.className !== undefined ? props.className : "MenuItemIcon"}
    //             viewBox="0 0 295.82 295.82"> 
    //             <path d="m154.871,69.605c-10.441-3.48-22.621,0-31.322,6.96-8.701,6.96-13.921,17.401-13.921,29.582 0,5.22 3.48,8.701 8.701,8.701 5.22,0 8.701-3.48 8.701-8.701 0-5.22 1.74-12.181 6.96-15.661 5.22-3.48 10.441-5.22 17.401-3.48 8.701,1.74 13.921,8.701 15.661,15.661 1.74,8.701 0,15.661-6.96,20.882-13.921,10.441-20.882,26.102-20.882,41.763 0,5.22 3.48,8.701 8.701,8.701 5.22,0 8.701-3.48 6.96-8.701 0-10.441 6.96-20.882 15.661-27.842 12.181-10.441 17.401-24.362 13.921-38.283-3.481-15.661-15.661-26.102-29.582-29.582z"></path>
    //             <path d="m140.95,210.555c-1.74,1.74-1.74,5.22-1.74,6.96 0,1.74 0,3.48 1.74,5.22 1.74,1.74 5.22,3.48 6.96,3.48 1.74,0 5.22-1.74 5.22-3.48 1.74,0 3.48-3.48 3.48-5.22 0-3.48-1.74-5.22-3.48-6.96-3.48-3.481-8.7-3.481-12.18,0z"></path>
    //             <path d="M147.91,0C66.124,0,0,66.124,0,147.91s66.124,147.91,147.91,147.91s147.91-66.124,147.91-147.91S229.696,0,147.91,0z  M147.91,278.419c-71.345,0-130.509-59.164-130.509-130.509S76.565,17.401,147.91,17.401S278.419,76.565,278.419,147.91 S219.255,278.419,147.91,278.419z"></path>
    //         </svg>
    //     )
    // }
    // else if(props.name === "settings"){
    //     return(
    //         <svg
    //             name={props.name}
    //             className={props.className !== undefined ? props.className : "MenuItemIcon"}
    //             viewBox="0 0 56 56">
    //             <path d="M8,14c3.519,0,6.432-2.614,6.92-6H54c0.553,0,1-0.447,1-1s-0.447-1-1-1H14.92C14.432,2.614,11.519,0,8,0   C4.14,0,1,3.141,1,7S4.14,14,8,14z M8,2c2.757,0,5,2.243,5,5s-2.243,5-5,5S3,9.757,3,7S5.243,2,8,2z"></path>
    //             <path d="M48,42c-3.519,0-6.432,2.614-6.92,6H2c-0.552,0-1,0.447-1,1s0.448,1,1,1h39.08c0.488,3.386,3.401,6,6.92,6   c3.859,0,7-3.141,7-7S51.859,42,48,42z M48,54c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S50.757,54,48,54z"></path>
    //             <path d="M54,27H35.368c-0.396-3.602-3.455-6.414-7.161-6.414c-3.706,0-6.765,2.813-7.161,6.414H2c-0.552,0-1,0.447-1,1s0.448,1,1,1   h19.109c0.577,3.4,3.536,6,7.098,6c3.562,0,6.52-2.6,7.097-6H54c0.553,0,1-0.447,1-1S54.553,27,54,27z M28.207,33   C25.336,33,23,30.664,23,27.793s2.336-5.207,5.207-5.207s5.207,2.336,5.207,5.207S31.078,33,28.207,33z"></path>
    //         </svg>
    //     )
    // }
    else{
        return(
            <svg
                name={props.name}
                className={props.className !== undefined ? props.className : "MenuItemIcon"}
            >
                <path d="M154.871 69.605c-10.441-3.48-22.621 0-31.322 6.96s-13.921 17.401-13.921 29.582c0 5.22 3.48 8.701 8.701 8.701 5.22 0 8.701-3.48 8.701-8.701 0-5.22 1.74-12.181 6.96-15.661 5.22-3.48 10.441-5.22 17.401-3.48 8.701 1.74 13.921 8.701 15.661 15.661 1.74 8.701 0 15.661-6.96 20.882-13.921 10.441-20.882 26.102-20.882 41.763 0 5.22 3.48 8.701 8.701 8.701 5.22 0 8.701-3.48 6.96-8.701 0-10.441 6.96-20.882 15.661-27.842 12.181-10.441 17.401-24.362 13.921-38.283-3.481-15.661-15.661-26.102-29.582-29.582zM140.95 210.555c-1.74 1.74-1.74 5.22-1.74 6.96 0 1.74 0 3.48 1.74 5.22 1.74 1.74 5.22 3.48 6.96 3.48 1.74 0 5.22-1.74 5.22-3.48 1.74 0 3.48-3.48 3.48-5.22 0-3.48-1.74-5.22-3.48-6.96-3.48-3.481-8.7-3.481-12.18 0z"></path>
                <path d="M147.91 0C66.124 0 0 66.124 0 147.91s66.124 147.91 147.91 147.91 147.91-66.124 147.91-147.91S229.696 0 147.91 0zm0 278.419c-71.345 0-130.509-59.164-130.509-130.509S76.565 17.401 147.91 17.401 278.419 76.565 278.419 147.91 219.255 278.419 147.91 278.419z"></path>
            </svg>
        )
    }
}

export default MenuItemIcon