import React from 'react'
import {isMobile} from "react-device-detect"
// import IconUser from "./svg/IconUser"
// import IconMap from "./svg/IconMap"
import Address from './Address'
import Link from "./ScrollingLink"
// import IconArrow from './svg/IconArrow'


function Addresses(props){

    let addressList = props.addressList.map( (address) => <Address key={"o"+address.addressID} address={address} standalone="true" />)

    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Twoje adresy</h1>
        {addressList}
        {addressList.length === 0 ? 
        <div className="lesVisibleTile">
            <span>Tutaj będą pojawiać się Twoje adresy po ich zapisaniu</span>
        </div>
        : null }
        <div className='buttonDiv'>
            <Link to="/addAaddress" className="button">Dodaj adres</Link>
        </div>
        {/* <pre>
            {JSON.stringify(props, null, 2)}
        </pre> */}
    </div>
    )
}

export default Addresses