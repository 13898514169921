import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 256 256"
      className={props.className}
    >
      <path
        fillRule="nonzero"
        d="M20.334 2c.392 0 .761.212.963.552l11.285 19.017c.199.336.208.759.024 1.103l-6.997 13.034a5.09 5.09 0 00.891 6.044l21.752 21.752a5.09 5.09 0 003.623 1.5c.842 0 1.679-.211 2.422-.609l13.034-6.997a1.129 1.129 0 011.102.024L87.45 68.705c.487.289.683.895.457 1.409-1.654 3.763-4.605 10.528-5.789 13.547-.147.374-.34.667-.575.871C78.885 86.833 75.455 88 71.345 88c-11.841 0-28.805-9.608-44.271-25.074-9.902-9.902-17.638-20.716-21.783-30.45C2.19 25.191-.297 15.111 5.47 8.459c.204-.235.497-.429.871-.575C9.36 6.7 16.125 3.748 19.888 2.095A1.1 1.1 0 0120.334 2m0-2c-.419 0-.844.085-1.25.264C15.386 1.889 8.607 4.847 5.611 6.022c-.631.247-1.209.615-1.653 1.127-10.986 12.674 2.4 37.89 21.701 57.191C40.159 78.84 57.994 90 71.345 90c4.421 0 8.353-1.225 11.506-3.958.512-.444.88-1.022 1.127-1.652 1.175-2.996 4.133-9.775 5.758-13.473a3.113 3.113 0 00-1.268-3.933L69.452 55.699a3.118 3.118 0 00-3.069-.066L53.349 62.63a3.12 3.12 0 01-3.684-.543L27.913 40.335a3.122 3.122 0 01-.543-3.684l6.997-13.034a3.122 3.122 0 00-.066-3.07L23.017 1.531A3.12 3.12 0 0020.334 0z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      ></path>
    </svg>
  );
}

export default Icon;
