function sqlToJsDate(sqlDate) {
	// console.warn(sqlDate)
	sqlDate = sqlDate.split(' ')
	sqlDate[0] = sqlDate[0].split('-')
	sqlDate[1] = sqlDate[1].split(':')
	let sYear = sqlDate[0][0]
	let sMonth = sqlDate[0][1]
	let sDay = sqlDate[0][2]
	let sHour = sqlDate[1][0]
	let sMinute = sqlDate[1][1] | 0
	let sSecond = sqlDate[1][2] | 0
	let date = new Date()
	date.setFullYear(sYear)
	date.setMonth(sMonth-1)
	date.setDate(sDay)
	date.setHours(sHour)
	date.setMinutes(sMinute)
	date.setSeconds(sSecond)
	date.setMilliseconds(0)
	return date
}

export default sqlToJsDate