
import React from 'react'
import {isMobile} from "react-device-detect"
import Link from "./ScrollingLink"
import Order from './Order'
import Address from './Address'
import IconWallet from "./svg/IconWallet"
import IconCalendar from "./svg/IconCalendar"
import IconDetails from "./svg/state31"
import OrderTypeIcon from "./OrderTypeIcon"
import StateIcon from "./StateIcon"


import SoftenerText from "./SoftenerText"
import "./css/OrderDetails.css"

function OrderDetails(props){
    if(props.order === undefined){

        return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
            <div className='tileIconed'>
                Nie ma takiego zlecenia lub nie masz do niego uprawnień
            </div>
        </div>
        )
    }


    let pickupAddress = props.order.pickup.address+""
    let addressList = props.addressList
    pickupAddress = addressList.filter(item => item.addressID === pickupAddress)[0];
    
    let deliveryAddress
    if(props.order.delivery !== null){
        deliveryAddress = props.order.delivery.address+""
        deliveryAddress = addressList.filter(item => item.addressID === deliveryAddress)[0];
    }




    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Szczegóły zlecenia</h1>

        <Order
            order={props.order}
        />

        {props.order.orderState === "0" ?
            <div className='buttonDiv'>
                <Link to={"/cancel/"+props.order.orderID} className="button">Usuń zlecenie</Link>
            </div>
        : null }

        <h2>Adres odbioru</h2>
        <div className="tileCenter">
            <IconCalendar className="tileIconedIconTop" />
            <div>
                {props.order.pickup.date} od {props.order.pickup.from} do {props.order.pickup.to}<br/>
                <Address address={pickupAddress} inline={true} />
            </div>
        </div>

        <h2>Dostawa</h2>
        {props.order.delivery !== null ?
            <div className="tileCenter">
                <StateIcon orderState="9" className="tileIconedIconTop"/>
                <div>
                    {props.order.delivery.date} od {props.order.delivery.from} do {props.order.delivery.to}<br/>
                    <Address address={deliveryAddress} inline={true}/>
                </div>

                <div className="buttonDiv">
                    <Link to={"/setDelivery/"+props.order.orderID} className="button">Zmień szczegóły dostawy</Link>
                </div>
            </div>
        : props.order.orderState == 50 ?
            <div className="tileCenter">
                <StateIcon orderState="9" className="tileIconedIconTop"/>
                <div>
                    Twoje zlecenie jest już gotowe. Podaj nam adres i datę dostawy.
                </div>
                <div className="buttonDiv">
                    <Link to={"/setDelivery/"+props.order.orderID} className="button">Szczegóły dostawy</Link>
                </div>
            </div>
        :
            <div className="lesVisibleTile">
                <StateIcon orderState="9" className="tileIconedIconTop"/>
                <span>
                    Datę i miejsce dostawy będziesz mógł/a podać, gdy zlecenie będzie zreazilowane.
                </span>
            </div>
        }


        <h2>Płatności</h2>
        {props.order.details.rozliczenie !== undefined ?
            <div className="tileCenter">

                <IconWallet className="tileIconedIconTop" />
                <table className="OrderDetailsPayementTable"><tbody>
                    {props.order.details.rozliczenie.pranieCodzienne > 0 ?
                        <tr>
                            <td>Pranie codzienne <nobr>({props.order.details.rozliczenie.pranieCodzienneKG} kg)</nobr></td>
                            <td className="tableTdRight">{props.order.details.rozliczenie.pranieCodzienne}</td>
                            <td>zł</td>
                        </tr>
                    : null }
                    {props.order.details.rozliczenie.pranieWielkogab > 0 ?
                        <tr>
                            <td>Pranie wielkogabarytowe <nobr>({props.order.details.rozliczenie.pranieWielkogabKG} kg)</nobr></td>
                            <td className="tableTdRight">{props.order.details.rozliczenie.pranieWielkogab}</td>
                            <td>zł</td>
                        </tr>
                    : null }
                    {props.order.details.rozliczenie.samoPrasowanie > 0 ?
                        <tr>
                            <td>Samo prasowanie <nobr>({props.order.details.rozliczenie.samoPrasowanieKG} kg)</nobr></td>
                            <td className="tableTdRight">{props.order.details.rozliczenie.samoPrasowanie}</td>
                            <td>zł</td>
                        </tr>
                    : null }
                    {props.order.details.rozliczenie.pranieSpec > 0 ?
                        <tr>
                            <td>Pranie specjalistyczne (chemiczne)</td>
                            <td className="tableTdRight">{props.order.details.rozliczenie.pranieSpec}</td>
                            <td>zł</td>
                        </tr>
                    : null}
                    <tr>
                        <td>Transport</td>
                        <td className="tableTdRight">{props.order.details.rozliczenie.transport}</td>
                        <td>zł</td>
                    </tr>
                    {props.order.details.rozliczenie.rabat > 0 ?
                        <tr> 
                            <td>Rabat</td>
                            <td className="tableTdRight">-{props.order.details.rozliczenie.rabat}</td>
                            <td>zł</td>
                        </tr>
                    : null}

                    <tr>
                        <td colSpan={5} className="OrderDetailsPayementSum"></td>
                    </tr>
                    <tr>
                        <td>Razem:</td>
                        <td className="tableTdRight" style={{fontWeight: "bold"}}>
                            {props.order.details.rozliczenie.price}
                        </td>
                        <td>zł</td>
                    </tr>
                </tbody></table>
            </div>
        : props.order.details.price > 0 ?
            <div className='tileCenter'>
                <IconWallet className="tileIconedIconTop" />
                <div>Razem: {props.order.details.price} zł</div>
                <div style={{fontSize: "0.75em"}}>
                    To zlecenie jest sprzed aktualizacji, która wprowadziła szczegółowe rozliczenia, dlatego możemy podać Ci tylko wartość końcową.
                </div>
            </div>
        : props.order.details.laundryType === "rug" ?
            <div className='lesVisibleTile'>
                <IconWallet className="tileIconedIconTop" />
                <div>Jeszcze nie wiemy jakie wymiary ma Twój dywan.</div>
                <div>Opłata za pranie dywanu wynosi od 25 zł/m².</div>
                <div>Przy zamówieniu za mniej niż 150 zł doliczana jest opłata za transport w wysokości 25zł.</div>
            </div>
        : <div className='lesVisibleTile'>
                <IconWallet className="tileIconedIconTop" />
                <div>Jeszcze nie wiemy ile waży Twoje zlecenie.</div>
                {props.order.details.laundryType === "wet" ? 
                    <div>Opłata za pranie i prasowanie wynosi 17 zł/kg.</div>
                : props.order.details.laundryType === "none" ?
                    <div>Opłata za prasowanie wynosi 15 zł/kg.</div>
                : null }
                <div>Przy zamówieniu za mniej niż 150 zł doliczana jest opłata za transport w wysokości 25 zł.</div>
            </div>
        }

        <h2>
            Szczegóły zlecenia
        </h2>
        <div className="tileCenter">
            <OrderTypeIcon orderType={props.order.details.laundryType} className="tileIconedIconTop"/>
            {props.order.details.laundryType !== "rug" ?
                <table className='orderDetailsTable'><tbody>
                    {props.order.details.laundryType === undefined || props.order.details.laundryType === "wet" || props.order.details.laundryType === ""?
                        <>
                            <tr>
                                <td>Typ prania:</td>
                                <td>Pranie codzienne</td>
                            </tr>
                            <tr>
                                <td style={{width: "50%"}}>
                                    Detergent:
                                </td>
                                <td>
                                    {props.order.details.detergent === "standard" ? "Standard" :
                                        props.order.details.detergent === "heavy" ? "Na ciężkie zabrudzenia" :
                                        props.order.details.detergent === "infants" ? "Dla niemowląt i alergików" :
                                        props.order.details.detergent === "none" ? "--Brak--" :
                                        props.order.details.detergent === "nuts" ? "Orzechy piorące" :
                                        null
                                    }
                                </td>
                            </tr><tr>
                                <td>
                                    Płyn do płukania:
                                </td>
                                <td>
                                    <SoftenerText
                                        lang="pl"
                                        softenerId={props.order.details.softener}
                                    />
                                </td>
                            </tr><tr>
                                <td>
                                    Dezynfekujący dodatek do prania:
                                </td>
                                <td>
                                    {props.order.details.sanitizer ?
                                        "Tak"
                                    :
                                        "Nie"
                                    }
                                </td>
                            </tr>
                        </>
                    :null }
                    {props.order.details.laundryType === "none"?
                        <tr>
                            <td>Typ prania:</td>
                            <td>Bez prania</td>
                        </tr>
                    :null }
                    {props.order.details.laundryType === "dry"?
                        <tr>
                            <td>Typ prania:</td>
                            <td>Specjalistyczne (chemiczne)</td>
                        </tr>
                    :null }
                    
                    <tr>
                        <td>
                            Prasowanie:
                        </td>
                        <td>
                            {props.order.details.ironing ?
                                "Tak"
                            :
                                "Nie"
                            }
                        </td>
                    </tr>
                    
                </tbody></table>
            : <div>
                Pranie dywanu
            </div> }
        </div>
        {/* <pre>
            {JSON.stringify(
                props.order, null, 2)}
        </pre> */}
    </div>
    )
}

export default OrderDetails

