import React from 'react'
// import Link from "./ScrollingLink";
import State00 from "./svg/state00"
import State0 from "./svg/state0"
import State10 from "./svg/state10"
import State12 from "./svg/state12"
import State31 from "./svg/state31"
import State50 from "./svg/state50"
import State51 from "./svg/state51"
import State90 from "./svg/state90"
import State99 from "./svg/state99"
import Unknown from "./svg/012-question"
// import rug from "./svg/icon-rug"
// import dry from "./svg/icon-dry-cleaning"


function StateIcon(props){
    let state = parseInt(props.orderState)

    if(state < 0){
        return <State00 className={props.className} />
    }
    else if(state === 0){
        return <State0 className={props.className} />
    }
    else if(state < 10){ // w trasie{
        return <State10 className={props.className} />
    }
    else if(state < 12){ // czekanie{
        return <State12 className={props.className} />
    }
    else if(state < 31){ // pranie{
        return <State31 className={props.className} />
    }
    else if(state < 50){ // prasowanie{
        return <State50 className={props.className} />
    }
    else if(state < 51){ // czeka na nadanie daty oddania{
        return <State51 className={props.className} />
    }
    else if(state < 90){ // w trasie{
        return <State10 className={props.className} />
    }
    else if(state === 90){ // zakończone{
        return <State90 className={props.className} />
    }
    else if(state < 100){
        return <State99 className={props.className} />
    }
    else {
        return <Unknown className={props.className} />
    }
}

export default StateIcon