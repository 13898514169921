import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 60 60"
      version="1.1"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
    >
      <path d="M19 0H0v60h16V19h3v-3h41V0H19zm-5 23h-3a1 1 0 100 2h3v4H8a1 1 0 100 2h6v4h-3a1 1 0 100 2h3v4H8a1 1 0 100 2h6v4h-3a1 1 0 100 2h3v4H8a1 1 0 100 2h6v3H2V19h12v4zm3-6H2V2h15v15zm41-3h-3V8a1 1 0 10-2 0v6h-4v-3a1 1 0 10-2 0v3h-4V8a1 1 0 10-2 0v6h-4v-3a1 1 0 10-2 0v3h-4V8a1 1 0 10-2 0v6h-4v-3a1 1 0 10-2 0v3h-4V2h39v12z"></path>
    </svg>
  );
}

export default Icon;
