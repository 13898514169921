import React from "react";

function Icon(props) {
  return (
    <svg
        className={props.className} 
        xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1000 1000">
      <path d="M754.5 500c5.5 13.8 2.6 30.1-8.6 41.3L308.4 978.8c-15 15-39.3 15-54.3-.1-15.1-15.1-15-39.3-.1-54.3L678.4 500 254 75.7c-15-15-15-39.3.1-54.3 15.1-15.1 39.4-15 54.3-.1L746 458.8c11.1 11.1 13.9 27.4 8.5 41.2z"></path>
    </svg>
  );
}

export default Icon;
