import React from 'react'
// import Link from "./ScrollingLink"
//import StateDebuger from "./StateDebuger"
import './css/Time4Humans.css'
import sqlToJsDate from './func/sqlToJsDate'
    
function Time4Humans(props){
    if(props.lang === undefined){
        return(
            <span>{props.time}</span>
        )
    }
    let text = {
        pl: {
            txt_weekDay: [
                'niedziela',
                'poniedziałek',
                'wtorek',
                'środa',
                'czwartek',
                'piątek',
                'sobota'
            ],
            // txt_month: [
            //     'stycznia',
            //     'lutego',
            //     'marca',
            //     'kwietnia',
            //     'maja',
            //     'czerwca',
            //     'lipca',
            //     'sierpnia',
            //     'września',
            //     'października',
            //     'listopada',
            //     'grudnia'
            // ],
            txt_month: [
                'st.',
                'lut.',
                'mrz.',
                'kw.',
                'maj.',
                'cz.',
                'lip.',
                'sier.',
                'wrz.',
                'paź.',
                'lis.',
                'gr.'
            ],
            txt_today: 'dziś',
            txt_yesturday: 'wczoraj',
            txt_justNow: 'przed chwilą',
            txt_1mAgo: '1 minutę temu',
            txt_5minAgo: ' minuty temu',
            txt_5MinAgo: ' minut temu',
            txt_1hAgo: '1 godzinę temu',
            txt_5hAgo: ' godziny temu',
            txt_5HAgo: ' godzin temu',
        },
        en: {
            txt_weekDay: [
                'Mon.',
                'Tue.',
                'Wed.',
                'Thu.',
                'Fri.',
                'Sat.',
                'Sun.'
            ],
            txt_month: [
                'January' ,
                'February' ,
                'March' ,
                'April' ,
                'May' ,
                'June' ,
                'July' ,
                'August' ,
                'September' ,
                'October' ,
                'November' ,
                'December' ,
            ],
            txt_today: 'today',
            txt_yesturday: 'yesterday',
            txt_justNow: 'just now',
            txt_1mAgo: '1 minute ago',
            txt_5minAgo: ' minutes ago',
            txt_5MinAgo: ' minutes ago',
            txt_1hAgo: '1 hour ago',
            txt_5hAgo: ' hours ago',
            txt_5HAgo: ' hours ago',
        }
    }
    let time = props.time
    if(props.inputFormat === "sql"){
        time = sqlToJsDate(props.time)
    }
    else if(props.inputFormat === "js"){
        time = new Date(props.time)
    }
    else{
        return(
            <span>{props.time}</span>
        )
    }
    let today = new Date(),
        fuzzy,
        min = null,

        isoTime = new Date(time.getTime() - (time.getTimezoneOffset() *60000)).toISOString().slice(0, -8).replace('T',' ')
    if(today < time){

        if(time.getFullYear() === today.getFullYear() && time.getMonth() === today.getMonth() && time.getDate() === today.getDate()){
            // dziś
            fuzzy = "dziś "+time.getHours()
            min = time.getMinutes()
        } else if(time.getFullYear() === today.getFullYear() && time.getMonth() === today.getMonth() && time.getDate() === today.getDate()+1){
            // jutro
            fuzzy = "jutro "+time.getHours()
            min = time.getMinutes()
        } else if(time.getFullYear() === today.getFullYear() && time.getMonth() === today.getMonth() && time.getDate() === today.getDate()+2){
            // pojutrze
            fuzzy = "pojutrze "+time.getHours()
            min = time.getMinutes()
        } else if(time.getFullYear() === today.getFullYear() && time.getMonth() === today.getMonth()){
            // w tym miesiącu
            fuzzy = text[props.lang]['txt_weekDay'][time.getDay()]+' '+time.getDate()+' '+text[props.lang]['txt_month'][time.getMonth()]
        } else if(time.getFullYear() === today.getFullYear()){
            // w tym roku
            fuzzy = time.getDate()+' '+text[props.lang]['txt_month'][time.getMonth()]
        } else {
            fuzzy = time.getDate()+' '+text[props.lang]['txt_month'][time.getMonth()]+" "+time.getFullYear()
        }
    } else {
        try{
            if(time.getFullYear() < today.getFullYear()){
                fuzzy = time.getDate()+' '+text[props.lang]['txt_month'][time.getMonth()]+' '+time.getFullYear()
                // min = time.getMinutes()
            } else if(time.getMonth() < today.getMonth()){
                fuzzy = time.getDate()+' '+text[props.lang]['txt_month'][time.getMonth()]
                // min = time.getMinutes()
            } else if(time.getDate() < today.getDate() -6){
                fuzzy = time.getDate()+' '+text[props.lang]['txt_month'][time.getMonth()]
                // min = time.getMinutes()
            } else if(time.getDate() < today.getDate() -1){
                fuzzy = text[props.lang]['txt_weekDay'][time.getDay()]+' '+time.getHours()
                min = time.getMinutes()
            } else if(time.getDate() < today.getDate()){
                fuzzy = text[props.lang]['txt_yesturday']+' '+time.getHours()
                min = time.getMinutes()
            } else {
                var delta = Math.round((+today - time) / 1000),
                    minute = 60,
                    hour = minute * 60

                if (delta < minute) {
                    fuzzy = text[props.lang]['txt_justNow']
                } else if (Math.floor(delta / minute) === 1) {
                    fuzzy = text[props.lang]['txt_1mAgo']
                } else if (Math.floor(delta / minute) < 5) {
                    fuzzy = ''+Math.floor(delta / minute) + text[props.lang]['txt_5minAgo'];
                } else if (Math.floor(delta / minute) < 60) {
                    fuzzy = ''+Math.floor(delta / minute) + text[props.lang]['txt_5MinAgo'];
                } else if (Math.floor(delta / hour) === 1) {
                    fuzzy = text[props.lang]['txt_1hAgo']
                } else if (Math.floor(delta / hour) < 5) {
                    fuzzy = ''+Math.floor(delta / hour) + text[props.lang]['txt_5hAgo']
                } else if (Math.floor(delta / hour) < 9) {
                    fuzzy = ''+Math.floor(delta / hour) + text[props.lang]['txt_5HAgo']
                } else {
                    fuzzy = text[props.lang]['txt_today']+' '+time.getHours()
                    min = time.getMinutes()
                }
            }
        } catch(e) {
            fuzzy = "[Translate Time4Humans]"+isoTime
        }
    }

    // let isoTime = new Date(time.getTime() - (time.getTimezoneOffset() *60000)).toISOString().slice(0, -5).replace('T',' ')

    return(
        <span className="time4Humans">
            <span className="time4HumansFuzzy">
                {fuzzy}
                {min !== null ?
                    <span className="time4HumansMinutes">
                        {min < 10 ? "0"+min : min}
                    </span>
                : null}
            </span>
            <span className="time4HumansOriginal">
                {isoTime}
            </span>
        </span>
    )
}

export default Time4Humans