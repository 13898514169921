import React from 'react'
import {isMobile} from "react-device-detect"
import IconUser from "./svg/IconUser"
import IconMap from "./svg/IconMap"
// import Address from './Address'
import Link from "./ScrollingLink"
import IconArrow from './svg/IconArrow'
import IconBug from './svg/IconBug'
import IconHistory from './svg/IconHistory'
// import Time4Humans from './Time4Humans'
import MenuItemIcon from './MenuItemIcon'

import repairUtf8 from "./func/repairUtf8"


function MyAccount(props){

    // let bilingAddress
    // let addressList = props.addressList.map( (address) => <Address address={address} standalone="true" />)

    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Twoje konto</h1>
        <div className='tileCenter'>
            <IconUser className="tileIconedIconTop" />
            <h3>Numer klienta: {props.user.userID}</h3>
            {props.user.userDetails.userName !== undefined ? 
                <span>Imię: {repairUtf8(props.user.userDetails.userName)}<br/></span>
            : null}
            Numer telefonu: {props.user.tel.substring(0, 3)} {props.user.tel.substring(3, 6)} {props.user.tel.substring(6, 9)}<br/>
            {/* Data rejestracji: {props.user.userDetails.registerDate}<br/> */}
            {/* Data rejestracji: <Time4Humans
                    lang={"pl"}
                    time={props.user.userDetails.registerDate}
                    inputFormat="sql"
                /> */}
        </div>
        <Link to="/history" className='tileIconed'>
            <IconHistory className="tileIconedIcon" />
            Historia
            <IconArrow className="tileArrow" />
        </Link>
        <Link to="/addresses" className='tileIconed'>
            <IconMap className="tileIconedIcon" />
            Twoje adresy
            <IconArrow className="tileArrow" />
        </Link>
        <Link to="/report" className='tileIconed'>
            <IconBug className="tileIconedIcon" />
            Zgłoś błąd
            <IconArrow className="tileArrow" />
        </Link>
        {/* <div className='tileIconed'>
            <IconMap className="tileIconedIcon" />
            Zgody
            <IconArrow className="tileArrow" />
        </div> */}
        <Link to="/logout" className='tileIconed'>
            <MenuItemIcon name="logout" className="tileIconedIcon"/>
            Wyloguj się
            <IconArrow className="tileArrow" />
        </Link>
        {/* <div className='buttonDiv'>
            <Link to="/deleteAccount" className='buttonWarn'>
                Usuń konto
            </Link >
        </div> */}
        {/* <pre>
            {JSON.stringify(props, null, 2)}
        </pre> */}
    </div>
    )
}

export default MyAccount