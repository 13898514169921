
import React from 'react'
import {isMobile} from "react-device-detect"
import Link from "./ScrollingLink"
// import OrderTypeIcon from "./OrderTypeIcon"
import Order from './Order'
import IconArrow from "./svg/IconArrow"
import "./css/Home.css"
// import Slider from './Slider'
import IconPlus from "./svg/IconPlus"

function Home(props){

    let orderItems = props.orders
    if(orderItems !== undefined && orderItems !== null){
            orderItems = orderItems.map((order) => <Order key={"o"+order.orderID} order={order}/>)
    } else {
        orderItems = <div className="lesVisibleTile">
            <span>W tej chwili nie masz żadnych aktywnych zleceń. Pojawią się one automatycznie po ich złożeniu.</span>
        </div>
    }

    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        {/* {!props.userLogedIn ?
            <>
                <h1>Jak działamy</h1>
                <Slider />
            </>
        : null } */}
        <h1>Aktywne zlecenia</h1>
        {!props.userLogedIn ?
            <div className='lesVisibleTile'>
                <span>Zaloguj się, aby zobaczyć aktywne zlecenia.</span>
                <div className='buttonDiv'>
                    <Link to='/login' className="button">
                        Zaloguj się
                    </Link>
                </div>
            </div>
        : props.currentOrderNumber === 0 ? 
            <div className="lesVisibleTile">
                <span>W tej chwili nie masz żadnych aktywnych zleceń. Pojawią się one automatycznie po ich złożeniu.</span>
            </div>
        : orderItems }

        <div>
            <Link to="/newOrder" className="tileIconed">
                <IconPlus className="tileIconedIcon"/>
                <h3>Złóż nowe zlecenie</h3>
                <div>Już od 17 zł/kg lub od 15 zł/szt.</div>
                <IconArrow className="tileArrow" />
            </Link>
        </div>


        {/* <h1>Nowe zlecenie</h1>
        <div id="NewLaundryButtonsWraper">
            <Link to="/newLaundry" className="tileIconed">
                <OrderTypeIcon orderType="wet" className="tileIconedIcon"/>
                <h3>Pranie i prasowanie</h3>
                Już od 17 zł/kg
                <IconArrow className="tileArrow" />
            </Link>
            <Link to="/newIroning" className="tileIconed">
                <OrderTypeIcon orderType="none" className="tileIconedIcon"/>
                <h3>Prasowanie</h3>
                Już od 15 zł/kg
                <IconArrow className="tileArrow" />
            </Link>
            <Link to="/newRugCleaning" className="tileIconed">
                <OrderTypeIcon orderType="rug" className="tileIconedIcon"/>
                <h3>Pranie dywanu</h3>
                Już od 25 zł/m²
                <IconArrow className="tileArrow" />
            </Link>
            <Link to="/newDryCleaning" className="tileIconed">
                <OrderTypeIcon orderType="dry" className="tileIconedIcon"/>
                <h3>Pranie specalistyczne / chemiczne</h3>
                Już od 15 zł/szt.
                <IconArrow className="tileArrow" />
            </Link>
        </div> */}
    </div>
    )
}

export default Home

