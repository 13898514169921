import React, { Component } from 'react'
// import { useHistory } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
import {Navigate} from "react-router-dom";

import {isMobile} from "react-device-detect"

import Link from "./ScrollingLink"
import IconMap from "./svg/IconMap"
import "./css/AddAddress.css"

class AddAddress extends Component{
    constructor(){
        super()
        this.state = {
            debugComponent: false,
            
            street: "",
            streetNr: "",
            apartmentNr: "",
            region: "el",
            zipCode: "",
            city: "",
            redir: ""
        }
        this.txt = {
            pl: {
                txt_add_address: "Zapisz adres",
                txt_cancel: "Anuluj",
                txt_street: "Ulica",
                txt_street_nr: "Nr",
                txt_apartment_nr: "Nr m.",
                txt_zip_code: "Kod pocztowy",
                txt_city: "Miasto",

                txt_loginFirst: "Najpierw zaloguj się",
                txt_other: "--inne--"

            },
            en: {
                txt_add_address: "Save address",
                txt_cancel: "Cancel",
                txt_street: "Street",
                txt_street_nr: "No.",
                txt_apartment_nr: "Apt. No.",
                txt_zip_code: "Zip code",
                txt_city: "City",

                txt_loginFirst: "Please log in first",
                txt_other: "--other--"
            }
        }
        this.chandleChange = this.chandleChange.bind(this)
        this.sendData = this.sendData.bind(this)
        this.text = this.text.bind(this)
    }
    componentDidMount(){
        let redir = this.props.redir
        if(redir === undefined){
            redir = "addresses"
        } else if(redir.indexOf("-") > -1){
            redir = redir.split("-").join("/")
        }
        this.setState({
            redir: "/"+redir
        })
    }
    sendData(){
        if(this.state.street === ''
        || this.state.streetNr === ''
        || this.state.zipCode === ''
        || (this.state.region === "xxx" && this.state.city === '')){
            this.setState({
                cantSend: true
            })
            let tim = setTimeout( () => {
                this.setState({
                    cantSend: false
                })
                tim = clearTimeout(tim)
            },300)
            return
        }
        this.props.loaderAnimationControl("start")


        let url = "https://api2.kurkadomowa.pl/addAddress.php"
        let body = {
            sessionID: localStorage.getItem('sessionID'),
            street: this.state.street,
            streetNr: this.state.streetNr,
            apartmentNr: this.state.apartmentNr,
            region: this.state.region,
            zipCode: this.state.zipCode,
            city: this.state.city
        }

        if(this.props.userID !== undefined){
            body.clientID = parseInt(this.props.userID)
        }

        console.warn('sendData() -> body',body)

        body = JSON.stringify(body)

        fetch(url, {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .catch(() => console.error('AddAddress.sendData: error parsing response:'))
        .then(res => {
            console.group('AddAddress.res')
            console.log(res)
            this.setState({sendData: res})
            if(!res.userIsLogedIn){
                this.setState({
                    loginFirst: true
                })
            }
            if(res.updateConfirmed){
                //// zapisano
                window.scrollTo({top: 0});
                this.props.appUpdate()
            } else {
                console.warn('updateOrderState', res.reason)
            }
            console.groupEnd()
        })
    }
    chandleChange(e){
        this.setState({[e.target.name]: e.target.value,})
        // this.props.chandleChildUpdate({[e.target.name]: e.target.value})
    }
    text(txt_name, txt_name2){
        if(this.txt["pl"] === undefined || this.txt["pl"][txt_name] === undefined){
            if(txt_name2 !== undefined){
                return this.constructor.name+'->'+txt_name+'->'+txt_name2
            }
            return this.constructor.name+'->'+txt_name
        }
        if(typeof this.txt["pl"][txt_name] === 'object'){
            return  this.txt["pl"][txt_name][txt_name2]
        }
        return this.txt["pl"][txt_name]
    }
    render(){
        if(this.state.sendData){
            if(this.state.sendData.updateConfirmed){
                return <Navigate to={this.state.redir} replace />
            }
        }

        return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
            <h1>Dodaj nowy adres</h1>
            {/* <pre>
                {JSON.stringify(this.props, null, 2)}
            </pre>
            <pre>
                {JSON.stringify(this.state, null, 2)}
            </pre> */}
            <div className="tileCenter">
                <IconMap className="tileIconedIconTop" />
                <table className="table"><tbody>
                    <tr>
                        <td style={{width: "50%"}}>
                            <input 
                                type="text" 
                                name="street"
                                className={this.state.cantSend && this.state.street === '' ? "inputWarn" : null}
                                placeholder={this.text('txt_street')}
                                onChange={this.chandleChange}
                                value={this.state.street}
                            />
                        </td>
                        <td style={{width: "25%"}}>
                            <input 
                                type="text" 
                                name="streetNr"
                                className={this.state.cantSend && this.state.streetNr === '' ? "inputWarn" : null}
                                placeholder={this.text('txt_street_nr')}
                                onChange={this.chandleChange}
                                value={this.state.streetNr}
                            />
                        </td>
                        <td style={{width: "25%"}}>
                            <input 
                                type="text" 
                                name="apartmentNr"
                                placeholder={this.text('txt_apartment_nr')}
                                onChange={this.chandleChange}
                                value={this.state.apartmentNr}
                            />
                        </td>
                    </tr><tr>
                        <td>
                            <select
                                name="region"
                                onChange={this.chandleChange}
                                value={this.state.region}
                            >
                                <option value="el">Łódź</option>
                                <option value="ezg">Zgierz</option>
                                <option value="epa">Pabianice</option>
                                <option value="xxx">{this.text('txt_other')}</option>
                            </select>
                        </td>
                        <td colSpan="3" className="right40">
                            <input 
                                type="text" 
                                name="zipCode"
                                className={this.state.cantSend && this.state.zipCode === '' ? "inputWarn" : null}
                                placeholder={this.text('txt_zip_code')}
                                onChange={this.chandleChange}
                                value={this.state.zipCode}
                            />
                        </td>
                    </tr>
                    {this.state.region === "xxx" ?
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    name="city"
                                    className={this.state.cantSend && this.state.city === '' ? "inputWarn" : null}
                                    placeholder={this.text('txt_city')}
                                    onChange={this.chandleChange}
                                    value={this.state.city}
                                />
                            </td>
                        </tr>
                    : null }
                </tbody></table>
                {!this.props.userIsLogedIn ?
                    <div className="buttonDiv">
                        {this.state.redir ? <Link className="button" to={this.state.redir}>Anuluj</Link> :null}
                        <button
                            className="button"
                            onClick={this.sendData}
                            >
                            {this.text('txt_add_address')}
                        </button>
                    </div>
                : null}
            </div>
        </div>)
    }
}

export default AddAddress