
import "./css/Menu.css"
import MenuItemIcon from "./MenuItemIcon"

// import { NavLink } from "react-router-dom";
import NavLink from "./ScrollingLink"
import {isMobile} from 'react-device-detect';

function Menu(props){
    return(
        <div id="menuWraper" className={isMobile ? "mobile" :"full"}>
            <div id="menu" className={props.menuStandalone ? "standalone" : null}>
                <NavLink to={props.userLogedIn ? "/about" : "/"} className="menuItem">
                    <div className="menuAccent"></div>
                    <div className="menuAccentL"><div></div></div>
                    <div className="menuAccentR"><div></div></div>
                    <MenuItemIcon name="faq" />
                    <span>
                        O nas
                    </span>
                </NavLink>
                <NavLink to={props.userLogedIn ? "/" : "/home"} className="menuItem">
                    <div className="menuAccent"></div>
                    <div className="menuAccentL"><div></div></div>
                    <div className="menuAccentR"><div></div></div>
                    <MenuItemIcon name="home" />
                    <span>
                        Początek
                    </span>
                </NavLink>
                {props.userLogedIn ?
                    <NavLink to="/newOrder" className="menuItem">
                        <div className="menuAccent"></div>
                        <div className="menuAccentL"><div></div></div>
                        <div className="menuAccentR"><div></div></div>
                        <MenuItemIcon name="newOrder" />
                        <span>
                            Nowe zlecenie
                        </span>
                    </NavLink>
                : null }
                <NavLink to="/myAccount" className="menuItem">
                    <div className="menuAccent"></div>
                    <div className="menuAccentL"><div></div></div>
                    <div className="menuAccentR"><div></div></div>
                    <MenuItemIcon name="myAccount" />
                    <span>
                        Moje konto
                    </span>
                </NavLink>
                {isMobile ? null : 
                    <NavLink to="/history" className="menuItem">
                        <div className="menuAccent"></div>
                        <div className="menuAccentL"><div></div></div>
                        <div className="menuAccentR"><div></div></div>
                        <MenuItemIcon name="history" />
                        <span>
                            Historia
                        </span>
                    </NavLink>
                }
                <NavLink to="/report" className="menuItem">
                    <div className="menuAccent"></div>
                    <div className="menuAccentL"><div></div></div>
                    <div className="menuAccentR"><div></div></div>
                    <MenuItemIcon name="report" />
                    <span>
                        Zgłoś błąd
                    </span>
                </NavLink>
                {!props.userLogedIn ?
                    <NavLink to="/login" className="menuItem">
                        <div className="menuAccent"></div>
                        <div className="menuAccentL"><div></div></div>
                        <div className="menuAccentR"><div></div></div>
                        <MenuItemIcon name="login" />
                        <span>
                            Zaloguj
                        </span>
                    </NavLink>
                    : !isMobile ?
                    <NavLink to="/logout" className="menuItem">
                        <div className="menuAccent"></div>
                        <div className="menuAccentL"><div></div></div>
                        <div className="menuAccentR"><div></div></div>
                        <MenuItemIcon name="logout" />
                        <span>
                            Wyloguj
                        </span>
                    </NavLink>
                : null }
            </div>
        </div>
    )
}

export default Menu