import React, {Component} from 'react'
// import {isMobile} from "react-device-detect"
// import IconAbout from "./svg/IconAbout"
import IconPhone from "./svg/IconPhone"
import IconBus from "./svg/state10"
import IconHanger from "./svg/IconHanger"
import "./css/slider.css"
    
class Slider extends Component{
    constructor(){
        super()
        this.state = {
            slide: 1,
            justUsed: false,
        }
        this.setSlide = this.setSlide.bind(this)
    }
    componentDidMount(){
        let sliderTim = setInterval( () => {
            if(!this.state.justUsed){
                if(this.state.slide === 3){
                    this.setState({
                        slide: 1
                    })
                }else{
                    this.setState({
                        slide: this.state.slide+1
                    })
                }
            } else {
                this.setState({
                    justUsed: false
                })
            }
        },7500)
    }
    setSlide(e){
        this.setState({
            slide: parseInt(e.target.name),
            justUsed: true,
        })
    }
    render(){
        return(<div className='slider'>
            <div className={"sliderInerDiv s" + this.state.slide}>
                <div className='slide tileCenter'>
                    <IconPhone className="tileIconedIconTop" />
                    <div>
                        <div className='bold'>
                            Wybierz interesujące Cię usługi, datę odbioru zlecenia oraz lokalizację
                        </div>
                        <div>
                            Nasz kurier na bazie podanych przez Ciebie informacji, pojawi się pod wskazanym adresem, po odbiór rzeczy. 
                        </div>
                    </div>
                </div>
                <div className='slide tileCenter'>
                    <IconBus className="tileIconedIconTop" />
                    <div>
                        <div className='bold'>
                            Twoje zlecenie zostaje przekazane do Kurki Domowej
                        </div>
                        <div>
                            Nasz zespół przechodzi do etapu realizacji.
                        </div>
                    </div>
                </div>
                <div className='slide tileCenter'>
                    <IconHanger className="tileIconedIconTop" />
                    <div>
                        <div className='bold'>
                            Zlecenie zostało zrealizowane
                        </div>
                        <div>
                            Otrzymasz wiadomość SMS <nobr>z informacją</nobr> <nobr>o wykonanej</nobr> usłudze <nobr>i linkiem</nobr> do aplikacji, by wyznaczyć dogodną dla Ciebie datę dostawy czystego prania. 
                        </div>
                    </div>
                </div>
            </div>
            <div className='sliderButtons'>
                <button type='button' name="1" className={this.state.slide === 1 ? "active" : null} onClick={this.setSlide}></button>
                <button type='button' name="2" className={this.state.slide === 2 ? "active" : null} onClick={this.setSlide}></button>
                <button type='button' name="3" className={this.state.slide === 3 ? "active" : null} onClick={this.setSlide}></button>
            </div>
            {/* <pre>
                {JSON.stringify(this.state, null, 2)}
            </pre> */}
        </div>)
    }
}

export default Slider