import React from 'react'
import { isMobile } from "react-device-detect"
import Link from "./ScrollingLink"
import OrderTypeIcon from "./OrderTypeIcon"
import IconArrow from "./svg/IconArrow"
import "./css/Home.css"
import trackEvent from './func/trackEvent'

function NewOrder() {
  

  return (
    <div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
      <h1>Złóż zlecenie</h1>
      <div id="NewLaundryButtonsWraper">
        <Link to="/newLaundry" className="tileIconed" onClick={() => trackEvent('NewLaundry')}>
          <OrderTypeIcon orderType="wet" className="tileIconedIcon" />
          <h3>Pranie i prasowanie</h3>
          Już od 19 zł/kg
          <IconArrow className="tileArrow" />
        </Link>
        <Link to="/newIroning" className="tileIconed" onClick={() => trackEvent('NewIroning')}>
          <OrderTypeIcon orderType="none" className="tileIconedIcon"/>
          <h3>Prasowanie lub maglowanie</h3>
          Już od 17 zł/kg
          <IconArrow className="tileArrow" />
        </Link>
        <Link to="/newRugCleaning" className="tileIconed" onClick={() => trackEvent('NewRugCleaning')}>
          <OrderTypeIcon orderType="rug" className="tileIconedIcon"/>
          <h3>Pranie dywanu</h3>
          Już od 25 zł/m²
          <IconArrow className="tileArrow" />
        </Link>
        <Link to="/newDryCleaning" className="tileIconed" onClick={() => trackEvent('NewDryCleaning')}>
          <OrderTypeIcon orderType="dry" className="tileIconedIcon"/>
          <h3>Pranie specalistyczne (chemiczne)</h3>
          Już od 15 zł/szt.
          <IconArrow className="tileArrow" />
        </Link>
      </div>
    </div>
  )
}

export default NewOrder
