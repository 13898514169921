import React from 'react'
// import Link from "./ScrollingLink"
// import StateDebuger from "./StateDebuger"
// import './css/Time4Humans.css'
// import sqlToJsDate from './func/sqlToJsDate'
import {isMobile} from "react-device-detect"
import IconBug from "./svg/IconBug"
    
function BugReport(props){

	return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Zgłoś błąd</h1>
	 		<div className="tileCenter">
 			{/* <img alt="" src={bugIcon} className="tileIconedIcon" /> */}
			<IconBug className="tileIconedIconTop" />
			<div>
				Jeśli znalazłeś/aś błąd w aplikacji, coś nie działa lub po prostu chcesz podzielić się z nami tym, co możemy poprawić, poinformuj nas o tym mailowo.
			</div>
			<br/>
			<div>
				Prześlij zgłoszenie na adres: biuro@kurkadomowa.pl. Jeśli możesz, załącz do maila zrzuty ekranu lub inne pliki, które pomogą nam w udoskonaleniu aplikacji. Każda wiadomość jest weryfikowana przez nasz support.
			</div>
			<div className="buttonDiv">
				<a href="mailto:biuro@kurkadomowa.pl" className="button">
					Wyślij e-mail
				</a>
			</div>
		</div>
    </div>
    )
}

export default BugReport