import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 42 42"
      version="1.1"
      viewBox="0 0 42 42"
      xmlSpace="preserve"
    >
      <path d="M42 20L22 20 22 0 20 0 20 20 0 20 0 22 20 22 20 42 22 42 22 22 42 22z"></path>
    </svg>
  );
}

export default Icon;
