import React, { Component } from "react"
// import StateDebuger from "./StateDebuger"

class TimeOptions extends Component{

	constructor(){
		super()
		this.state = {
			// debugComponent: false,
		}
		this.onChange = this.onChange.bind(this)
	}
	onChange(e){
		let name = e.target.name,
			value = e.target.value

		if(name === "selectedTimeFrom"){
			let h = value
			h = h.split(':')
			let m = parseInt(h[1])
			h = parseInt(h[0])

			let hTo = this.props.timeTo+""
			hTo = hTo.split(':')
			let mTo = parseInt(hTo[1])
			hTo = parseInt(hTo[0])

			if(
					h+1 > hTo || 
					(h+1 === hTo && m > mTo)
				){
				let timeTo = h+2
				if(m<10){ 
					timeTo += ":0"+m
				} else {
					timeTo += ":"+m
				}
				this.props.updateParent({
					selectedTimeFrom: value,
					selectedTimeTo: timeTo
				})
				return
			}
		}
		this.props.updateParent({
			[name]: value
		})
	}
	componentDidMount(){
		this.checkRange()
	}
	componentDidUpdate(){
		this.checkRange()
	}
	checkRange(){
		// nad tym by można popracować

		if(
			parseInt(this.props.time) < this.props.openFrom ||
			parseInt(this.props.time) > this.props.openTo-1 ||
			parseInt(this.props.timeTo) < this.props.openFrom+1 ||
			parseInt(this.props.timeTo) > this.props.openTo
		){
			this.props.updateParent({
				selectedTimeFrom: this.props.openFrom+":00",
				selectedTimeTo: this.props.openTo+":00"
			})
		}
	}
	render(){
		let mInterval = 15
		let minApointmentWindowH = 1
		let optionsFrom = []
		let optionsTo = []
		let h = parseInt(this.props.openFrom)
		let m = 0
		while(h <= this.props.openTo-2){
			optionsFrom.push(
				<option key={"from"+h+":"+m} value={h+":"+(m < 10 ? "0"+m : m)}>od {h}:{m < 10 ? "0"+m : m}</option>
			)
			m += mInterval
			if(m >= 60 || h === this.props.openTo-2){
				m -= 60
				h++
			}
		}
		h = this.props.time+""
		h = h.split(':')
		m = parseInt(h[1])
		h = parseInt(h[0])+ minApointmentWindowH
		while(h <= this.props.openTo){
			optionsTo.push(
				<option key={"to"+h+":"+m} value={h+":"+(m < 10 ? "0"+m : m)}>do {h}:{m < 10 ? "0"+m : m}</option>
			)
			m += mInterval
			if(m >= 60 || h === this.props.openTo){
				m -= 60
				h++
			}
		}
		return(<>
			<select
				onChange={this.onChange}
				name="selectedTimeFrom"
				value={this.props.time}
				>
				{optionsFrom}
			</select>
			<select
				onChange={this.onChange}
				name="selectedTimeTo"
				value={this.props.timeTo}
				>
				{optionsTo}
			</select>
		</>)
	}
}

export default TimeOptions