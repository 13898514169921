import React from 'react'
// import Link from "./ScrollingLink"

function StateText(props){
    let txt = {
        pl: {
            txt_state: "Stan",
            "-1": "Oczekuje na ręczną weryfikację.",
            0 : "Zlecenie oczekuje, aż kurier odbierze je od Ciebie.", //"zlecenie gotowe do odebrania (u klienta)",
            9 : "W trasie do Kurki Domowej", //"u kuriera -> Kurki",
        
            10 : "Pranie oczekuje na sortowanie.", //"paczka przyjęta w Kurce",
            11 : "Sortowanie", //"sortowanie",
            12 : "Oczekuje w kolejce na pranie.", //"ocz. na pranie",
            20 : "Pranie", //"pranie",
            21 : "Oczekuje na suszenie.", //"ocz. na suszenie",
            30 : "Suszenie", //"suszenie",
            31 : "Oczekuje na prasowanie", //"ocz. na prasowanie",
            40 : "Prasowawnie", //"prasowanie",
            50 : "Czyste pranie czeka na Ciebie - wyznacz datę oddania.", //"gotowa -> czeka na wyznaczenie daty",
            51 : "Zlecenie zostało zrealizowane. Czeka na odbiór przez kuriera.", //"czeka na zabranie przez kuriera",
            80 : "W trasie do Ciebie", //"paczka u kuriera -> klienta",
        
            90 : "Dostarczone.", //"dostarczone",
            98 : "Zlecenie zostało anulowane.", //"anulowane przez kurke",
            99 : "Zlecenie zostało anulowane.", //"anulowane przez usera"
        },
        en : {
            txt_state: "State",
            "-1": "Oczekuje na ręczną weryfikacje",
            0 : "Ready for pickup", //"zlecenie gotowe do odebrania (u klienta)",
            9 : "On the way to Kurka Domowa", //"u kuriera -> kurki",
        
            10 : "Waiting for sorting", //"paczka przyjęta w kurce",
            11 : "Sorting", //"sortowanie",
            12 : "Waiting for washing", //"ocz. na pranie",
            20 : "Washing", //"pranie",
            21 : "Waiting for Drying", //"ocz. na suszenie",
            30 : "Drying", //"suszenie",
            31 : "Waiting for ironing", //"ocz. na prasowanie",
            40 : "Ironing", //"prasowanie",
            50 : "Waiting for You to set the delivery date", //"gotowa -> czeka na wyznaczenie daty",
            51 : "Waiting for the courier", //"czeka na zabranie przez kuriera",
            80 : "On the way to You", //"paczka u kuriera -> klienta",
        
            90 : "Delivered", //"dostarczone",
            98 : "Canceled by KurkaDomowa", //"anulowane przez kurke",
            99 : "Canceled by client", //"anulowane przez usera"
        },
        fr: {
            txt_state: "Stan",
            0 : "Prêt à récuperér", //"zlecenie gotowe do odebrania (u klienta)",
            9 : "Pris en charge par KurkaDomowa", //"u kuriera -> kurki",

            10 : "En attende pour le tri", //"paczka przyjęta w kurce",
            11 : "En tri", //"sortowanie",
            12 : "En attente pour le lavage", //"ocz. na pranie",
            20 : "Lavage en cours", //"pranie",
            21 : "En attente - séchage ", //"ocz. na suszenie",
            30 : "Séchage", //"suszenie",
            31 : "En attente pour repasseage", //"ocz. na prasowanie",
            40 : "Repassage", //"prasowanie",
            50 : "Prêt - Choisissez la date de récuperation", //"gotowa -> czeka na wyznaczenie daty",
            51 : "Prêt à récupérer par notre livreur", //"czeka na zabranie przez kuriera",
            80 : "En cours de livraison", //"paczka u kuriera -> klienta",

            90 : "Livré", //"dostarczone",
            98 : "Annulé par KurkaDomowa", //"anulowane przez kurke",
            99 : "Annulé à votre demande", //"anulowane przez usera"
        }
    }
    return(
        <span>{txt[props.lang][props.orderState]}</span>
    )
}

export default StateText





