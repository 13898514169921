import React, {Component} from 'react'
import {isMobile} from "react-device-detect"
import { useLocation } from 'react-router-dom';

import MyRouter from "./MyRouter"
import Buble from './Buble'
import Loader from "./Loader"

import Logo from './svg/Logo'


// import ReactPixel from 'react-facebook-pixel';



// import "./css/App.css"
// import setCookie from './func/setCookie'
// import getCookie from './func/getCookie'



class App extends Component {
  constructor(){
    super()
    this.state = {
      loader: "active"
    }

    this.fetchInProgres = false
    this.lacCount = 0
    // this.txt = {
    // }
    this.mainInterval = null
    // this.text = this.text.bind(this)
    this.fetchUserData = this.fetchUserData.bind(this)
    // this.loginHandler = this.loginHandler.bind(this)
    // this.logout = this.logout.bind(this)

    // this.test = this.test.bind(this)
    // this.fetchUserDataOLD  =  this.fetchUserDataOLD.bind(this)
    this.loaderAnimationControl = this.loaderAnimationControl.bind(this)
  }
  componentDidMount(){
    this.fetchUserData()
    this.setState({
      isMobile: isMobile
    })
    if(window.navigator.standalone){
      this.setState({
        menuStandalone: true
      })
    }

    this.tim = setInterval( () => {
      this.fetchUserData()
    },30000)

    window.addEventListener("focus", this.fetchUserData)

  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.fbq('track', 'PageView');
    }
  }
  componentWillUnmount(){
    window.removeEventListener('focus', this.fetchUserData)
    this.tim = clearInterval(this.tim)
  }
  loaderAnimationControl(start){
    // console.warn('loaderAnimationControl', this.state.loader)
    if(start === "start"){
      // alert("start")
      this.lacCount = 0
      this.setState({
        loader: "active"
      })
      return
    }

    if(this.lacCount >= 3){
      let lacTim = setTimeout( () => {
        this.setState({
          loader: "none"
        })
        lacTim = clearTimeout(lacTim)
      },1000)
      this.setState({
        loader: "fadeOut"
      })
    } else {
      this.lacCount ++
    }
    // alert("loaderAnimationControl")
  }
  fetchUserData(dataType){
    if(this.fetchInProgres) return
    this.fetchInProgres = true


    let body = {
      sessionID: localStorage.getItem('sessionID'),
      ltt: localStorage.getItem('ltt'),
      userAgent: navigator.userAgent,
      userID: localStorage.getItem('userID'),
      dataType: dataType,
    }

    body = JSON.stringify(body)
    fetch('https://api2.kurkadomowa.pl/data.php', {
        method: 'POST',
        body: body,
        headers: {'Content-Type': 'application/json'}
    })
    .then((response) => {
        if(!response.ok){
          throw new Error(response.status)
        }
        else{
            try{
                return response.json();
            } catch(e){
                throw new Error(e);
            }
        }
    })
    .then((res) => {
      console.log(res)
      this.setState(res)
      if(this.state.loader === "active"){
        this.setState({
          loader: "ending"
        })
      }
      this.fetchInProgres = false

      // console.log('res.sessionID', res.sessionID)
      // console.log("res.ltt", res.ltt)

      localStorage.setItem('sessionID', res.sessionID)

      if(res.userLogedIn){
        // localStorage.setItem('ltt', res.ltt)
        localStorage.setItem('userID', res.user.userID)
      }

      if(res.dataType === "init"){
        this.fetchUserData()
      }
    })
  }
  render(){
    if(this.state.zaslepka && !localStorage.ignorujZaslepke){
      return(<div id="App">
        <Buble />
        <div className='zaslepkaWraper'>
          <div className='zaslepka'>
            <Logo className="zaslepkaLogo"/>
            <div className='zaslepkaText lesVisibleTile'>
              <h1>O kurza twarz, trwają prace serwisowe.</h1>
              <p>Zaglądnij do nas za kilka godzin. Do rana napewno wszystko będzie gotowe.</p>
              <p>W międzyczasie możesz wejść na <a href="https://kurkadomowa.pl">stronę główną</a> i poczytać o naszej firmie.</p>
            </div>
          </div>
        </div>
      </div>
      )
    }
    return(
        <div id="App">
            <Buble />
            <MyRouter appState={this.state} appUpdate={this.fetchUserData} loaderAnimationControl={this.loaderAnimationControl}/>


            {this.state.loader !== "none" ? <Loader className={this.state.loader} loaderAnimationControl={this.loaderAnimationControl}/> : null}
            {/* <pre>
              {JSON.stringify(
                {loadder: this.state.loader,
                  lacCount: this.lacCount
                }, null, 2)}
            </pre> */}
            {/* <pre>
              {JSON.stringify(this.props, null, 2)}
            </pre> */}
        </div>
    )
  }
}

export default App;
