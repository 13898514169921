import IconMap from "./svg/IconMap"
import IconDelete from "./svg/IconDelete"
import {isMobile} from "react-device-detect"

import Link from "./ScrollingLink"

import "./css/Address.css"

function Address(props){
    if(props.address === undefined){
        return "nieznany adres"
    }
    
    if(props.standalone){
        return(<div className="tileIconed">
            <IconMap className="tileIconedIcon" />
            <div>
                {props.address.street} {props.address.streetNr}{props.address.apartmentNr !== "" ? " / "+props.address.apartmentNr : null}
                {props.inline ? "," : <br/>}
                {props.address.city} {props.address.zipCode}
            </div>
            <Link to={"/deleteAddress/"+props.address.addressID}>
                <IconDelete className={isMobile ? "addressDeletePerm" : "addressDelete" }/>
            </Link>
        </div>)    
    }

    return(<div>
        <div>
            {props.address.street} {props.address.streetNr}{props.address.apartmentNr !== "" ? " / "+props.address.apartmentNr : null}
            {props.inline ? ", " : <br/>}
            {props.address.city} {props.address.zipCode}
        </div>
    </div>)
}

export default Address