import React from "react";

function Icon(props) {
  return (
    <svg
      onAnimationEnd={props.loaderAnimationControl}
      className={props.className}
      id="Le1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <g transform="translate(707.519 470.739) scale(6.62398)">
        <path
          fillRule="nonzero"
          d="M0 14.201C4.157-1.816-8.938-17.401-25.3-17.071c-16.68.147-29.289 16.313-24.888 32.492 1.977 7.26 7.34 13.522 14.371 16.181-11.987-7.633-15.464-24.319-6.295-35.46 5.492-6.784 14.907-9.853 23.342-7.606C-4.797-7.867 2.221 7.652-2.95 20.885c1.283-2.041 2.312-4.287 2.95-6.684"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;