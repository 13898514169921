import React from "react";

function Icon(props) {
  return (
    <svg viewBox="0 0 512 512" className={props.className}>
      <path d="M346 319c-5.522 0-10 4.477-10 10v69c0 27.57-22.43 50-50 50H178.032c-5.521 0-9.996 4.473-10 9.993l-.014 19.882-23.868-23.867a10.003 10.003 0 00-9.171-6.008H70c-27.57 0-50-22.43-50-50V244c0-27.57 22.43-50 50-50h101c5.522 0 10-4.477 10-10s-4.478-10-10-10H70c-38.598 0-70 31.402-70 70v154c0 38.598 31.402 70 70 70h59.858l41.071 41.071a9.997 9.997 0 0010.894 2.169 10 10 0 006.177-9.233l.024-34.007H286c38.598 0 70-31.402 70-70v-69c0-5.523-4.478-10-10-10z"></path>
      <path d="M366.655 0h-25.309C261.202 0 196 65.202 196 145.346s65.202 145.345 145.345 145.345h25.309c12.509 0 24.89-1.589 36.89-4.729l37.387 37.366A10 10 0 00458 316.255v-57.856c15.829-12.819 28.978-29.012 38.206-47.102C506.687 190.751 512 168.562 512 145.346 512 65.202 446.798 0 366.655 0zm75.328 245.535a10.003 10.003 0 00-3.983 7.988v38.6l-24.471-24.458a10.003 10.003 0 00-10.006-2.485c-11.903 3.658-24.307 5.512-36.868 5.512h-25.309c-69.117 0-125.346-56.23-125.346-125.346S272.23 20 341.346 20h25.309C435.771 20 492 76.23 492 145.346c0 39.731-18.23 76.249-50.017 100.189z"></path>
      <path d="M399.033 109.421c-1.443-20.935-18.319-37.811-39.255-39.254-11.868-.815-23.194 3.188-31.863 11.281-8.55 7.981-13.453 19.263-13.453 30.954 0 5.523 4.478 10 10 10s10-4.477 10-10c0-6.259 2.522-12.06 7.1-16.333 4.574-4.269 10.552-6.382 16.842-5.948 11.028.76 19.917 9.649 20.677 20.676.768 11.137-6.539 20.979-17.373 23.403-8.778 1.964-14.908 9.592-14.908 18.549v24.025c0 5.523 4.478 10 10 10 5.523 0 10-4.477 9.999-10v-23.226c20.15-4.868 33.669-23.306 32.234-44.127zM363.87 209.26c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21 1.07-7.07 2.93a10.076 10.076 0 00-2.93 7.07c0 2.64 1.071 5.22 2.93 7.08 1.86 1.86 4.44 2.92 7.07 2.92s5.21-1.06 7.07-2.92a10.1 10.1 0 002.93-7.08c0-2.63-1.071-5.21-2.93-7.07zM275 310H64c-5.522 0-10 4.477-10 10s4.478 10 10 10h211c5.523 0 10-4.477 10-10s-4.478-10-10-10zM282.069 368.93C280.21 367.07 277.63 366 275 366s-5.21 1.07-7.07 2.93c-1.861 1.86-2.93 4.44-2.93 7.07s1.07 5.21 2.93 7.07c1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.069-2.93A10.055 10.055 0 00285 376c0-2.63-1.071-5.21-2.931-7.07zM235.667 366H64c-5.522 0-10 4.477-10 10s4.478 10 10 10h171.667c5.523 0 10-4.477 10-10s-4.478-10-10-10zM210 254H64c-5.522 0-10 4.477-10 10s4.478 10 10 10h146c5.523 0 10-4.477 10-10s-4.478-10-10-10z"></path>
    </svg>
  );
}

export default Icon;
