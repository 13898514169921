import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 467.2 467.2"
      version="1.1"
      viewBox="0 0 467.2 467.2"
      xmlSpace="preserve"
    >
      <path d="M455.146 120.9l-91.7-116.3c-2.3-2.9-5.7-4.6-9.4-4.6h-240.8c-3.7 0-7.2 1.7-9.4 4.6l-91.7 116.3c-1.7 2.1-2.6 4.7-2.6 7.4v326.9c0 6.6 5.4 12 12 12h424.1c6.6 0 12-5.4 12-12V128.3c.1-2.7-.8-5.3-2.5-7.4zm-32.6-2.6h-176.9V24h102.6l74.3 94.3zM119.046 24h102.6v94.3h-176.9l74.3-94.3zm-85.5 419.2V142.3h400.1v300.9h-400.1z"></path>
      <path d="M242.246 203.1c-.3-.3-.6-.6-.9-.8-.2-.1-.3-.2-.5-.3s-.3-.2-.5-.4l-.6-.3c-.2-.1-.3-.2-.5-.3l-.6-.3c-.2-.1-.3-.2-.5-.2-.2-.1-.4-.1-.6-.2-.2-.1-.4-.1-.5-.2-.2-.1-.4-.1-.6-.1s-.4-.1-.6-.1-.4-.1-.7-.1c-.2 0-.3-.1-.5-.1-.8-.1-1.6-.1-2.4 0-.2 0-.3 0-.5.1-.2 0-.4.1-.7.1-.2 0-.4.1-.6.1-.2 0-.4.1-.6.1-.2.1-.4.1-.5.2-.2.1-.4.1-.6.2-.2.1-.3.2-.5.2l-.6.3c-.2.1-.3.2-.5.3l-.6.3c-.2.1-.3.2-.5.4-.2.1-.3.2-.5.3-.3.2-.6.5-.8.7l-.1.1-57 57c-4.7 4.7-4.7 12.3 0 17 2.3 2.3 5.4 3.5 8.5 3.5s6.1-1.2 8.5-3.5l36.5-36.5v136.7c0 6.6 5.4 12 12 12s12-5.4 12-12V240.6l36.3 36.3c4.7 4.7 12.3 4.7 17 0s4.7-12.3 0-17l-56.2-56.8z"></path>
    </svg>
  );
}

export default Icon;