import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 468 468"
    >
      <path
        fillRule="nonzero"
        d="M455.146 120.9l-91.7-116.3c-2.3-2.9-5.7-4.6-9.4-4.6h-240.8c-3.7 0-7.2 1.7-9.4 4.6l-91.7 116.3c-1.7 2.1-2.6 4.7-2.6 7.4v326.9c0 6.6 5.4 12 12 12h424.1c6.6 0 12-5.4 12-12V128.3c.1-2.7-.8-5.3-2.5-7.4zm-32.6-2.6h-176.9V24h102.6l74.3 94.3zM119.046 24h102.6v94.3h-176.9l74.3-94.3zm-85.5 419.2V142.3h400.1v300.9h-400.1z"
      ></path>
      <path
        fillRule="nonzero"
        d="M154.871 69.605c-10.441-3.48-22.621 0-31.322 6.96s-13.921 17.401-13.921 29.582c0 5.22 3.48 8.701 8.701 8.701 5.22 0 8.701-3.48 8.701-8.701 0-5.22 1.74-12.181 6.96-15.661 5.22-3.48 10.441-5.22 17.401-3.48 8.701 1.74 13.921 8.701 15.661 15.661 1.74 8.701 0 15.661-6.96 20.882-13.921 10.441-20.882 26.102-20.882 41.763 0 5.22 3.48 8.701 8.701 8.701 5.22 0 8.701-3.48 6.96-8.701 0-10.441 6.96-20.882 15.661-27.842 12.181-10.441 17.401-24.362 13.921-38.283-3.481-15.661-15.661-26.102-29.582-29.582z"
        transform="translate(2.437 62.121) scale(1.56646)"
      ></path>
      <path
        fillRule="nonzero"
        d="M140.95 210.555c-1.74 1.74-1.74 5.22-1.74 6.96 0 1.74 0 3.48 1.74 5.22 1.74 1.74 5.22 3.48 6.96 3.48 1.74 0 5.22-1.74 5.22-3.48 1.74 0 3.48-3.48 3.48-5.22 0-3.48-1.74-5.22-3.48-6.96-3.48-3.481-8.7-3.481-12.18 0z"
        transform="translate(1.904 62.121) scale(1.56646)"
      ></path>
    </svg>
  );
}

export default Icon;
