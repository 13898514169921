import React from 'react'
// import './css/OrderItem.css'
import Time4Humans from './Time4Humans'
import StateText from "./StateText"
import StateIcon from "./StateIcon"
import OrderTypeIcon from "./OrderTypeIcon"
// import StateDebuger from "./StateDebuger"
import Link from "./ScrollingLink"
import "./css/Order.css"
import IconArrow from "./svg/IconArrow"


function Order(props){

    if(props.order === undefined){
        return(<div className="OrderTile">
            {/* <StateIcon orderState={99} /> */}
            Unknown order
        </div>)
    }

    return(
        <Link to={"/order/"+props.order.orderID} className="OrderTile" key={"o"+props.order.orderID}>
            <StateIcon orderState={props.order.orderState} className={"tileIconedIcon"}/>
            <OrderTypeIcon orderType={props.order.details.laundryType} className={"orderTypeIcon"}/>
            <h3>
                EL/{props.order.userID}/{props.order.orderID}
            </h3>
            {/* <div className='OrderLastUpdate'>
                Ostatnia aktualizacja: <Time4Humans
                    lang={"pl"}
                    time={props.order.lastChangeDate}
                    inputFormat="sql"
                />
            </div> */}
            <div>
                Data odbioru: <Time4Humans
                    lang={"pl"}
                    time={props.order.pickup.date+" "+props.order.pickup.from}
                    inputFormat="sql"
                />
            </div>
            Status realizacji: <StateText
                lang={"pl"}
                orderState={props.order.orderState}/>
            {/* <pre>
                {JSON.stringify(props.order, null, 2)}
            </pre> */}

            {/* <IconArrow className="tileArrow" /> */}
        </Link>
    )

}

export default Order