import React from 'react'
import { NavLink } from "react-router-dom";

function scroll(){
	// window.scrollTo(0, 0)
	// window.scrollTo({top: 0, behavior: 'smooth'});
	window.scrollTo({top: "1px"});
}

function ScrollingLink(props){
	return(
		<NavLink
			onClick={scroll}
			{...props}
		/>
	)
}

export default ScrollingLink