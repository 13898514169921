import React from "react";

function Icon(props) {
  return (
    <svg 
      className={props.className}
      viewBox="0 0 511.989 511.989">
      <path d="M110.933 221.782a8.536 8.536 0 00-8.533 8.533v51.2c0 4.71 3.823 8.533 8.533 8.533s8.533-3.823 8.533-8.533v-51.2c.001-4.71-3.822-8.533-8.533-8.533z"></path>
      <path d="M111.855 2.304L31.172 34.586C8.448 43 0 54.418 0 76.715v358.477c0 22.298 8.448 33.715 30.959 42.061l81.058 32.427c4.011 1.519 8.038 2.287 11.981 2.287 17.152 0 29.602-14.336 29.602-34.091V34.049c0-24.269-19.354-40.175-41.745-31.745zm24.678 475.572c0 10.18-5.035 17.024-12.535 17.024-1.869 0-3.883-.401-5.803-1.118L37.103 461.33c-16.102-5.965-20.036-11.102-20.036-26.138V76.715c0-15.036 3.934-20.164 20.241-26.206l80.725-32.29c2.082-.785 4.087-1.186 5.956-1.186 7.501 0 12.544 6.835 12.544 17.016v443.827zM178.133 51.115h120.533c14.114 0 25.6 11.486 25.6 25.6v128a8.53 8.53 0 008.533 8.533 8.53 8.53 0 008.533-8.533v-128c0-23.526-19.14-42.667-42.667-42.667H178.133c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.534 8.533 8.534zM332.8 298.582a8.53 8.53 0 00-8.533 8.533v128c0 14.114-11.486 25.6-25.6 25.6H179.2c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533h119.467c23.526 0 42.667-19.14 42.667-42.667v-128a8.532 8.532 0 00-8.534-8.532z"></path>
      <path d="M511.343 252.655a8.53 8.53 0 00-1.852-2.782l-85.325-85.333a8.525 8.525 0 00-12.066 0 8.523 8.523 0 000 12.066l70.767 70.775h-286.6a8.536 8.536 0 00-8.533 8.533 8.536 8.536 0 008.533 8.533h286.601L412.1 335.215a8.523 8.523 0 000 12.066 8.51 8.51 0 006.033 2.5 8.509 8.509 0 006.033-2.5l85.325-85.325a8.53 8.53 0 001.852-2.782 8.54 8.54 0 000-6.519z"></path>
    </svg>
  );
}

export default Icon;
