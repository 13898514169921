import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 554.55 641.36"
      version="1.1"
      viewBox="0 0 554.55 641.36"
      xmlSpace="preserve"
    >
      <circle cx="221.32" cy="163.49" r="11.47" className="st0"></circle>
      <path
        d="M272.89 336.09c-9.38-6.45-22.92 1.7-22.73 12.67-.02 13.63 17.28 19.79 26.27 9.3 6.04-6.63 3.5-17.49-3.54-21.97zm4.97-6.05c13.46 12.2 5.34 36.16-12.27 38.59-15.79 2.72-29.62-12.52-25.28-28.25 4.43-17.05 25.17-22.03 37.55-10.34zM305.43 379.82c-7.88-4.99-16.93 5.72-10.37 12.61 8.89 8.22 20.39-5.27 10.37-12.61zm2.76-3.35c7.46 6.7 2.94 20.11-6.8 21.4-11.18 1.87-18.83-11.47-11.64-20.38 4.34-5.81 13.51-5.88 18.44-1.02zM288.69 290.9c-5.89-3.83-13.08 4.32-7.97 9.69 6.86 6.32 15.66-4.1 7.97-9.69zm2.12-2.58c8.23 8.11-2.58 21.47-12.21 14.84-10.82-8.46 1.71-23.97 12.21-14.84zM262.81 400.35c-4.13-2.72-9.27 3.09-5.63 6.85 4.82 4.48 11.07-2.93 5.63-6.85zm1.5-1.83c5.77 5.66-1.7 15.19-8.63 10.49-7.67-5.94 1.25-16.96 8.63-10.49zM287.7 439.66c-6.3-4.09-14 4.62-8.53 10.37 7.34 6.76 16.77-4.39 8.53-10.37zm2.27-2.76c6.12 5.53 2.42 16.53-5.59 17.6-9.11 1.63-15.5-9.54-9.57-16.76 3.56-4.77 11.13-4.85 15.16-.84z"
        className="st0"
      ></path>
      <g>
        <path
          d="M495.11 281.78c1.52 11.01 4.3 21.55 4.52 32.52.2 2.93.8 9.18 1 12.12.39 27.76-3.72 56.47-12.71 82.37-26.19 73.99-89.01 129.1-166.42 142.5-84.64 15.53-173.6-26.02-216.63-100.43-42.18-73.18-37.58-161.88 9.99-231.65 1.48-2.14 3.01-4.26 4.59-6.35 13.58-18.08 30.51-33.33 49.15-46.14a432.142 432.142 0 0136.85-22.71c17.48-9.6 34.26-17.26 49.83-23.42 7.18-2.84 14.84 2.81 14.23 10.51l-.02.21c-.35 4.22-1.64 7.54-2.69 9.67-2.34 4.24-5 9.77-7.28 16.44-1.35 3.95-4.45 13.22-4.97 23.28-.4 7.73-1.62 31.09 12.09 38.64 15.15 8.35 40.98-6.76 42.94-20.62 1.28-9.04-6.92-22.29-9.88-27.06-3.26-5.26-8.57-12.33-6.04-17.73.46-.97 1.06-1.66 1.5-2.09l48.14-12.49c1.78-.46 1.87-2.95.13-3.54-20.93-7.16-41.86-14.32-62.79-21.49-2.88-.99-5.34-2.94-7.01-5.49-6.34-9.68-10.67-18-13.6-24.23-4.07-8.66-8.95-20.05-19.96-32.53-.24-.27-.46-.5-.67-.73-15.06-15.62-46.1-28.33-60.9-15.38-8.29 7.25-11.05 22.16-6.68 38.52 1.56 5.85-3.38 11.48-9.31 10.23-3.29-.7-6.8-.78-10.35.22 0 0-3.58 1-6.59 3.23-11.16 8.26-9.16 32.94-6.8 47.63.8 4.99-3.18 9.43-8.24 9.31-3.58-.09-7.59.44-11.62 2.2 0 0-1.6.73-2.94 1.53-7.65 4.56-17.08 23.66-9.95 39.4l.03.06c1.92 4.21 1.37 9.19-1.83 12.54C.28 309.41 28.98 495.52 171.7 569.14c111.1 56 247.91 17.6 310.82-90.3 34.41-59.4 40.23-134.37 12.59-197.06z"
          className="st0"
        ></path>
        <path
          d="M140.47 432.36c34.22 70.05 117.87 103.39 191.36 78.23 61.3-20.34 105.31-78.85 107.44-143.39 2.46-61.1-35.07-122.18-92.82-143.38 71.92 47.26 92.82 144.71 38.15 213.21-38.8 49.2-110.89 65.18-166.89 37.16-52.6-24.96-84.3-82.85-80.09-140.74 1.06-14.29 4.17-28.2 8.56-41.92-26.44 41.63-27.47 96.68-5.71 140.83z"
          className="st0"
        ></path>
        <path
          d="M384.25 374.27c17.61-67.84-37.86-133.86-107.16-132.46-70.65.62-124.06 69.1-105.42 137.63 8.37 30.75 31.09 57.27 60.87 68.54-50.77-32.33-65.5-103.01-26.66-150.2 23.26-28.74 63.14-41.74 98.87-32.22 59.19 15.24 88.91 80.97 67.01 137.02 5.43-8.64 9.79-18.16 12.49-28.31z"
          className="st0"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
