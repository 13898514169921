import React, {Component} from 'react'

import "./css/Buble.css"
// import buble from "./svg/buble.svg"

class Buble extends Component {
    constructor(){
        super()
        this.state = {}
    }
    genBuble(container){
        let buble = document.createElement('div')
        buble.className = "buble"
        buble.style = this.genRandStyle(container)
        buble.innerHTML = '<svg style="rotate: '+parseInt(Math.random() *360)+'deg;" "width="100%" height="100%" viewBox="0 0 1080 1080"><g transform="matrix(35.5485,0,0,35.5485,354.592,309.349)"><path d="M0,14.271C4.946,19.578 13.993,17.897 15.955,10.544C17.885,4.046 11.996,-2.475 5.51,-1.229C-1.347,-0.194 -5.006,9.103 0,14.271M-3.248,18.008C-11.84,11.464 -10.923,-1.885 -0.74,-6.479C8.605,-10.837 19.559,-3.816 19.52,6.309C19.811,17.571 5.819,25.108 -3.248,18.008"/></g></svg>'
    
        // let innerBuble = document.createElement('img')
        // innerBuble.src = "src/buble.svg"
        // innerBuble.style = "rotate: "+parseInt(Math.random() *360)+"deg;"
        // buble.appendChild(innerBuble)
    
        document.getElementById("bubleContainer"+container).appendChild(buble)
        buble.onanimationend = () => {
            buble.remove()
        };
        // console.log('bubleGenerated')
    }
    genRandStyle(sty){
        // console.log("genRandStyle", sty)
        let x = parseInt(Math.random() * (100 - -5) + -5)
        // let y = parseInt(Math.random() * (100 - 20) + 20)
        // let y = 100
    
        let h
        if(sty === 4){
            h = parseInt(Math.random() * (20 - 6) + 6)
        }else{
            h = parseInt(Math.random() * (10 - 3) + 3)
        }
        let d = parseInt(Math.random() * (25 - -25) + -25)
        let s = parseInt(Math.random() * (15 - 6) + 6)
        return "top: 95%; left: "+x+"%; rotate: "+d+"deg; height: "+h+"vh; width:"+h+"vh; animation-duration: "+s+"s;"
    }
    
    componentDidMount(){
        let a = 1;
        this.genBuble(a)
        this.inter = setInterval( () => {
            let bubleCount = document.querySelectorAll('.buble').length

            if(bubleCount < this.state.bubleCap){
                this.genBuble(a)
                a++
                if(a>4){
                    a=1
                }
            }
            this.setState({bubleCount: bubleCount})
            // console.log('bubleCount', bubleCount)42
        },250)
        this.updateBubleCup()
        window.addEventListener('resize', this.updateBubleCup);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBubleCup);
        this.inter = clearInterval(this.inter)
    }
    updateBubleCup = () => {
        let bubleCap = parseInt(window.innerWidth/40)
        this.setState({ bubleCap: bubleCap })
        // console.log('bubleCap', bubleCap)
    };
    render(){

        return(<div id="bubleWraper">
        <div id="bubleContainer1" className="bubleContainer">
            {true ? <div id="bubleStats"> buble: {this.state.bubleCount}/ {this.state.bubleCap}</div> : null}
        </div>
        <div id="bubleContainer2" className="bubleContainer"></div>
        <div id="bubleContainer3" className="bubleContainer"></div>
        <div id="bubleContainer4" className="bubleContainer"></div>
    </div>)
    }
}

export default Buble