import React from "react";

function Icon(props) {
  return (
    <svg
      onAnimationEnd={props.loaderAnimationControl}
      className={props.className}
      id="Le3"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <g transform="translate(-1010.82 -5148.42) scale(6.62398)">
        <g>
          <path
            fillRule="nonzero"
            d="M-2.678-3.103a2.709 2.709 0 000 5.415 2.708 2.708 0 000-5.415"
            transform="scale(-1 1) rotate(-81.601 359.199 532.605)"
          ></path>
          <path
            fillRule="nonzero"
            d="M0-19.973c.358 2.599 1.015 5.089 1.067 7.678.047.691.19 2.168.236 2.862.092 6.555-.878 13.331-3.002 19.447-6.183 17.469-21.015 30.479-39.29 33.643-19.983 3.666-40.985-6.143-51.143-23.711-9.958-17.277-8.873-38.218 2.357-54.69.35-.505.711-1.005 1.083-1.499 3.207-4.269 7.203-7.87 11.603-10.893a102.525 102.525 0 018.699-5.362 103.555 103.555 0 0111.764-5.53c1.694-.67 3.502.664 3.36 2.48l-.004.05a6.347 6.347 0 01-.635 2.283l.001.001a24.608 24.608 0 00-1.719 3.882c-.318.932-1.05 3.122-1.173 5.495-.096 1.825-.383 7.339 2.855 9.123 3.578 1.971 9.674-1.595 10.137-4.868.302-2.135-1.634-5.263-2.332-6.389-.769-1.243-2.023-2.912-1.425-4.185.107-.23.25-.393.353-.495l11.366-2.949c.419-.108.441-.695.031-.835l-14.823-5.073a3.238 3.238 0 01-1.654-1.295 46.52 46.52 0 01-3.212-5.722c-.961-2.045-2.113-4.732-4.713-7.679a4.882 4.882 0 00-.159-.172c-3.555-3.688-10.883-6.688-14.378-3.631-1.957 1.711-2.608 5.231-1.576 9.093.369 1.381-.799 2.71-2.197 2.414-.777-.164-1.606-.183-2.443.051 0 0-.845.236-1.555.762-2.635 1.951-2.163 7.777-1.606 11.244.189 1.18-.751 2.227-1.945 2.198a6.382 6.382 0 00-2.742.52c-.001 0-.378.172-.695.361-1.805 1.077-4.032 5.587-2.35 9.303l.006.014c.453.994.323 2.17-.433 2.959-24.54 25.638-17.764 69.576 15.931 86.958 26.229 13.221 58.528 4.155 73.381-21.318C5.15 12.528 6.524-5.173 0-19.973"
            transform="translate(285.585 860.644)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
