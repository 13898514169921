import React from "react";

function Icon(props) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448.11 467.2">
      <path d="M445.6 120.9L353.9 4.6a11.94 11.94 0 00-9.4-4.6H103.7a11.8 11.8 0 00-9.4 4.6L2.6 120.9a11.71 11.71 0 00-2.6 7.4v326.9a12 12 0 0012 12h424.1a12 12 0 0012-12V128.3a11.07 11.07 0 00-2.5-7.4zm-32.6-2.6H236.1V24h102.6zM109.5 24h102.6v94.3H35.2zM24 443.2V142.3h400.1v300.9z"></path>
      <path d="M310.7 225.75l-108.7 108-40.5-40.7a12.233 12.233 0 00-17.3 17.3l49.1 49.4a12.25 12.25 0 008.7 3.6 12.2 12.2 0 008.6-3.6L328 243.05a12.233 12.233 0 00-17.3-17.3z"></path>
    </svg>
  );
}

export default Icon;
