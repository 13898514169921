import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="-40 0 512 512"
    >
      <path d="M208.977 27.215c-15.668 0-28.41 12.781-28.41 28.488 0 15.727 12.742 28.52 28.41 28.52 15.68 0 28.437-12.793 28.437-28.52 0-15.707-12.758-28.488-28.437-28.488zm0 42.039c-7.415 0-13.446-6.078-13.446-13.55 0-7.458 6.031-13.52 13.446-13.52 7.425 0 13.468 6.062 13.468 13.52 0 7.472-6.043 13.55-13.468 13.55zm0 0M165.738 34.527h-6.351a7.485 7.485 0 000 14.97h6.351a7.485 7.485 0 000-14.97zm0 0M165.738 63.273h-6.351a7.485 7.485 0 000 14.97h6.351a7.485 7.485 0 000-14.97zm0 0M260.117 34.527h-6.324a7.485 7.485 0 000 14.97h6.324a7.485 7.485 0 000-14.97zm0 0M260.117 63.273h-6.324a7.485 7.485 0 000 14.97h6.324a7.485 7.485 0 000-14.97zm0 0"></path>
      <path d="M410.55 0H22.423C10.059 0 0 10.086 0 22.48v467.04C0 501.914 10.059 512 22.422 512H410.55c12.363 0 22.422-10.086 22.422-22.48V22.48C432.973 10.086 422.914 0 410.55 0zM121.517 497.031H22.422c-4.11 0-7.453-3.37-7.453-7.511V464.8h106.547zm296.492-7.511c0 4.14-3.348 7.511-7.457 7.511H136.484v-32.23h281.524zm0-391.692H235.973a7.485 7.485 0 000 14.969h182.035v337.035H14.968V112.797h183.36a7.483 7.483 0 007.484-7.484 7.483 7.483 0 00-7.484-7.485H14.968V22.48c0-4.14 3.345-7.511 7.454-7.511H410.55c4.11 0 7.457 3.37 7.457 7.511zm0 0"></path>
      <path d="M391.121 29h-87.77c-6.757 0-12.253 5.508-12.253 12.273V70.16c0 6.77 5.496 12.278 12.254 12.278h87.77c6.76 0 12.257-5.508 12.257-12.278V41.273c0-6.765-5.496-12.273-12.258-12.273zm-2.71 38.469h-82.348v-23.5h82.347zm0 0M94.422 207.434a7.484 7.484 0 002.887 10.183 7.484 7.484 0 0010.183-2.887c22.086-39.566 63.852-64.144 108.992-64.144 68.86 0 124.88 56.262 124.88 125.414 0 69.137-56.02 125.387-124.88 125.387-68.87 0-124.902-56.25-124.902-125.387 0-10.309 1.246-20.523 3.703-30.36a7.49 7.49 0 00-5.45-9.077 7.487 7.487 0 00-9.073 5.449c-2.75 11.023-4.149 22.457-4.149 33.988 0 77.39 62.746 140.355 139.875 140.355 77.11 0 139.844-62.964 139.844-140.355 0-77.406-62.734-140.379-139.844-140.379-50.562-.004-97.332 27.516-122.066 71.813zm0 0"></path>
      <path d="M304.902 240.813a7.496 7.496 0 004.422-4.223 7.496 7.496 0 00-.207-6.113 103.537 103.537 0 00-37.336-41.86c-16.511-10.543-35.629-16.117-55.293-16.117-56.867 0-103.133 46.43-103.133 103.5s46.266 103.5 103.133 103.5c19.66 0 38.778-5.574 55.293-16.129a103.651 103.651 0 0037.336-41.844 7.482 7.482 0 00-4.215-10.34 8.591 8.591 0 01-5.699-8.085v-54.23c-.004-3.61 2.29-6.849 5.7-8.06zm-12.457 80.156c-15.824 27.035-44.347 43.562-75.957 43.562-48.617 0-88.168-39.715-88.168-88.531s39.551-88.531 88.168-88.531c31.625 0 60.145 16.523 75.957 43.558-5.101 4.387-8.21 10.864-8.21 17.844v54.23c0 6.989 3.109 13.473 8.21 17.868zm0 0M103.781 35.125H64.414c-10.71 0-19.43 8.734-19.43 19.473 0 10.742 8.72 19.476 19.43 19.476h39.367c10.711 0 19.43-8.734 19.43-19.476 0-10.739-8.715-19.473-19.43-19.473zm0 23.98H64.414c-2.46 0-4.46-2.02-4.46-4.507 0-2.485 2-4.508 4.46-4.508h39.367c2.461 0 4.461 2.023 4.461 4.508 0 2.488-2 4.507-4.46 4.507zm0 0"></path>
    </svg>
  );
}

export default Icon;