import {isMobile} from "react-device-detect"
// import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";



import Link from "./ScrollingLink"
import IconMap from "./svg/IconMap"
import "./css/Address.css"

function sendAddressDelete(addressID, loaderAnimationControl, appUpdate, navigate){
    // alert("tets")

    // return
    loaderAnimationControl("start")

    let url = "https://api2.kurkadomowa.pl/deleteAddress.php"
    let body = {
        sessionID: localStorage.getItem('sessionID'),
        addressID: addressID,
    }
    console.warn('sendData() -> body',body)

    body = JSON.stringify(body)

    fetch(url, {
        method: 'POST',
        body: body,
        headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .catch(() => console.error('sendAddressDelete.sendData: error parsing response:'))
    .then(res => {
        console.group('sendAddressDelete.res')
        console.log(res)
        if(res.updateConfirmed){
            //// zapisano
            // window.scrollTo({top: 0});
            navigate("/addresses");
            appUpdate()
        } else {
            alert("coś poszło nie tak, skontaktuj się z nami pod numerem +48 500 445 665")
            console.warn('permisionDenayed', res.permisionDenayed)
        }
        console.groupEnd()
    })
}
function AddressDelete(props){
    let navigate = useNavigate();
    if(props.address === undefined){
        return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
            <div className="tileIconed">
                <IconMap className="tileIconedIcon" />
                Nieznany adres
            </div>
            <pre>
                {JSON.stringify(props, null , 2)}
            </pre>
        </div>)
    }
    
    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Na pewno chcesz usunąć adres?</h1>
        <div className="tileCenter">
            <IconMap className="tileIconedIconTop" />

            <div>
                {props.address.street} {props.address.streetNr} {props.address.apartmentNr !== "" ? "/ "+props.address.apartmentNr : null}
            </div>
            <div>
                {props.address.city} {props.address.zipCode}
            </div>
            <div className="buttonDiv">
                <Link to="/myAccount" className="button">Anuluj</Link>
                <button className="buttonWarn" 
                    type="button"
                    onClick={ () => sendAddressDelete(
                    props.address.addressID,
                    props.loaderAnimationControl,
                    props.appUpdate,
                    navigate
                )}>Usuń adres</button>
            </div>
            <div className="bold">
                Tej operacji nie mozna cofnąć.
            </div>
        </div>
        {/* <pre>
            {JSON.stringify(props, null , 2)}
        </pre> */}
    </div>)
}

export default AddressDelete