import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path d="M415.777 58.077H331.8V43.894C331.801 19.69 312.11 0 287.907 0h-63.814c-24.203 0-43.894 19.69-43.894 43.894v14.184H96.223c-29.34 0-53.21 23.87-53.21 53.21v30.466c0 5.522 4.478 10 10 10H81.41l25.358 301.106a64.389 64.389 0 0020.639 42.109A64.397 64.397 0 00171.099 512h169.803a64.397 64.397 0 0043.692-17.031 64.395 64.395 0 0020.638-42.109l25.358-301.106h28.397c5.522 0 10-4.478 10-10v-30.466c0-29.341-23.87-53.211-53.21-53.211zM200.199 43.894c0-13.175 10.719-23.894 23.894-23.894h63.814c13.175 0 23.894 10.719 23.894 23.894v14.184H200.199zm248.788 87.859H300.301c-5.522 0-10 4.478-10 10s4.478 10 10 10h110.218l-25.217 299.429c-1.927 22.889-21.43 40.818-44.4 40.818H171.099c-22.97 0-42.473-17.93-44.4-40.818l-25.217-299.429h110.823c5.522 0 10-4.478 10-10s-4.478-10-10-10H63.013v-20.466c0-18.313 14.897-33.21 33.21-33.21h319.555c18.313 0 33.21 14.897 33.21 33.21v20.466z"></path>
      <path d="M198.959 229.392c-3.906-3.904-10.236-3.904-14.143 0-3.905 3.905-3.905 10.237 0 14.143l57.041 57.041-57.041 57.041c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929L256 314.718l57.041 57.041c1.953 1.952 4.512 2.929 7.071 2.929s5.118-.977 7.071-2.929c3.905-3.905 3.905-10.237 0-14.143l-57.041-57.041 57.041-57.041c3.905-3.905 3.905-10.237 0-14.143-3.906-3.904-10.236-3.904-14.143 0L256 286.433zM247.77 145.58c2.097 5.029 8.008 7.492 13.05 5.41 5.04-2.081 7.488-8.03 5.41-13.061-2.08-5.037-8.025-7.493-13.06-5.41-5.05 2.09-7.463 8.025-5.4 13.061z"></path>
    </svg>
  );
}

export default Icon;
