import React from "react";

function Icon(props) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448.11 467.2">
      <path d="M445.6 120.9L353.9 4.6a11.94 11.94 0 00-9.4-4.6H103.7a11.8 11.8 0 00-9.4 4.6L2.6 120.9a11.71 11.71 0 00-2.6 7.4v326.9a12 12 0 0012 12h424.1a12 12 0 0012-12V128.3a11.07 11.07 0 00-2.5-7.4zm-32.6-2.6H236.1V24h102.6zM109.5 24h102.6v94.3H35.2zM24 443.2V142.3h400.1v300.9z"></path>
      <path d="M200.64 286.28c-5.71 6.91-3.58 4.84-5.71 6.91a43.78 43.78 0 01-7.08 5.41c-9.48 6-19.62 6-29.92 2.72-4.31-1.36-13.63-5.31-15.06-5.57 2.36 4.36 6.79 11.24 9.06 19 2.21 7.57 2.37 14.68-2.86 25s-6.52 24.5-2.86 38.89 21.36 27.22 21.88 27.63c4.74 3.7 42.56 11.52 42.82 11.64 2.58 1.13 3.77 2.95 3.09 4.83s-11.27 3-14 2.19c-13.36-4-48.54-14-59-32.1-12.27-21.2-8.71-53.66-3.63-63.23 6.23-11.73-8.7-36-14.36-38.59a31.12 31.12 0 01.94-3.57c1.61-7 15.75-1.66 19.43-.29 5.45 2 10.88 4.15 16.2 6.5a34 34 0 0017.1 2.48c8.46-.86 15.5-5.42 19.15-13.29 2.7-5.85-5.45-30.58-.26-38.46.89-1.76 18.9-22.87 21.08-25.39 1.28-.19 1.81.86 1.92 2.52.34 4.68.79 9.36 1.11 14 .54 7.81.07 34.95-.18 36.64-.23 1.5-.41 3-2.24 4.28-.19-1.17-1.84-43.08-2.4-46.4-24.55 15.6-8.49 49.35-14.22 56.25zM173.45 163.91c2.58 4 5.57 8.11 6.54 13.21a3.89 3.89 0 01-1.34 2.92c-1.08 1-2.59 1.4-3.83 2.21s-2.47 1.85-4.08 3.06a18.73 18.73 0 002.68 1.6c10.37 3.61 14.84 11.8 16.73 21.85.57 3.06.06 17.71-6.93 26.64-3.26 4.72-7.77 8.6-11.94 12.6-2 2-3.12 1.85-7.61.25.29-3.11 2.63-5.16 4.15-7.63 3.74-6.08 8.29-11.8 11.06-18.3a20.57 20.57 0 00-2.85-21.32 15.2 15.2 0 00-11.77-5.84c-3.55 0-6.18-.74-8.38-3.84-13.65 6.08-26.45 13.57-39 21-3.63-2.27-3.73-6-3.78-8.74a239 239 0 011-28.94c.61-6.19 5.46-10.24 10.52-13.24 4.39-2.58 10-.58 13.56 4.24 1.47 2 2.7 4.17 4.25 6.6 3-5 6.91-8.28 12.48-9.45 4-.84 5.07-.5 6.48 3.13.48 1.23.88 2.5 1.39 4 4.35-.37 6.01-5.21 10.67-6.01zM139.71 245.27c-1-1.11-1.95-2.88-2.62-2.77a7.74 7.74 0 00-4.65 2.25c-1.74 2.18-2.83 4.89-4.19 7.37-2 3.66-7.92 6.38-12.91 5.63 2-1.89 3.81-3.59 5.64-5.25.59-.54 5.66-5.24 7.6-7.46 3.83-4.37-1-10.62-2-11.76-3.35 0-24.32 20.12-26.29 25.39-.78 2.09-2.15 4.29-.24 7.08 2 .16 13.56 1.41 17.84-.53 2-.91 6-4.12 8.55-2.27 1.8 1.3 4.15.57 6.13-1.56 3.62-3.88 16.2-15.84 17.88-19.45-4.82-1.67-6.95 3.4-10.74 3.33z"></path>
      <path d="M153.93 210.87a5.69 5.69 0 00-3.32-2.55 15 15 0 00-6.47.12c-2.76.78-5.45 1.71-7.27 3.57a10.56 10.56 0 016 9.38v6.23c.57 1.42 1.07 2.62 2 3.22 3 2 8.79 0 9.73-3.49 1.42-5.46 2.55-11.16-.67-16.48zM287.54 235.42c2.67-1 9.67 0 11.65 2a69.23 69.23 0 016.05 6.55c2.77 3.69 2.39 7.43-.91 10.17-3.82 3.17-48.92 49.71-50.56 51-2.63 2-5.73 2.95-9.1 1-3.2-1.89-15-12.48-12.52-16.08 1.04-1.5 52.85-53.69 55.39-54.64zM264.25 349.93c0-5.08 5.65-9.22 5.65-9.22s34-19.19 42-23.29c2.95-1.52 6.5-2 9.84-2.56a4 4 0 013.23 1.42c1.34 2 11.55 28.31 7.52 29.33-4.68 1.18-9.45 2.07-14.19 3-5 1-38.7 13.71-47 16-3.22.88-3.64.9-4.52-2.41-1.17-4.54-1.9-9.2-2.53-12.27zM347.66 260.06c-.7.06-23.17 5.44-23.28 5-1.58.44-50.91 38-55.9 42.42-2.46 2.22-2.64 3.53-.91 5.42 1 .57 6.48 5.53 8.52 7.11a3.49 3.49 0 004.69.06c5.77-4.59 48-33.74 49-34.55 3.61-2.94 18-16.6 20.68-21.21 1.81-3.09.68-4.56-2.8-4.25zM172.82 330.3a69.35 69.35 0 013.47 6.25c3.16 7.45 10.78 9.45 17.16 4.53 5.05-3.94 5.9-3.77 8.23 2.34 1.92 5 4.14 10 4.47 15.5.37 5.95-2 10.67-7.42 13.17-6.83 3.15-13.8 6-20.71 9-3.12 1.33-5.93-.16-8.49-1.45a10.08 10.08 0 01-4.51-4.41c-2.6-5.9-4.42-12-2.31-18.57a7.57 7.57 0 012.37-2.37c0 1.58.09 2.78 0 4a21.67 21.67 0 003.13 13.09c2.51 4.15 5.62 5.41 10.41 4a57.5 57.5 0 0015-6.93c4.27-2.75 5.38-4.85 4.17-10a58 58 0 00-3.06-8.38 2 2 0 00-3.14-1.05c-.87.48-1.73 1-2.63 1.41a14 14 0 01-18.08-5.67 42.7 42.7 0 01-2.81-5.77c-1.72-4.06-1.32-5.28 2.39-7.75a16.77 16.77 0 012.36-.94z"></path>
    </svg>
  );
}

export default Icon;
