import React from "react";

function Icon(props) {
  return (
    <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 295.82 295.82">
      <path d="M154.871 69.605c-10.441-3.48-22.621 0-31.322 6.96s-13.921 17.401-13.921 29.582c0 5.22 3.48 8.701 8.701 8.701 5.22 0 8.701-3.48 8.701-8.701 0-5.22 1.74-12.181 6.96-15.661 5.22-3.48 10.441-5.22 17.401-3.48 8.701 1.74 13.921 8.701 15.661 15.661 1.74 8.701 0 15.661-6.96 20.882-13.921 10.441-20.882 26.102-20.882 41.763 0 5.22 3.48 8.701 8.701 8.701 5.22 0 8.701-3.48 6.96-8.701 0-10.441 6.96-20.882 15.661-27.842 12.181-10.441 17.401-24.362 13.921-38.283-3.481-15.661-15.661-26.102-29.582-29.582zM140.95 210.555c-1.74 1.74-1.74 5.22-1.74 6.96 0 1.74 0 3.48 1.74 5.22 1.74 1.74 5.22 3.48 6.96 3.48 1.74 0 5.22-1.74 5.22-3.48 1.74 0 3.48-3.48 3.48-5.22 0-3.48-1.74-5.22-3.48-6.96-3.48-3.481-8.7-3.481-12.18 0z"></path>
      <path d="M147.91 0C66.124 0 0 66.124 0 147.91s66.124 147.91 147.91 147.91 147.91-66.124 147.91-147.91S229.696 0 147.91 0zm0 278.419c-71.345 0-130.509-59.164-130.509-130.509S76.565 17.401 147.91 17.401 278.419 76.565 278.419 147.91 219.255 278.419 147.91 278.419z"></path>
    </svg>
  );
}

export default Icon;