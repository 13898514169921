import React, {Component} from 'react'
import {isMobile} from "react-device-detect"
// import IconUser from "./svg/IconUser"
import IconMap from "./svg/IconMap"
import IconCalendar from "./svg/IconCalendar"
import IconWallet from "./svg/IconWallet"
import IconSoftener from "./svg/IconSoftener"
import IconDimentions from "./svg/IconDimentions"
// import Address from './Address'
import Link from "./ScrollingLink"
import DateOptionItem from './DateOptionItemNEW'
import TimeOptions from './TimeOptions'
import SoftenerSelect from "./SoftenerSelect"
// import IconArrow from './svg/IconArrow'

// import repairUtf8 from "./func/repairUtf8"
// import Address from './Address'
// import DateTimeSelect from "./DateTimeSelect"
import "./css/NewLaundry.css"
import { Navigate } from 'react-router-dom'


class NewLaundry extends Component{
    constructor(){
        super()
        this.state = {
            stateDebuger: false,
            
            // selectedDate: "",
            // selectedTimeFrom: "12:00",
            // selectedTimeTo: "20:00",
            // timeFrom: 12,
            // timeTo: 20,
            // selectedAddress: "",
            // selectedRegion: "el",


            

        }
        this.selectAddress = this.selectAddress.bind(this)
        this.selectDate = this.selectDate.bind(this)
        this.chandleChange = this.chandleChange.bind(this)
        this.chandleChildUpdate = this.chandleChildUpdate.bind(this)
        this.calcExpectedDelivery = this.calcExpectedDelivery.bind(this)

        this.sendOrder = this.sendOrder.bind(this)
    }

    componentDidMount(){
        this.setState({
            laundryType: this.props.laundryType
        })
        if(this.props.laundryType === "wet"){
            this.setState({
                selectedDetergent: "standard",
                selectedSoftener: "-1",
                selectedSanitizer: false,
            })
        } else if(this.props.laundryType === "rug"){
            this.setState({
                rugL: 80,
                rugW: 120
            })
        }

        let a = this.props.addressList[0]
        if(a === undefined){
            a = {
                addressID: 0,
                region: "el",
                selectAddressListValue: ""
            }
        }
        this.setState({
            selectedAddress: a.addressID,
            selectedRegion: a.region,
            selectAddressListValue: a.region+":"+a.addressID
        })


        let tomorow = new Date()
        tomorow.setDate(tomorow.getDate() + 1)
        let r = tomorow.getFullYear()
        let m = tomorow.getMonth()+1
        if(m < 10) m = "0"+m
        let d = tomorow.getDate()
        if(d < 10) d = "0"+d
        tomorow = r+"-"+m+"-"+d
        console.log("tommrow", tomorow)

        let calendar = this.props.calendar
        let todayIndex = 0, ii = calendar.length;
        for(todayIndex; todayIndex< ii; todayIndex++){
            if(calendar[todayIndex].date === tomorow) break;
        }
        console.log(calendar)
        let day = calendar.slice(todayIndex, todayIndex+7).filter(item => item.el.opened === "1")[0];
        console.log("day", day)
        
        let start = parseInt(day.el.start)
        let end = parseInt(day.el.end)
        console.log("day", day)
        this.setState({
            tomorow: tomorow,
            selectedDate: day.date,
            selectedTimeFrom: start+":00",
            selectedTimeTo: end+":00",
            timeFrom: start,
            timeTo: end,
        })
        this.calcExpectedDelivery(tomorow)
        // alert(JSON.stringify(day, null, 2))
    }
    selectAddress(e){
        console.log(e)
        let val = e.target.value
        val = val.split(":")
        this.setState({
            selectAddressListValue: val.join(":"), 
            selectedAddress: val[1],
            selectedRegion: val[0]
        })
    }
    selectDate(e){
        console.group('selectDate()')
        let val = e.target.value
        console.log("val", val)
        let day = this.props.calendar.filter(d => d.date === val)[0];
        let start = parseInt(day.el.start)
        let end = parseInt(day.el.end)
        console.log("day", day)
        this.setState({
            selectedDate: val,
            selectedTimeFrom: start+":00",
            selectedTimeTo: end+":00",
            timeFrom: start,
            timeTo: end,
        })
        this.calcExpectedDelivery(val)
        console.groupEnd()
    }
    chandleChange(e){
        let val = e.target.value
        let name = e.target.name
        console.warn(name)
        this.setState({
            [name]: val
        })

        if(name === "rugL"){
            this.calcRug(val, this.state.rugW)
        } else if(name === "rugW"){
            this.calcRug(this.state.rugL, val)
        }
    }
    chandleChildUpdate(e){
        this.setState(e)
    }
    calcExpectedDelivery(pickupDate){
        let dow = ["w niedzielę", "w poniedziałek", "we wtorek", "w środę", "w czwartek", "w piątek", "w sobotę"]
        let month = ["stycznia", "lutego", "marca", "kwietnia", "maja", "czerwca", "lipca", "sierpnia", "września", "października", "listopada", "grudnia"]

        let ex = new Date(0)
        let tempP = pickupDate.split("-")
        ex.setFullYear(tempP[0])
        ex.setMonth(parseInt(tempP[1])-1)
        ex.setDate(tempP[2])

        //// obliczanie dnia ////
        if(this.props.laundryType === "rug"){
            if(ex.getDay() === 6){
                ex.setDate( parseInt(ex.getDate())+14)
            } else {
                ex.setDate( ex.getDate() -ex.getDay() +13);
            }
        }else if(this.props.laundryType === "dry"){
            ex.setDate(ex.getDate()+7)
        }else{
            if(ex.getDay() > 3){
                ex.setDate(ex.getDate()+4)    
            } else {
                ex.setDate(ex.getDate()+3)
            }
        }
        //// koniec obliczania dnia ////

        let m = ex.getMonth()+1
        let d = ex.getDate()
        if(m < 10) m = "0"+m
        if(d < 10) d = "0"+d
        let edd = ex.getFullYear()+"-"+m+"-"+d
        let eddes = " "+dow[ex.getDay()]+" "+ex.getDate()+" "+month[ex.getUTCMonth()] 
        this.setState({
            expectetDeliveryDate: edd,
            expectetDeliveryDateEasyString: eddes
        })
    }
    calcRug(rugL, rugW){
        let rug1MPrice = 25
        let rugDim = rugL * rugW / 10000
        // rugDim = parseInt(rugDim *100) / 100
        let rugPrice = 0
        if(parseInt(rugDim) < rugDim){
            rugPrice = rug1MPrice * (parseInt(rugDim) + 1)
        } else {
            rugPrice = rug1MPrice * rugDim
        }
        rugPrice = parseInt(rugPrice * 100) / 100
        this.setState({
            rugDim: rugDim,
            rugPrice: rugPrice
        })
    }
    sendOrder(){
        // Sprawdz czy wszystkie wymagane pola są wypełnione
        

        // Ustaw widok wysyłania
        this.props.loaderAnimationControl("start")
        // this.setState({
        //     isLoading: true
        // })
        let body = this.state
        body.sessionID = localStorage.sessionID

        // experimental //
        body.dryOption = undefined
        // body.dryItems = undefined
        // experimental end //

        body = JSON.stringify(body)
        // console.log("fetch body", body)
        fetch('https://api2.kurkadomowa.pl/newOrder.php', {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else{
                try{
                    return response.json();
                } catch(e){
                    throw new Error(e);
                }
            }
        })
        .then((data) => {
            this.setState({
                orderSaved: data.orderSaved,
                requestFailed: data.requestFailed,
                failReason:  data.failReason,
                sendOrderResponse: data
            })
            console.log("DATA STORED");
            window.scrollTo({top: 0});
            this.props.appUpdate()
        })
        .catch((error) => {
          console.warn('NewLaundry.sendOrder() -> error: ' + error);
          this.setState({
                requestFailed: true,
                failReason: "Błędna odpowiedź z serwewra!"
            });
        });
    }
    render(){
        if(this.state.orderSaved){
            return <Navigate to="/home" replace/>
        }

        let addressList = this.props.addressList.map( (address) => {
            return(<option
                value={address.region+":"+address.addressID}
                key={"a"+address.addressID}
            >
                    {address.street} {address.streetNr}{address.apartmentNr !== "" ? " / "+address.apartmentNr : null}, {address.city}
            </option>)
        })
        let calendar = this.props.calendar
        let todayIndex = 0, ii = calendar.length;
        for(todayIndex; todayIndex< ii; todayIndex++){
            if(calendar[todayIndex].date === this.state.tomorow) break
        }
        // console.warn("todayIndex", todayIndex)
        calendar = calendar.slice(todayIndex, todayIndex+14).map((day) => {
            return(
                // null
                <DateOptionItem
                    day={day}
                    key={"d"+day.dateID}
                    // userType={props.userType}
                    lang="pl"
                />
            )
        })

        return(<div id="newLaundry" className={isMobile ? "contentWraperMobile" : "contentWraper"}>

            {this.props.laundryType === "wet" ?
                <h1>Pranie i prasowanie</h1> :
            this.props.laundryType === "none" ?
                <h1>Prasowanie lub maglowanie</h1> :
            this.props.laundryType === "rug" ?
                <h1>Pranie dywanu</h1> :
            this.props.laundryType === "dry" ?
                <h1>Pranie specjalistyczne (chemiczne)</h1> :
            null }

            <h2>Gdzie</h2>
            <div className='tileCenter'>
                <IconMap className="tileIconedIconTop" />
                <div id="dayTimePlace">
                    Skąd mamy odebrać zlecenie?
                    {this.props.addressList[0]!== undefined ? 
                        <select
                        value={this.state.selectAddressListValue}
                        onChange={this.selectAddress}>
                            {addressList}
                        </select>
                    : null }
                    <div className='buttonDiv'>
                    {this.props.laundryType === "wet" ?
                        <Link to="/addAaddress/newLaundry" className="button">Dodaj adres</Link>
                    : this.props.laundryType === "none" ?
                        <Link to="/addAaddress/newIroning" className="button">Dodaj adres</Link>
                    : this.props.laundryType === "rug" ?
                        <Link to="/addAaddress/newRugCleaning" className="button">Dodaj adres</Link>
                    : this.props.laundryType === "dry" ?
                        <Link to="/addAaddress/newDryCleaning" className="button">Dodaj adres</Link>
                    : null }
                    </div>
                </div>
            </div>
            <h2>Kiedy</h2>
            <div className='tileCenter'>
                <IconCalendar className="tileIconedIconTop" />
                <div id="dateTimeDiv">
                    Wybierz datę oraz przedział w jakich godzinach mamy do Ciebie przyjechać?
                    <div>
                        <select
                            name="selectedDate"
                            onChange={this.selectDate}
                            value={this.state.selectedDate}
                            >
                                {calendar}
                        </select>
                        <TimeOptions
                            openFrom={this.state.timeFrom}
                            openTo={this.state.timeTo}
                            time={this.state.selectedTimeFrom}
                            timeTo={this.state.selectedTimeTo}
                            lang="pl"
                            updateParent={this.chandleChildUpdate}
                            />
                    </div>
                </div>
            </div>
            {this.state.laundryType === "wet" ?
            <>
            <h2>Parametry prania</h2>
            <div className="tileCenter">
                <IconSoftener className="tileIconedIconTop" />
                <div>
                    Detergent
                    <select
                        name="selectedDetergent"
                        onChange={this.chandleChange}
                        value={this.state.selectedDetergent}
                        >
                        <option value="none">Bez detergentu</option>
                        <option value="standard">Standardowy</option>
                        <option value="heavy">Na ciężkie zabrudzenia</option>
                        <option value="infants">Dla alergików i niemowląt</option>
                        <option value="nuts">Orzechy piorące</option>
                    </select>
                    Płyn do płukania
                    <SoftenerSelect
                        lang="pl"
                        // name="selectedSoftener"
                        chandleChange={this.chandleChange}
                        selectedSoftener={this.state.selectedSoftener}
                        />
                    Dezynfekujący dodatek do prania
                    <select
                        name="selectedSanitizer"
                        onChange={this.chandleChange}
                        value={this.state.selectedSanitizer}
                        >
                        <option value={false}>Nie</option>
                        <option value={true}>Tak</option>
                    </select>
                </div>
            </div>
            </>
            : null }
            {this.state.laundryType === "rug" ? 
                <>
                    <h2>Wymiary dywanu</h2>
                    <div className="tileCenter">
                        <IconDimentions className="tileIconedIconTop" />
                        {/* <img src={rulerIcon} alt="" className="tileIconedIcon" /> */}
                        <div>

                            Podaj długość i serokość swojego dywanu:
                            <div id="RugSplitDiv">
                                <input
                                    name="rugL"
                                    onChange={this.chandleChange}
                                    type={isMobile ? "tel" : "number"}
                                    min="30"
                                    step="5"
                                    value={this.state.rugL} />
                                <div id="RugSplitX">
                                    X
                                </div>
                                <input
                                    name="rugW"
                                    onChange={this.chandleChange}
                                    type={isMobile ? "tel" : "number"}
                                    min="30"
                                    step="5"
                                    value={this.state.rugW} />
                            </div>
                            <div>
                                Jeśli nie wiesz jakie wymiary ma twój dywan, lub oddajesz ich kilka, możesz zostawić to pole nieuzupełnione
                            </div>
                            {this.state.rugDim !== undefined ?
                                <div style={{textAlign:'center'}}><hr/>Razem {this.state.rugDim} m2</div>
                            : null }
                        </div>
                    </div>
                </>
            : null }

            {/* <div className="buttonDiv">
                <button className="button bold" type="button" onClick={this.sendOrder}>
                    Wyślij zlecenie
                </button>
            </div> */}




            <h2>Opłata</h2>
            <div className="lesVisibleTile"><span>
                <IconWallet className="tileIconedIconTop" />
                {this.props.laundryType === "wet" ? 
                    <div>Opłata za pranie i prasowanie wynosi 19 zł/kg.</div>:
                this.props.laundryType === "none" ?
                    <div>Opłata za prasowanie wynosi 17 zł/kg.</div>:
                this.props.laundryType === "rug" ?
                    <>
                        {this.state.rugPrice !== undefined ? 
                            <div>Opłata za pranie tego dywanu wynosi {this.state.rugPrice} zł.</div>
                        :
                            <div>Opłata za pranie dywanu wynosi 25 zł/m2 (minimum 25 zł).</div>
                        }
                    </>
                :
                    <>
                        {this.state.dryPrice !== undefined ? 
                            <div>Opłata wynosi {this.state.dryPrice} zł.</div>
                        :
                            <div>Opłata za pranie specjalistyczne (chemiczne) pobierana jest zgodnie z cenniekiem.</div>
                        }
                    </>
                }
                <div>
                    Przy łącznej wartości zleceń odebranych danego dnia poniżej 150 zł doliczana jest opłata za transport w wysokości 25 zł.
                </div>
            </span></div>
            
            <h2>Przewidywany termin realizacji zlecenia</h2>
            <div className="lesVisibleTile">
                <span>
                    <div>
                        <IconCalendar className="tileIconedIconTop" />
                    </div>
                    <span>Twoje pranie będzie zrealizowane
                        <span style={{fontWeight: 700, display: "inline"}}>
                            {this.state.expectetDeliveryDateEasyString}
                        </span>.
                        <span>Otrzymasz od nas wiadomość SMS z informacją o wykonanej usłudze wraz z linkiem do aplikacji, by wyznaczyć dogodny dla Ciebie termin dostawy.</span>
                    </span>
                </span>
            </div>
            <div className="buttonDiv">
                <button className="button bold" type="button" onClick={this.sendOrder}>
                    Wyślij zlecenie
                </button>
            </div>


            {/* <div className='tile'>
                <pre>
                    this.sate: {JSON.stringify(this.state, null, 2)}
                </pre>
                <pre>
                    this.props: {JSON.stringify(this.props, null, 2)}
                </pre>
            </div> */}
        </div>
        )
    }
}

export default NewLaundry