import React from 'react'
import {isMobile} from "react-device-detect"
import Link from "./ScrollingLink"
import IconLogout from "./svg/IconLogout"

function logout(){
    localStorage.clear()
    window.location.replace("/")
  }

function Logout(){
    return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>Wylogowywanie</h1>
        <div className='tileCenter'>
            <IconLogout className="tileIconedIconTop" />
            <h3>Czy na pewno chcesz się wylogować?</h3>
            <div>
                Usuniemy wszystkie informacje z pamięci Twojej przeglądarki. Jeśli będziesz chciał/a złożyć nowe zlecenie, zaloguj się ponownie, podając numer telefonu oraz kod, który otrzymasz SMS-em.
            </div>
            <div className='buttonDiv'>
                <Link to="/home" className="button">Anuluj</Link>
                <button className='buttonWarn' onClick={logout}>Wyloguj</button>
            </div>
        </div>
    </div>)
}

export default Logout