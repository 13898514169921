import React from 'react'

import Le1 from "./svg/Le1"
import Le2 from "./svg/Le2"
import Le3 from "./svg/Le3"
import Le4 from "./svg/Le4"

import "./css/Loader.css"

function Loader(props){

    return(<div id="loaderContainer">
        {/* <div className='loaderPre'>{props.className}</div> */}
        <div id="loaderLogo"
            className={props.className}
            // loaderAnimationControl={props.loaderAnimationControl}
        >
            <Le1 className={props.className} loaderAnimationControl={props.loaderAnimationControl}/>
            <Le2 className={props.className} loaderAnimationControl={props.loaderAnimationControl}/>
            <Le3 className={props.className} loaderAnimationControl={props.loaderAnimationControl}/>
            <Le4 className={props.className} loaderAnimationControl={props.loaderAnimationControl}/>
        </div>
    </div>)
}

export default Loader