import React from 'react'
import {isMobile} from "react-device-detect"
import IconAbout from "./svg/IconAbout"
import Slider from "./Slider"
    
function BugReport(props){

	return(<div className={isMobile ? "contentWraperMobile" : "contentWraper"}>
        <h1>O nas</h1>
	 		  <div className="tileCenter">
			  <IconAbout className="tileIconedIconTop" />
			  <div>
            Zapomnij <nobr>o żmudnym</nobr> procesie sortowania odzieży, doborze odpowiednich środków piorących <nobr>i prasowaniu.</nobr> <span className='bold'>Kurka Domowa</span> zajmie się tym wszystkim za Ciebie <nobr>i zadba</nobr><nobr> o Twoje</nobr> ubrania, tekstylia domowe, <nobr>a także</nobr> pościel oraz dywany. Wybierz interesujące Cię usługi, <span className='bold'>złóż zamówienie <nobr className="bold">w naszej</nobr> aplikacji <nobr className="bold">i czekaj</nobr> na naszego kuriera, który odbierze Twoje pranie <nobr className="bold">w dogodnym</nobr> dla Ciebie terminie.</span>
        </div>
		  </div>
      <h2>Jak działamy</h2>
      <Slider />
    </div>
    )
}

export default BugReport