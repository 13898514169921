import React from "react";

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315 315" className={props.className}>
      <path d="M301.952 202.988L187.53 137.725c-6.537-3.755-14.663-6.248-23.03-7.158v-7.932c12.986-3.154 22.656-14.88 22.656-28.822 0-16.352-13.304-29.656-29.656-29.656s-29.656 13.304-29.656 29.656a7 7 0 1014 0c0-8.633 7.023-15.656 15.656-15.656s15.656 7.023 15.656 15.656-7.023 15.656-15.656 15.656a7 7 0 00-7 7v14.097c-8.368.909-16.484 3.397-22.994 7.14L13.21 202.895A25.587 25.587 0 000 225.281c0 14.096 11.467 25.563 25.563 25.563h263.874c14.096 0 25.564-11.467 25.564-25.563-.001-9.306-5.032-17.867-13.049-22.293zm-12.516 33.856H25.563c-6.376 0-11.563-5.187-11.563-11.563 0-4.211 2.292-8.094 6.063-10.18l114.398-65.247c2.317-1.332 10.828-5.679 23.038-5.679 12.203 0 20.734 4.354 23.077 5.699l114.523 65.321c3.639 2.01 5.899 5.874 5.899 10.085.002 6.377-5.185 11.564-11.562 11.564z"></path>
    </svg>
  );
}

export default Icon;
