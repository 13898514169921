import React from "react";

function Icon(props) {
  return (
    <svg
      onAnimationEnd={props.loaderAnimationControl}
      className={props.className}
      id="Le2"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <g transform="translate(326.276 491.214) scale(6.62398)">
        <path
          fillRule="nonzero"
          d="M0 24.826c8.08 16.539 27.829 24.409 45.179 18.469C59.65 38.492 70.042 24.678 70.545 9.442c.58-14.426-8.281-28.846-21.913-33.851 16.98 11.159 21.913 34.165 9.006 50.338-9.16 11.614-26.18 15.388-39.4 8.774C5.82 28.81-1.664 15.143-.67 1.476c.25-3.374.985-6.658 2.02-9.898C-4.893 1.405-5.137 14.403 0 24.826"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
