import React, {Component} from 'react'
import {isMobile} from "react-device-detect"

import IconMap from "./svg/IconMap"
import IconCalendar from "./svg/IconCalendar"
import Link from "./ScrollingLink"
import TimeOptions from './TimeOptions'
import DateOptionItem from './DateOptionItemNEW'
import { Navigate } from 'react-router-dom'



import Order from "./Order.js"

class SetDelivery extends Component{
    constructor(){
        super()
        this.state = {
        }

        this.saveDelivery = this.saveDelivery.bind(this)
        this.chandleChildUpdate = this.chandleChildUpdate.bind(this)
        this.selectDate = this.selectDate.bind(this)
        this.selectAddress = this.selectAddress.bind(this)
    }

    componentDidMount(){
        let a = this.props.addressList[0]
        if(a === undefined){
            a = {
                addressID: 0,
                region: "el",
                selectAddressListValue: ""
            }
        }
        this.setState({
            selectedAddress: a.addressID,
            selectedRegion: a.region,
            selectAddressListValue: a.region+":"+a.addressID
        })


        let tomorow = new Date()
        let r = tomorow.getFullYear()
        let m = tomorow.getMonth()+1
        if(m < 10) m = "0"+m
        let d = tomorow.getDate()+1
        if(d < 10) d = "0"+d
        tomorow = r+"-"+m+"-"+d

        let calendar = this.props.calendar
        let todayIndex = 0, ii = calendar.length;
        for(todayIndex; todayIndex< ii; todayIndex++){
            if(calendar[todayIndex].date === tomorow) break;
        }
        let day = calendar.slice(todayIndex, todayIndex+7).filter(item => item.el.opened === "1")[0];
        
        let start = parseInt(day.el.start)
        let end = parseInt(day.el.end)
        // console.log("day", day)
        this.setState({
            tomorow: tomorow,
            selectedDate: day.date,
            selectedTimeFrom: start+":00",
            selectedTimeTo: end+":00",
            timeFrom: start,
            timeTo: end,
        })
        // this.calcExpectedDelivery(tomorow)
        // alert(JSON.stringify(day, null, 2))
    }
    chandleChildUpdate(e){
        this.setState(e)
    }
    selectDate(e){
        console.group('selectDate()')
        let val = e.target.value
        console.log("val", val)
        let day = this.props.calendar.filter(d => d.date === val)[0];
        let start = parseInt(day.el.start)
        let end = parseInt(day.el.end)
        console.log("day", day)
        this.setState({
            selectedDate: val,
            selectedTimeFrom: start+":00",
            selectedTimeTo: end+":00",
            timeFrom: start,
            timeTo: end,
        })
        // this.calcExpectedDelivery(val)
        console.groupEnd()
    }
    selectAddress(e){
        console.log(e)
        let val = e.target.value
        val = val.split(":")
        this.setState({
            selectAddressListValue: val.join(":"), 
            selectedAddress: val[1],
            selectedRegion: val[0]
        })
    }
    saveDelivery(){
        this.props.loaderAnimationControl("start")
        let body = this.state
        body.sessionID = localStorage.sessionID
        body.orderID = this.props.order.orderID
        body = JSON.stringify(body)
        // console.log("fetch body", body)
        fetch('https://api2.kurkadomowa.pl/setDelivery.php', {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
        })
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else{
                try{
                    return response.json();
                } catch(e){
                    throw new Error(e);
                }
            }
        })
        .then((data) => {
            console.warn(data)
            this.setState({
                updateConfirmed: data.updateConfirmed,
                requestFailed: data.requestFailed,
                failReason:  data.failReason,
                response: data,
                sql: data.sql
            })
            console.log("DATA STORED");
            window.scrollTo({top: 0});
            // this.props.loaderAnimationControl("start")
            this.props.appUpdate()
        })
        .catch((error) => {
          console.warn('NewLaundry.sendOrder() -> error: ' + error);
          alert("coś poszło nie tak")
          this.setState({
                requestFailed: true,
                failReason: "Błędna odpowiedź z serwewra!"
            });
        });
    }
    render(){
        let addressList = this.props.addressList.map( (address) => {
            return(<option
                value={address.region+":"+address.addressID}
                key={"a"+address.addressID}
            >
                    {address.street} {address.streetNr}{address.apartmentNr !== "" ? " / "+address.apartmentNr : null}, {address.city}
            </option>)
        })
        let calendar = this.props.calendar
        let todayIndex = 0, ii = calendar.length;
        for(todayIndex; todayIndex< ii; todayIndex++){
            if(calendar[todayIndex].date === this.state.tomorow) break
        }
        // console.warn("todayIndex", todayIndex)
        calendar = calendar.slice(todayIndex, todayIndex+14).map((day) => {
            return(
                // null
                <DateOptionItem
                    day={day}
                    key={"d"+day.dateID}
                    // userType={props.userType}
                    lang="pl"
                />
            )
        })

        if(this.state.updateConfirmed === true){
            return <Navigate to={"/order/"+this.props.order.orderID} replace/>
        }

        return(<div id="newLaundry" className={isMobile ? "contentWraperMobile" : "contentWraper"}>
                <h1>Szczegóły dostawy</h1>
                <Order order={this.props.order} />
                <h2>Gdzie</h2>
                <div className='tileCenter'>
                    <IconMap className="tileIconedIconTop" />
                    <div id="dayTimePlace">
                        Gdzie mamy dostarczyć zlecenie?
                        {this.props.addressList[0]!== undefined ? 
                            <select
                            value={this.state.selectAddressListValue}
                            onChange={this.selectAddress}>
                                {addressList}
                            </select>
                        : null }
                        <div className='buttonDiv'>
                            <Link to={"/addAaddress/setDelivery-"+this.props.order.orderID} className="button">Dodaj adres</Link>
                        </div>
                    </div>
                </div>
                <h2>Kiedy</h2>
                <div className='tileCenter'>
                    <IconCalendar className="tileIconedIconTop" />
                    <div id="dateTimeDiv">
                        Wybierz datę oraz przedział w jakich godzinach mamy do Ciebie przyjechać?
                        <div>
                            <select
                                name="selectedDate"
                                onChange={this.selectDate}
                                value={this.state.selectedDate}
                                >
                                    {calendar}
                            </select>
                            <TimeOptions
                                openFrom={this.state.timeFrom}
                                openTo={this.state.timeTo}
                                time={this.state.selectedTimeFrom}
                                timeTo={this.state.selectedTimeTo}
                                lang="pl"
                                updateParent={this.chandleChildUpdate}
                                />
                        </div>
                    </div>
                </div>
                <div className="buttonDiv">
                    <button
                        className="button"
                        type="button"
                        onClick={this.saveDelivery}
                    >
                        Zapisz dostawę
                    </button>
                </div>
                <div>
                    {this.state.sql}
                </div>
                {/* <pre>
                    {JSON.stringify(this.state, null, 2)}
                </pre> */}
            </div>
        )
    }
}

export default SetDelivery