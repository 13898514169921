import React from "react";

function Icon(props) {
  return (
    <svg
      onAnimationEnd={props.loaderAnimationControl}
      className={props.className}
      id="Le4"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 1080 1080"
    >
      <g transform="translate(-1010.82 -5148.42) scale(6.62398)">
        <g>
          <path
            fillRule="nonzero"
            d="M0-6.835C4.562-2.338-1.428 5.061-6.765 1.389-12.763-3.298-5.818-11.896 0-6.835m-1.175 1.428C-4.439-7.527-8.427-3.014-5.59-.039c3.784 3.515 8.684-2.293 4.415-5.368"
            transform="translate(234.294 858.898)"
          ></path>
          <path
            fillRule="nonzero"
            d="M0-3.801C2.52-1.36-.761 2.811-3.751.759-7.084-1.826-3.206-6.612 0-3.801m-.651.792C-2.447-4.195-4.679-1.665-3.1-.033c2.085 1.959 4.816-1.292 2.449-2.976"
            transform="translate(241.453 866.826)"
          ></path>
          <path
            fillRule="nonzero"
            d="M0-2.906C1.954-.997-.631 2.169-2.882.597-5.449-1.37-2.443-5.075 0-2.906m-.5.608c-1.45-.903-3.067 1.045-1.881 2.287C-.78 1.494 1.318-1.004-.5-2.298"
            transform="translate(237.351 845.121)"
          ></path>
          <path
            fillRule="nonzero"
            d="M0-2.056C1.381-.703-.443 1.536-2.038.421-3.847-.964-1.725-3.592 0-2.056m-.354.43c-1.027-.638-2.172.738-1.33 1.617C-.571 1.058.937-.729-.354-1.626"
            transform="translate(231.094 870.289)"
          ></path>
          <path
            fillRule="nonzero"
            d="M0-3.115C2.072-1.108-.626 2.321-3.084.634-5.832-1.471-2.615-5.436 0-3.115m-.536.651c-1.55-.967-3.281 1.117-2.013 2.447 1.715 1.611 3.96-1.062 2.013-2.447"
            transform="translate(237.152 880.408)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
