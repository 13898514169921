import React from 'react'
// import Link from "./ScrollingLink"

function DateOptionItem(props){
	function sqlToJsDate(sqlDate){
		//sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
		var sqlDateArr1 = sqlDate.split("-");
		//format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
		var sYear = sqlDateArr1[0];
		var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
		var sDay = sqlDateArr1[2];
		return new Date(sYear,sMonth,sDay,0,0,0,0);
	}
	let text = {
		pl: {
				txt_wd: ["Ndz. ", "Pon. ", "Wt. ", "Śr. ", "Czw. ", "Pt. ", "Sob. "],
				txt_mo: [" Stycznia", " Lutego", " Marca", " Kwietnia", " Maja", " Czerwca", " Lipca", " Sierpnia", " Września", " Października", " Listopada", " Grudnia"]
		},
		en: {
				txt_wd: ["Sun. ", "Mon. ", "Tue. ", "Wed. ", "Thu. ", "Fri. ", "Sat. "],
				txt_mo: [" January"," February"," March"," April"," May"," June"," July"," August"," September"," October"," November"," December"]
		}
}


	let date = sqlToJsDate(props.day.date)
	let today = new Date()
	let dateString = props.day.date
	try{
		dateString = text[props.lang]["txt_wd"][date.getDay()] + date.getDate() + text[props.lang]["txt_mo"][date.getMonth()]
	} catch(e) {
		console.error(e)
		if(props.lang === "translator"){
			dateString = "[DateOptionItem -> text[txt_wd]["+date.getDay()+"]] "+date.getDate()+" [DateOptionItem -> text[txt_mo]["+date.getMonth()+"]]"
		}
	}
	
	if(date < today){ // przeszłość lub dziś
		return null
	} else if(props.day.el.opened === "1"){ // otwarte
		return <option value={props.day.date}>{dateString}</option>
	}
	return <option disabled="disabled" value={props.day.date}>{dateString}</option>
}

export default DateOptionItem