import React from "react";

function Icon(props) {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M290.359 432L181.335 263.467V128c0-5.891-4.776-10.667-10.667-10.667h-53.333c-5.891 0-10.667 4.776-10.667 10.667v135.765L7.266 432.747a52.585 52.585 0 00-7.263 26.666C.002 488.455 23.546 511.999 52.588 512h194.24a51.86 51.86 0 0028.136-8.306c24.049-15.547 30.942-47.645 15.395-71.694zm-43.531 58.667H52.588a31.255 31.255 0 01-15.902-4.318c-14.876-8.754-19.839-27.91-11.085-42.786L126.53 272.075a10.666 10.666 0 001.472-5.408v-128h32v128a10.664 10.664 0 001.707 5.792l110.72 171.125a30.506 30.506 0 014.896 16.566c.005 16.848-13.648 30.511-30.497 30.517z"></path>
      <path d="M153.655 310.112l-18.389-10.805-90.571 153.952a10.667 10.667 0 009.205 16.074h74.101V448H72.535l81.12-137.888zM486.252 245.333h-166.25c-23.564 0-42.667 19.103-42.667 42.667v170.667c.035 29.441 23.893 53.298 53.333 53.333h96c29.441-.035 53.298-23.893 53.333-53.333V313.749l24.469-24.459a25.76 25.76 0 007.527-18.183c.009-14.226-11.518-25.766-25.745-25.774zm3.126 28.875l-27.584 27.584c-2 2-3.125 4.713-3.125 7.541v149.333c0 17.673-14.327 32-32 32h-96c-17.673 0-32-14.327-32-32V288c0-11.782 9.551-21.333 21.333-21.333h166.251a4.417 4.417 0 013.125 7.541z"></path>
      <path d="M416.002 309.333V448h-32v21.333h42.667c5.891 0 10.667-4.776 10.667-10.667V309.333h-21.334zM298.668 154.496V10.667C298.668 4.776 293.893 0 288.002 0h-74.667c-5.891 0-10.667 4.776-10.667 10.667v146.859a181.065 181.065 0 00-37.333 16.288l10.667 18.464a159.859 159.859 0 0140-16.352 10.667 10.667 0 008-10.336V21.333h53.333v141.771a10.668 10.668 0 008.672 10.475 160.148 160.148 0 0194.859 57.099l16.629-13.355a181.602 181.602 0 00-98.827-62.827zM453.335 149.333c-20.619 0-37.333 16.715-37.333 37.333 0 20.619 16.715 37.333 37.333 37.333 20.619 0 37.333-16.715 37.333-37.333s-16.714-37.333-37.333-37.333zm0 53.334c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16zM416.002 74.667c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32s32-14.327 32-32c0-17.673-14.327-32-32-32zm0 42.666c-5.891 0-10.667-4.776-10.667-10.667S410.111 96 416.002 96s10.667 4.776 10.667 10.667-4.776 10.666-10.667 10.666zM37.335 85.333c-20.619 0-37.333 16.715-37.333 37.333C.002 143.285 16.716 160 37.335 160s37.333-16.715 37.333-37.333c0-20.619-16.714-37.334-37.333-37.334zm0 53.334c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16zM85.335 10.667c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32zm0 42.666c-5.891 0-10.667-4.776-10.667-10.667 0-5.89 4.776-10.666 10.667-10.666s10.667 4.776 10.667 10.667-4.776 10.666-10.667 10.666z"></path>
      <path d="M320.002 309.333H352.002V330.66600000000005H320.002z"></path>
      <path d="M320.002 352H341.33500000000004V373.33299999999997H320.002z"></path>
      <path d="M320.002 394.667H352.002V416H320.002z"></path>
      <path d="M320.002 437.333H341.33500000000004V458.66600000000005H320.002z"></path>
      <path d="M21.335 192H42.668V213.333H21.335z"></path>
      <path d="M53.335 245.333H74.668V266.666H53.335z"></path>
      <path d="M234.668 288H256.001V309.33299999999997H234.668z"></path>
      <path d="M213.335 245.333H234.668V266.666H213.335z"></path>
      <path d="M202.668 202.667H224.001V224H202.668z"></path>
      <path d="M213.335 437.333H234.668V458.66600000000005H213.335z"></path>
      <path d="M181.335 394.667H202.668V416H181.335z"></path>
    </svg>
  );
}

export default Icon;