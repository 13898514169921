import React from 'react'
// import Link from "./ScrollingLink"

function SoftenerText(props){
	let softenersNames = {
		pl: {
			"-1": "Bez płynu do płukania",
			"1": "Silan - Citrus oil & Frangipani",
			"2": "Silan - Lavender garden",
			"3": "Silan - Jasmine oil & Lily",
			"4": "Silan - Sensitive (Dla niemowląt i alergików)",
			"5": "Silan - Fresh Sky",
			"6": "Silan - Orange oil & Magnolia",
			"7": "Silan - Lemon Blossom Scent & Minerals",
			"8": "Silan - Rose Oil & Peony",
			"9": "Silan - Coconut Water Scent & Minerals",
			"10": "Silan - Peony & White Tea Scent",
			"11": "Silan - Patchouli Oil & Lotus",
			"21": "Lenor - Gold orchid",
			"22": "Lenor - Wild verbena",
			"23": "Lenor - Silk tree blossom",
			"24": "Lenor - Deep sea minerals",
			"25": "Lenor - Amethyst & Floral bouqet",
			"26": "Lenor - Shea butter",
			"27": "Lenor - Sensitive (Dla niemowląt i alergików)",
			"28": "Lenor - Floral romance",
			"29": "Lenor - Fresh meadow",
			"30": "Lenor - Lavender & camomile",
			"31": "Lenor - Summer breeze",
			"32": "Lenor - Spring awakening",
			"33": "Lenor - Diamond & Lotus flower",
			"34": "Lenor - Emerald & Ivory flower",
			"35": "Lenor - Lavender",
			"51": "Softlan - Fruhlingsfrische",
			"52": "Softlan - Vanille & orchidee",
			"53": "Softlan - Windfrisch",
			"54": "Softlan - Kirschbluten",
			"55": "Softlan - Weich & Mild sensitiv",
			"56": "Softlan - Traumfrisch"
		},
		en: {
			"-1": "Without softener",
			"1": "Silan - Citrus oil & Frangipani",
			"2": "Silan - Lavender garden",
			"3": "Silan - Jasmine oil & Lily",
			"4": "Silan - Sensitive (Dla niemowląt i alergików)",
			"5": "Silan - Fresh Sky",
			"6": "Silan - Orange oil & Magnolia",
			"7": "Silan - Lemon Blossom Scent & Minerals",
			"8": "Silan - Rose Oil & Peony",
			"9": "Silan - Coconut Water Scent & Minerals",
			"10": "Silan - Peony & White Tea Scent",
			"11": "Silan - Patchouli Oil & Lotus",
			"21": "Lenor - Gold orchid",
			"22": "Lenor - Wild verbena",
			"23": "Lenor - Silk tree blossom",
			"24": "Lenor - Deep sea minerals",
			"25": "Lenor - Amethyst & Floral bouqet",
			"26": "Lenor - Shea butter",
			"27": "Lenor - Sensitive (Dla niemowląt i alergików)",
			"28": "Lenor - Floral romance",
			"29": "Lenor - Fresh meadow",
			"30": "Lenor - Lavender & camomile",
			"31": "Lenor - Summer breeze",
			"32": "Lenor - Spring awakening",
			"33": "Lenor - Diamond & Lotus flower",
			"34": "Lenor - Emerald & Ivory flower",
			"35": "Lenor - Lavender",
			"51": "Softlan - Fruhlingsfrische",
			"52": "Softlan - Vanille & orchidee",
			"53": "Softlan - Windfrisch",
			"54": "Softlan - Kirschbluten",
			"55": "Softlan - Weich & Mild sensitiv",
			"56": "Softlan - Traumfrisch"
		}
	}
	try {
		return(
			<span>
				{softenersNames[props.lang][props.softenerId+""]}
			</span>
		)
	} catch(e) {
		return(null)
	}
}

export default SoftenerText;